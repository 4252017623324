<div class="image-card-select-options row">
  <div class="col-sm-6">
    <app-info-card
      [showCardBorder]="true"
      [showShadow]="false"
      [showImage]="true"
      [showBody]="true"
      [showCursorPointer]="true"
      [isSelected]="isBookAppointmentCardSelected"
      [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
      [customCardStylesConfig]="{ borderRadius: '0px' }"
      (cardClickEvent)="onBookAppointmentCardClick()"
    >
      <div class="cursor-pointer" cardImage>
        <!--<img [src]="'https://www.dev.my.calenso.com/files/ffc9180f-f450-478e-87a2-1a6027263ff3/ico-yes.svg'"
             alt="Card image cap"
             class="icon-img"
        >-->
        <mat-icon class="button-icon" svgIcon="check-solid"></mat-icon>
      </div>

      <div class="cursor-pointer" cardBody>
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column">
            <div class="mb-1 option-title">
              {{ 'survey_component.book_an_appointment' | translate }}
            </div>
          </div>
        </div>
      </div>
    </app-info-card>
  </div>

  <div class="col-sm-6 mt-4 mt-md-0">
    <app-info-card
      [showCardBorder]="true"
      [showShadow]="false"
      [showImage]="true"
      [showBody]="true"
      [showCursorPointer]="true"
      [isSelected]="isEndProcessCardSelected"
      [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
      [customCardStylesConfig]="{ borderRadius: '0px' }"
      (cardClickEvent)="onEndProcessCardClick()"
    >
      <div class="cursor-pointer" cardImage>
        <!--<img [src]="'https://www.dev.my.calenso.com/files/2d0902aa-40af-4e52-bb73-74bed30b120f/ico-no.svg'"
             alt="Card image cap"
             class="icon-img"
        >-->
        <mat-icon class="button-icon" svgIcon="times-solid"></mat-icon>
      </div>

      <div class="cursor-pointer" cardBody>
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column">
            <div class="mb-1 option-title">
              {{ 'survey_component.end_process' | translate }}
            </div>
          </div>
        </div>
      </div>
    </app-info-card>
  </div>
</div>
