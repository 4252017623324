import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toHour'
})
export class HourReplacePipe implements PipeTransform {
    transform(value: number): string {
        // LoggerService.log("2 val " + value);
        value = value * 60;
        const h = Math.floor(value / 60);
        const hDisplay = h;
        // LoggerService.log("num " + hDisplay);
        return hDisplay + '';
    }
}
