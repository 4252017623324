<div class="alert alert-danger" *ngIf="showError">
  <div class="error-title">
    {{ errorTitle | translate }}
  </div>
  <div>
    {{ errorMessage | translate }}
  </div>
</div>

<ng-container *ngIf="showGhostElement">
  <div class="skeleton-box-card mt-4">
    <div class="">
      <div class="row">
        <div class="col-md-12 mb-4"
             *ngFor="let dependenciesGhostElement of dependenciesGhostElements">
          <div class="d-flex stats-card shadow">
            <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
              <div class="">
                <div class="stat-box-1"></div>
                <div class="stat-box mt-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<form #personalForm="ngForm" *ngIf="showPersonalDataForm" class="needs-validation" novalidate="">
  <div class="personal-form">
    <div class="mb-1 cbw-personal-form-title title">
      <app-csw-text
        [text]="((bookingService.widgetTemplates | calioMeetingTemplate: {
                      id: 120, type: 'template'
                      }) | translation: 'template_content': lang
                    )"
        [styleConfig]="{ fontWeight: 600 }"
        [customClass]="'step-element-title '"
      >
      </app-csw-text>
    </div>
    <div class="mb-2 cbw-personal-form-title description">
      <app-csw-text
        [text]="((bookingService.widgetTemplates | calioMeetingTemplate: {
                      id: 121, type: 'template'
                      }) | translation: 'template_content': lang
                    )"
        [customClass]="'step-element-title'"
      >
      </app-csw-text>
    </div>

    <ng-container *ngFor="let fieldType of fieldTypes">
      <ng-container [ngSwitch]="fieldType.type">
        <ng-container *ngSwitchCase="'salutation'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-salutation">
            <label class="mb-1" for="salutation">
              {{ 'form_fields.salutation' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('salutation') > -1)} }}
            </label>
            <ng-select
              id="salutation"
              name="gender"
              [(ngModel)]="userInfo.gender"
              #salutation="ngModel"
              [required]="customerRequiredFields?.indexOf('salutation') > -1"
              [clearable]="false"
              [ngClass]="{ 'is-invalid': salutation.touched && salutation.invalid }"
            >
              <ng-option *ngFor="let salutationType of salutationTypes" [value]="salutationType.value">
                {{ 'form_fields.' + salutationType.display | translate }}
              </ng-option>
            </ng-select>

            <div *ngIf="salutation.touched && salutation.invalid" class="invalid-feedback">
              <div
                *ngIf="salutation.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'prename'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-prename">
            <label class="mb-1"
                   for="firstName">{{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 220, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('prename') > -1)} ) }}</label>
            <input type="text" class="form-control" id="firstName" placeholder=""
                   [required]="customerRequiredFields?.indexOf('prename') > -1"
                   name="forename" [(ngModel)]="userInfo.forename"
                   #forename="ngModel" [ngClass]="{ 'is-invalid': forename.touched && forename.invalid }"
            >
            <div *ngIf="forename.touched && forename.invalid" class="invalid-feedback">
              <div
                *ngIf="forename.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'lastname'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-lastname">
            <label class="mb-1"
                   for="lastName">{{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 221, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('lastname') > -1)} ) }}</label>
            <input type="text" class="form-control" id="lastName" placeholder=""
                   [required]="customerRequiredFields?.indexOf('lastname') > -1"
                   name="lastName" [(ngModel)]="userInfo.lastName"
                   #lastName="ngModel" [ngClass]="{ 'is-invalid': lastName.touched && lastName.invalid }"
            >
            <div *ngIf="lastName.touched && lastName.invalid" class="invalid-feedback">
              <div
                *ngIf="lastName.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'birthday'">
          <div class="mb-2 cbw-personal-form cbw-personal-form-birthday csw-date-field">
            <label
              class="mb-1">{{ 'form_fields.birthday' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('birthday') > -1)} }}</label>
            <mat-form-field>
              <input
                class=""
                name="birthday"
                matInput
                [matDatepicker]="myPicker"
                [(ngModel)]='userInfo.birthday'
                #birthday="ngModel"
                [required]="customerRequiredFields?.indexOf('birthday') > -1">
              <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
              <mat-datepicker #myPicker></mat-datepicker>
            </mat-form-field>

            <div *ngIf="birthday.touched && birthday.invalid" class="text-danger">
              <div
                *ngIf="birthday.errors['required']">
                This field is required.
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'meeting_types'">
          <div *ngIf="rawMeetingTypes?.length > 1 && showMeetingTypes" class="mb-1 cbw-personal-form cbw-personal-form-meeting_types">
            <label class="input-label"
                   [innerHTML]="(((bookingService.widgetTemplates | calioMeetingTemplate: {
                      id: 203, type: 'template'
                      }) | translation: 'template_content': lang
                    ) | addRequired: {isRequired: (customerRequiredFields?.indexOf('meeting_types') > -1)})
                      | calioSafe: 'html'
                  "
            >
              {{ 'form_fields.meeting_type' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('meeting_types') > -1)} }}
            </label>

            <ng-select
              name="meeting_type_id"
              [(ngModel)]="userInfo.meeting_type_id"
              #meeting_type_id="ngModel"
              [items]="rawMeetingTypes"
              [bindValue]="'id'"
              [clearable]="false"
              [required]="customerRequiredFields?.indexOf('meeting_types') > -1"
              #meetingTypesModel="ngModel"
              [ngClass]="{ 'is-invalid': meetingTypesModel.touched && meetingTypesModel.invalid }"
              (change)="onMeetingTypeChange($event)"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="d-flex align-items-center">
                  <div class="meeting-icon d-flex align-items-center">
                    <img *ngIf="item.id === 1" src="{{ imagePath }}/images/meetings/store.svg" width="26px" />
                    <img *ngIf="item.id === 2" src="{{ imagePath }}/images/meetings/zoom.svg" width="26px" />
                    <img *ngIf="item.id === 3" src="{{ imagePath }}/images/meetings/calenso.svg" width="26px" />
                    <img *ngIf="item.id === 4" src="{{ imagePath }}/images/meetings/phone.svg" width="26px" height="30px" />
                    <img *ngIf="item.id === 5" src="{{ imagePath }}/images/meetings/customer.svg" width="26px" />
                    <img *ngIf="item.id === 6" src="{{ imagePath }}/images/meetings/unblu.svg" width="26px" />
                    <img *ngIf="item.id === 7" src="{{ imagePath }}/images/meetings/teams.svg" width="26px" />
                    <img *ngIf="item.id === 8" src="{{ imagePath }}/images/meetings/meet.svg" width="26px" />
                    <img *ngIf="item.id === 9" src="{{ imagePath }}/images/meetings/gotomeeting.png" width="26px" />
                    <img *ngIf="item.id === 10" src="{{ imagePath }}/images/meetings/webex.png" width="26px" />
                    <img *ngIf="item.id === 11" src="{{ imagePath }}/images/meetings/phone.svg" width="26px" height="30px" />
                    <img *ngIf="item.id === 13" src="{{ imagePath }}/images/meetings/metaverse.svg" width="26px" />
                  </div>
                  <div
                    class="meeting-option"
                    *ngIf="(bookingService.widgetTemplates | calioMeetingTemplate: {
                      id: item.id,
                      type: 'meeting_template'
                    }) as widgetTemplate"
                  >
                    <div
                      class="meeting-type-name"
                      [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content': lang) | calioSafe: 'html'"
                    ></div>
                    <div
                      class="meeting-type-desc"
                      [innerHTML]="(widgetTemplate.descTemplate | translation: 'template_content': lang) | calioSafe: 'html'"
                    ></div>
                    <ng-container *ngIf="item.id === 1 && selectedStore">
                      <div class="cbw-summary-worker-store-location">
                        <span class="cbw-summary-worker-store-location-name">{{ selectedStore | translation: 'name' : lang }}</span>
                        <ng-container *ngIf="selectedStore.is_online === 0">
                          <span class="cbw-summary-worker-store-location-separator">,&nbsp;</span>
                          <span class="cbw-summary-worker-store-location-address">
                            <ng-container *ngIf="selectedStore.street">{{ selectedStore.street }},&nbsp;</ng-container>
                            <ng-container *ngIf="selectedStore.zip">{{ selectedStore.zip }}&nbsp;</ng-container>
                            <ng-container *ngIf="selectedStore.city">{{ selectedStore.city }}</ng-container>
                            <ng-container *ngIf="selectedStore.country">,&nbsp;{{ selectedStore.country | translation: 'name' : lang }}</ng-container>
                          </span>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-template>

              <ng-template ng-label-tmp let-item="item">
                <span
                  *ngIf="(bookingService.widgetTemplates | calioMeetingTemplate: {
                    id: item.id,
                    type: 'meeting_template'
                  }) as widgetTemplate"
                  [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content': lang) | calioSafe: 'html'"
                ></span>
              </ng-template>
            </ng-select>

            <div *ngIf="meetingTypesModel.touched && meetingTypesModel.invalid" class="invalid-feedback">
              <div
                *ngIf="meetingTypesModel.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
              <div
                *ngIf="meetingTypesModel.errors['minlength']">
                {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
              </div>
              <div
                *ngIf="meetingTypesModel.errors['maxlength']">
                {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'notification_preferences'">
          <!-- Notification type -->
          <div class="mb-1 cbw-personal-form cbw-personal-form-notification_preferences"
               *ngIf="widgetService?.widgetConf?.context?.ask_for_customer_notification_preference && NOTIFICATION_TYPES?.length > 0"
          >
            <label class="input-label">
              {{ 'form_fields.notification_type' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('notification_preferences') > -1)} }}
            </label>

            <ng-select
              name="customer_notification_preference"
              [(ngModel)]="userInfo.customer_notification_preference"
              [clearable]="false"
              [required]="customerRequiredFields?.indexOf('notification_preferences') > -1"
              [ngClass]="{ 'is-invalid': customer_notification_preference.touched && customer_notification_preference.invalid }"
              #customer_notification_preference="ngModel"
            >
              <ng-option *ngFor="let notificationType of NOTIFICATION_TYPES" [value]="notificationType.value">
                {{ 'form_fields.' + notificationType.display | translate }}
              </ng-option>
            </ng-select>

            <div *ngIf="customer_notification_preference.touched && customer_notification_preference.invalid"
                 class="invalid-feedback">
              <div
                *ngIf="customer_notification_preference.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
              <div
                *ngIf="customer_notification_preference.errors['minlength']">
                {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
              </div>
              <div
                *ngIf="customer_notification_preference.errors['maxlength']">
                {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
              </div>
            </div>

          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'email'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-email">
            <label class="mb-1"
                   for="email">{{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 222, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('email') > -1)} ) }}</label>
            <input type="email" class="form-control" id="email"
                   name="eMail" [(ngModel)]="userInfo.eMail"
                   #eMail="ngModel" [ngClass]="{ 'is-invalid': eMail.touched && eMail.invalid }"
                   [required]="customerRequiredFields?.indexOf('email') > -1"
                   [placeholder]="((bookingService.widgetTemplates | calioMeetingTemplate: {
                      id: 169, type: 'template'
                      }) | translation: 'template_content': lang
                    )"
            >
            <div *ngIf="eMail.touched && eMail.invalid" class="invalid-feedback">
              <div
                *ngIf="eMail.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
              <div
                *ngIf="eMail.errors['minlength']">
                {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
              </div>
              <div
                *ngIf="eMail.errors['maxlength']">
                {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
              </div>
              <div *ngIf="eMail.errors['email']">
                {{ 'error_messages.email_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'phone'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-phone">
            <label class="mb-1">
              {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 223, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('phone') > -1)} ) }}
            </label>
            <app-calio-tel-input
              [name]="'phone'"
              [(ngModel)]="userInfo.phone"
              [defaultCountry]="(partnerData?.country?.identifier)"
              #phone="ngModel"
              [cssClass]="((phone.touched && phone.invalid) ? 'is-invalid': '')"
              [required]="customerRequiredFields?.indexOf('phone') > -1"
            >
            </app-calio-tel-input>
            <div *ngIf="phone.touched && phone.invalid" class="error-message">
              <div *ngIf="phone.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
              <div *ngIf="phone.errors['invalidNumber']">
                {{ 'error_messages.invalid_phone_number' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'mobile'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-mobile">
            <label class="mb-1">
              {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 263, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('mobile') > -1)} ) }}
            </label>
            <app-calio-tel-input
              [name]="'mobile'"
              [(ngModel)]="userInfo.mobile"
              [defaultCountry]="(partnerData?.country?.identifier)"
              #mobile="ngModel"
              [cssClass]="((mobile.touched && mobile.invalid) ? 'is-invalid': '')"
              [required]="customerRequiredFields?.indexOf('mobile') > -1"
            >
            </app-calio-tel-input>
            <div *ngIf="mobile.touched && mobile.invalid" class="error-message">
              <div *ngIf="mobile.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
              <div *ngIf="mobile.errors['invalidNumber']">
                {{ 'error_messages.invalid_phone_number' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'address'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-street">
            <label class="mb-1"
                   for="street">{{ 'form_fields.street' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('address') > -1)} }}</label>
            <input type="text" class="form-control" id="street"
                   name="street" [(ngModel)]="userInfo.street"
                   #street="ngModel" [ngClass]="{ 'is-invalid': street.touched && street.invalid }"
                   [required]="customerRequiredFields?.indexOf('address') > -1"
            >
            <div *ngIf="street.touched && street.invalid" class="invalid-feedback">
              <div
                *ngIf="street.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
              <div
                *ngIf="street.errors['minlength']">
                {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
              </div>
              <div
                *ngIf="street.errors['maxlength']">
                {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
              </div>
            </div>
          </div>

          <div class="row mb-1 cbw-personal-form-zip">
            <div class="col-4">
              <label
                class="mb-1"
                for="zip">{{ 'form_fields.zip' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('address') > -1)} }}
              </label>
              <input
                type="text"
                class="form-control"
                id="zip"
                [required]="customerRequiredFields?.indexOf('address') > -1"
                name="zip"
                [(ngModel)]="userInfo.zip"
                #zip="ngModel"
                pattern="^[a-zA-Z0-9\s-]{1,10}$"
                [ngClass]="{ 'is-invalid': zip.touched && zip.invalid }"
              >
              <div *ngIf="zip.touched && zip.invalid" class="invalid-feedback">
                <div
                  *ngIf="zip.errors['required']">
                  {{ 'error_messages.required_error_message' | translate }}
                </div>
                <div
                  *ngIf="zip.errors['pattern']">
                  {{ 'error_messages.invalid_zip' | translate }}
                </div>
              </div>
            </div>

            <div class="col-8">
              <label class="mb-1" for="city">
                {{ 'form_fields.city' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('address') > -1)} }}
              </label>
              <input
                type="text"
                class="form-control"
                id="city"
                [required]="customerRequiredFields?.indexOf('address') > -1"
                name="city"
                [(ngModel)]="userInfo.city"
                #city="ngModel"
                [ngClass]="{ 'is-invalid': city.touched && city.invalid }"
              >
              <div *ngIf="city.touched && city.invalid" class="invalid-feedback">
                <div *ngIf="city.errors['required']">
                  {{ 'error_messages.required_error_message' | translate }}
                </div>
                <div *ngIf="city.errors['minlength']">
                  {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
                </div>
                <div *ngIf="city.errors['maxlength']">
                  {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'comment'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-comment">
            <label
              class="mb-1"
              for="firstName">
              {{ 'form_fields.comment' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('comment') > -1)} }}
            </label>

            <textarea
              class="form-control"
              id="comment"
              placeholder=""
              [required]="customerRequiredFields?.indexOf('comment') > -1"
              name="comment" [(ngModel)]="userInfo.comment"
              #comment="ngModel"
              [ngClass]="{ 'is-invalid': comment.touched && comment.invalid }"
              cols="30"
              rows="5">
            </textarea>

            <small class="calio-text-muted">
              {{ 'form_fields.comment_helper_text' | translate }}
            </small>

            <div *ngIf="comment.touched && comment.invalid" class="invalid-feedback">
              <div *ngIf="comment.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'title'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-title">
            <label
              for="title">
              {{ 'personal_form.title' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('title') > -1)}  }}
            </label>

            <ng-select
              id="title"
              name="title"
              [(ngModel)]="userInfo.title"
              #title="ngModel"
              [required]="customerRequiredFields?.indexOf('title') > -1"
              [clearable]="false"
              [ngClass]="{ 'is-invalid': title.touched && title.invalid }"
            >
              <ng-option *ngFor="let title of titleTypes" [value]="title.id">
                {{ title.name | translate }}
              </ng-option>
            </ng-select>

            <div *ngIf="title.touched && title.invalid" class="invalid-feedback">
              <div
                *ngIf="title.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'company'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-company">
            <label
              class="mb-1"
              for="company">
              {{ 'personal_form.company' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('company') > -1)}  }}
            </label>
            <input
              type="text"
              class="form-control"
              id="company"
              placeholder=""
              [required]="customerRequiredFields?.indexOf('company') > -1"
              name="company"
              [(ngModel)]="userInfo.company"
              #company="ngModel"
              [ngClass]="{ 'is-invalid': company.touched && company.invalid }"
            >
            <div *ngIf="company.touched && company.invalid" class="invalid-feedback">
              <div
                *ngIf="company.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'country'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-country">
            <label class="input-label">
              {{ 'personal_form.country' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('country') > -1)} }}
            </label>

            <ng-select
              id="country"
              name="country"
              [(ngModel)]="userInfo.country"
              [clearable]="false"
              [required]="customerRequiredFields?.indexOf('country') > -1"
              [ngClass]="{ 'is-invalid': country.touched && country.invalid }"
              #country="ngModel"
            >
              <ng-option *ngFor="let option of (countries | sortTranslationList: 'name': currentLang)" [value]="option.id">
                <div>
                  <span>
                    {{ option | translation: 'name': currentLang }}
                  </span>
                </div>
              </ng-option>
            </ng-select>

            <div *ngIf="country.touched && country.invalid" class="invalid-feedback">
              <div
                *ngIf="country.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'policy'">
          <div class="mb-1 cbw-personal-form cbw-personal-form-policy">
            <div class="custom-control custom-checkbox my-1">
              <input
                id="policy"
                name="policy"
                for="policy"
                type="checkbox"
                class="custom-control-input"
                [(ngModel)]="userInfo.policy"
                #policy="ngModel"
                [ngClass]="{ 'is-invalid': policy.touched && policy.invalid }"
                [required]="customerRequiredFields.indexOf('policy') > -1"
              >
              <label class="custom-control-label ps-2">
                <span [innerHtml]="('personal_form.accept_data_policy' | translate) | trustHtml" ></span>
                <span *ngIf="customerRequiredFields?.indexOf('policy') > -1">*</span>
              </label>
            </div>
            <div *ngIf="policy.touched && policy.invalid" class="text-danger">
              <div *ngIf="policy.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'CUSTOM_FIELD'">
          <div class="mb-2" *ngIf="widgetQuestions[fieldType.value]">
            <app-booking-question
              [customField]="widgetQuestions[fieldType.value]"
              [selectedCustomFieldValues]="selectedCustomFieldValues"
              (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)">
            </app-booking-question>

            <ng-container *ngIf="widgetQuestions[fieldType.value]?.customFieldDependencies?.length > 0">
              <ng-container
                *ngFor="let dependencyCustomField of widgetQuestions[fieldType.value]?.customFieldDependencies;
                  let childIndex = index;
                  let childIsFirst = first;
                  let childIsLast = last;">
                <app-booking-question
                  [customField]="dependencyCustomField"
                  [selectedCustomFieldValues]="selectedCustomFieldValues"
                  (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)">
                </app-booking-question>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="widgetQuestions[fieldType.value]?.showDependenciesGhostElement">
              <div class="skeleton-box-card mt-4">
                <div class="">
                  <div class="row">
                    <div class="col-md-12 mb-4"
                         *ngFor="let dependenciesGhostElement of dependenciesGhostElements">
                      <div class="d-flex stats-card shadow">
                        <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
                          <div class="">
                            <div class="stat-box-1"></div>
                            <div class="stat-box mt-1"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="d-flex justify-content-end mt-4 cbw-custom-navigation">
    <div class="me-2">
      <app-button
        id="previous-btn"
        [id]="'personal-form-previous-btn'"
        (clickEvent)="previousQuestion()"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color">
        <span class="custom-button-icon back"></span>
        {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 129, type: 'template'
        }) | translation: 'template_content': lang
      )}}
      </app-button>
    </div>
    <div>
      <app-button
        [id]="'personal-form-finish-btn'"
        (clickEvent)="submit(personalForm)"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 128, type: 'template'
        }) | translation: 'template_content': lang
      )}}
      <span class="custom-button-icon next"></span>
      </app-button>
    </div>
  </div>
</form>
