import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { CustomerDbModel } from '../../../../models/customer.model';
import { SurveyService } from '../../../../services/feature/survey.service';
import { WidgetService } from '../../../../services/feature/widget.service';
import { LoggerService } from '../../../../services/utils/logger.service';

@Component({
  selector: 'app-survey-integrate-booking-widget',
  templateUrl: './survey-integrate-booking-widget.component.html',
  styleUrls: ['./survey-integrate-booking-widget.component.scss']
})
export class SurveyIntegrateBookingWidgetComponent implements OnInit, AfterViewInit {

  lang: string;
  bookingWidgetConfigObject: {
    selectedPartnerName?: string,
    selectedStoreId?: number,
    selectedWorkerId?: number,
    selectedCustomer?: CustomerDbModel,
    selectedAppointmentServiceIds?: number[],
    meetingTypeId?: number,
    customerNotificationPreference?: string,
  } = {};


  @ViewChild('calensoWidgetDiv') calensoWidgetDiv: ElementRef;

  constructor(
    private ngZone: NgZone,
    private widgetService: WidgetService,
    private surveyService: SurveyService,
    private renderer: Renderer2,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    LoggerService.log('Inside SurveyIntegrateBookingWidgetComponent  constructor');
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(
      (language: LangChangeEvent) => {
        this.lang = language.lang;
        if (this.calensoWidgetDiv?.nativeElement) {
          this.calensoWidgetDiv.nativeElement.firstChild.setAttribute('lang', this.lang)
        }
      }
    );
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loadBookingWidget();
  }


  loadBookingWidget(): void {
    const node = document.createElement('link');
    node.href = `${environment.bookingWebcomponentUrl}`;
    node.rel = 'import';
    node.onload = this.onBookingWidgetLoad.bind(this);
    this.renderer.appendChild(this.document.head, node);
  }

  onBookingWidgetLoad(): void {
    this.ngZone.run(() => {
      this.initBookingWidgetConfig();
    });
  }

  initBookingWidgetConfig(): void {
    if (this.widgetService?.widgetConf?.context?.store_id) {
      this.bookingWidgetConfigObject.selectedStoreId = this.widgetService?.widgetConf?.context?.store_id;
    }

    if (this.widgetService?.widgetConf?.context?.worker_id || this.widgetService?.widgetConf?.context?.worker_id === 0) {
      this.bookingWidgetConfigObject.selectedWorkerId = this.widgetService?.widgetConf?.context?.worker_id;
    }

    if (this.widgetService?.widgetConf?.context?.appointment_service_ids?.length > 0) {
      this.bookingWidgetConfigObject.selectedAppointmentServiceIds = this.widgetService?.widgetConf?.context?.appointment_service_ids;
    }

    if (this.surveyService?.customerDetail) {
      this.surveyService.preInitializeWidgetCustomerConfData(this.widgetService.customerConfData);

      const newCustomer = new CustomerDbModel();
      newCustomer.prename = this.surveyService.customerDetail.prename;
      newCustomer.lastname = this.surveyService.customerDetail.lastname;
      newCustomer.email = this.surveyService.customerDetail.email;
      newCustomer.mobile = this.surveyService.customerDetail.mobile;
      newCustomer.phone = this.surveyService.customerDetail.phone;
      newCustomer.id = this.surveyService.customerDetail.uuid;
      newCustomer.uuid = this.surveyService.customerDetail.uuid;
      newCustomer.street = this.surveyService.customerDetail.street;
      newCustomer.gender = this.surveyService.customerDetail.gender;
      newCustomer.zip = this.surveyService.customerDetail.zip;
      newCustomer.city = this.surveyService.customerDetail.city;
      newCustomer.birthday = this.surveyService.customerDetail.birthday;
      newCustomer.company_name = this.surveyService.customerDetail?.company;
      newCustomer.title = this.surveyService.customerDetail?.title;
      newCustomer.country = this.surveyService.customerDetail?.country;
      this.bookingWidgetConfigObject.selectedCustomer = newCustomer;
    }

    if (this.surveyService.customerDetail.notification_preference) {
      this.bookingWidgetConfigObject.customerNotificationPreference = this.surveyService.customerDetail.notification_preference;
    }
    if (this.surveyService.customerDetail.meeting_type_id) {
      this.bookingWidgetConfigObject.meetingTypeId = this.surveyService.customerDetail.meeting_type_id;
    }

    if (this.widgetService.widgetConf?.partner?.booking_name) {
      this.bookingWidgetConfigObject.selectedPartnerName = this.widgetService.widgetConf?.partner?.booking_name;
    }

    LoggerService.log('final this.bookingWidgetConfigObject ', this.bookingWidgetConfigObject);

    this.loadCalensoBookingElement();
  }

  loadCalensoBookingElement(): void {
    const node = document.createElement('calenso-booking');
    node.setAttribute('partner', this.bookingWidgetConfigObject.selectedPartnerName);
    node.setAttribute('type', 'appointment');
    node.setAttribute('lang-switcher', 'false');
    node.setAttribute('lang', this.lang);
    node.setAttribute('selected-store-id', this.bookingWidgetConfigObject.selectedStoreId + '');
    node.setAttribute('selected-worker-id', this.bookingWidgetConfigObject.selectedWorkerId + '');
    if (this.bookingWidgetConfigObject.meetingTypeId) {
      node.setAttribute('meeting-type-id', this.bookingWidgetConfigObject.meetingTypeId + '');
    }
    if (this.bookingWidgetConfigObject.customerNotificationPreference) {
      node.setAttribute('customer-notification-preference', this.bookingWidgetConfigObject.customerNotificationPreference);
    }
    if (this.bookingWidgetConfigObject.selectedAppointmentServiceIds?.length > 0) {
      node.setAttribute('selected-appointment-service-ids', this.bookingWidgetConfigObject.selectedAppointmentServiceIds.join(','));
    }
    node.setAttribute('selected-customer', JSON.stringify(this.bookingWidgetConfigObject.selectedCustomer));
    node.addEventListener('CHILD_TO_PARENT', (event) => {
      LoggerService.log('Inside CHILD_TO_PARENT ', event);
    });

    const calensoWidgetDivContainer: HTMLElement = this.document.getElementById('smart-widget-calenso-booking-widget');

    LoggerService.log('calensoWidgetDivContainer ', calensoWidgetDivContainer);
    LoggerService.log('Loading this.calensoWidgetDiv ', this.calensoWidgetDiv);
    LoggerService.log('Loading node ', node);
    if (calensoWidgetDivContainer) {
      LoggerService.warn('Appending child in smart-widget-calenso-booking-widget');
      calensoWidgetDivContainer.appendChild(node);
    } else {
      LoggerService.warn('calensoWidgetDivContainer is empty.');
    }
  }
}
