import {Component, OnInit} from '@angular/core';
import {WidgetService} from '../../../../services/feature/widget.service';
import {SurveyService} from '../../../../services/feature/survey.service';
import {CalendarAppointmentModel} from '../../../../models/appointment-booking.model';
import {PartnerDbModel} from '../../../../models/partner-db.model';
import {CustomerDbModel} from '../../../../models/customer.model';
import {SurveyStepModel} from '../../../../models/survey-step.model';
import {environment} from '../../../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {FileService} from '../../../../services/utils/file.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {BookingService} from '../../../../services/feature/booking.service';
import {LoggerService} from '../../../../services/utils/logger.service';

@Component({
  selector: 'app-survey-end-step',
  templateUrl: './survey-end-step.component.html',
  styleUrls: ['./survey-end-step.component.scss']
})
export class SurveyEndStepComponent implements OnInit {

  lang: string;

  constructor(
    public widgetService: WidgetService,
    public surveyService: SurveyService,
    private fileService: FileService,
    private translate: TranslateService,
    public bookingService: BookingService,
  ) {
  }

  ngOnInit(): void {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(
      (language: LangChangeEvent) => {
        this.lang = language.lang;
      }
    );
  }

  exportSurveySummary(bookingData: CalendarAppointmentModel): void {
    this.surveyService.exportSurveySummary(
      bookingData?.uuid,
      this.surveyService.customerDetail.uuid,
      this.widgetService.widgetUUID
    ).subscribe({
      next: (result: {
        path: string,
        appointment: CalendarAppointmentModel,
        partner: PartnerDbModel,
        customer: CustomerDbModel,
        widgetSteps: SurveyStepModel[]
      }) => {
        this.fileService.saveFile(`${environment.hostName}${result.path}`, this.fileService.fetchFilenameFromPath(result.path));
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }
}
