import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {ButtonsModule} from '../buttons/buttons.module';
import {CalioIntlTelInputModule} from '../calio-intl-tel-input/calio-intl-tel-input.module';
import {AlertModule} from '../alert/alert.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {BookingQuestionComponent} from './booking-question/booking-question.component';
import {PersonalFormComponent} from './personal-form/personal-form.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {ModalsModule} from '../modals/modals.module';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [
    BookingQuestionComponent,
    PersonalFormComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    NgSelectModule,
    ButtonsModule,
    CalioIntlTelInputModule,
    AlertModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatIconModule,
    ModalsModule,
    MatDialogModule,
  ],
  exports: [
    BookingQuestionComponent,
    PersonalFormComponent
  ],
})
export class BookingQuestionsModule {
}
