import { PartnerDbModel } from './partner-db.model';
import { StoreDbModel } from './store-db.model';

export class WorkerDbModel {
  id: number;
  prename: string;
  lastname: string;
  booking_label: string;
  avatar: string;
  partner_id: number;
  username: string;
  email: string;
  job_title: string;
  conference_url: string;
  bookable: number;
  store_id: number;
  store_name: string;
  photo_dir: string;
  registration_date: Date;
  updated: Date | string;
  first_login: number;
  street: string;
  zip: string;
  city: string;
  mobile: string;
  phone: string;
  timezone: string;
  show_in_pro_calendar: number;
  is_reseller_admin: number;
  status: number;
  uuid: string;
  store: StoreDbModel;
  full_name: string;
  resource_name: string;
  isSelected?: boolean;
  is_resource: number;
  description: string;
  booking_type_id: number;
  password: string;
  password_repeat: string;
  color: string;
  is_multi_language: number;
  is_lead_generator: number;
  lang_identifier: string;
  calendar_color: string;
  base_account_partner_id: number;
  partner: PartnerDbModel;
  newsletter_invitation_sent: number;
  identity_connection: string;
  is_round_robin_enabled: number;
  hide_past_appointments: number;
  block_resource_dependencies: string;
  has_pending_export: number;
  is_mfa_enabled: number;
  mfa_verification_pending: number;
  _translations: {
    [key: string]: WorkerDbModelTranslation
  }[] = [];
}

export class WorkerDbModelTranslation {
  job_title?: string;
  booking_label?: string;
  description?: string;
  locale?: string;
  full_name?: string;
  resource_name?: string;
}
