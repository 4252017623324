import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SurveyComponent} from './survey.component';
import {SharedModule} from '../../shared/shared.module';
import {MatStepperModule} from '@angular/material/stepper';
import {FormsModule} from '@angular/forms';
import {ButtonsModule} from '../../ui-lib/buttons/buttons.module';
import {SurveyQuestionsViewComponent} from './components/survey-questions-view/survey-questions-view.component';
import {AlertModule} from '../../ui-lib/alert/alert.module';
import {BookingQuestionsModule} from '../../ui-lib/booking-questions/booking-questions.module';
import {SurveyQuestionViewComponent} from './components/survey-question-view/survey-question-view.component';
import {MatIconModule} from '@angular/material/icon';
import {SurveyIntegrateBookingWidgetComponent} from './components/survey-integrate-booking-widget/survey-integrate-booking-widget.component';
import { SurveyFinalStepComponent } from './components/survey-final-step/survey-final-step.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SurveyCustomerFormComponent } from './components/survey-customer-form/survey-customer-form.component';
import { SurveyEndStepComponent } from './components/survey-end-step/survey-end-step.component';
import {GtmOperationModule} from '../../ui-lib/gtm-operation/gtm-operation.module';

@NgModule({
  declarations: [
    SurveyComponent,
    SurveyQuestionsViewComponent,
    SurveyQuestionViewComponent,
    SurveyIntegrateBookingWidgetComponent,
    SurveyFinalStepComponent,
    SurveyCustomerFormComponent,
    SurveyEndStepComponent
  ],
  imports: [
    SharedModule,
    MatStepperModule,
    FormsModule,
    ButtonsModule,
    AlertModule,
    BookingQuestionsModule,
    MatIconModule,
    MatCheckboxModule,
    GtmOperationModule,
  ],
  exports: [
    SurveyComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SurveyModule {
}
