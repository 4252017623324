import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceComma'
})
export class ReplaceCommaPipe implements PipeTransform {
    transform(value: string) {
        let num = 0;
        num = parseFloat(value.toString().replace(',', '.').replace(' ', ''));
        return num;
    }
}
