import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from "@sentry/angular-ivy";
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CUSTOM_MAT_ICONS } from './constants/app.constants';
import { BookingModule } from './features/booking/booking.module';
import { FillCalendarModule } from './features/fill-calendar/fill-calendar.module';
import { LeadGeneratorModule } from './features/lead-generator/lead-generator.module';
import { SurveyModule } from './features/survey/survey.module';
import { AuthTokenInterceptor } from './interceptor/auth-token.interceptor';
import { GlobalErrorHandler } from './interceptor/global-error-handler.interceptor';
import { NoopHttpInterceptorService } from './interceptor/noop-http-interceptor';
import { AlertModule } from "./ui-lib/alert/alert.module";
import { LayoutModule } from './ui-lib/layout/layout.module';

// Import locales
import localeDe from '@angular/common/locales/de';
import localeDeCH from '@angular/common/locales/de-CH';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeIt from '@angular/common/locales/it';
import localeItCh from '@angular/common/locales/it-CH';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeSv from '@angular/common/locales/sv';

// required for currency pipe
registerLocaleData(localeDe, 'de');
registerLocaleData(localeDeCH, 'ch');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeFrCH, 'fr_CH');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeItCh, 'it_CH');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeSv, 'sv');

export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: NoopHttpInterceptorService, multi: true, },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LOCALE_ID, useValue: 'de-ch' },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true, }
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SurveyModule,
    BookingModule,
    FillCalendarModule,
    LeadGeneratorModule,
    RouterModule.forRoot([]),
    MatIconModule,
    AlertModule
  ]
})
export class AppModule {

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    CUSTOM_MAT_ICONS.forEach(icon => {
      iconRegistry.addSvgIcon(
        icon.iconName,
        sanitizer.bypassSecurityTrustResourceUrl(`${environment.hostName}/img/icons/${icon.iconPath}`)
      );
    });
  }
}
