import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BookingService} from '../../../../services/feature/booking.service';
import {AppointmentServiceCategoryModel, AppointmentServiceModel} from '../../../../models/appointment-service.model';
import {HelperService} from '../../../../services/utils/helper.service';
import {LoggerService} from '../../../../services/utils/logger.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CustomEventService} from '../../../../services/feature/custom-event.service';
import {HttpErrorResponse} from '@angular/common/http';
import {BOOKING_PAGE_VIEWS, GTM_EVENTS, WIDGET_TEMPLATES_CONST} from '../../../../constants/app.constants';
import {WidgetService} from '../../../../services/feature/widget.service';
import {WidgetTemplateModel} from '../../../../models/widget-template.model';
import {GoogleAnalyticsService} from '../../../../services/utils/google-analytics.service';
import {SwRouteService} from '../../../../services/utils/sw-route.service';

@Component({
  selector: 'app-services-selection',
  templateUrl: './services-selection.component.html',
  styleUrls: ['./services-selection.component.scss']
})
export class ServicesSelectionComponent implements OnInit {

  showDependenciesGhostElement = false;
  dependenciesGhostElements = [1, 2];
  supportedAppointmentServiceIds: number[];
  services: AppointmentServiceModel[];
  panelOpenState = false;
  servicesGroupedData: {
    key: string,
    temp: {
      appointment_service_category: AppointmentServiceCategoryModel,
    },
    value: AppointmentServiceModel[],
  }[] = [];
  lang: string;
  serviceLabelTemplate: WidgetTemplateModel;
  showErrorMessage = false;
  isGTMEventSent: boolean;

  @Output() nextPageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() previousPageEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public translate: TranslateService,
    public widgetService: WidgetService,
    public bookingService: BookingService,
    private helperService: HelperService,
    private customEventService: CustomEventService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private swRouteService: SwRouteService
  ) {
  }

  ngOnInit(): void {
    this.isGTMEventSent = false;
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(
      (language: LangChangeEvent) => {
        this.lang = language.lang;
      }
    );
    this.bookingService.loadAppointmentStateFromLocalStorage();
    this.supportedAppointmentServiceIds = this.bookingService.getSupportedAppointmentServiceIdsFromLocalStorage(
      this.bookingService.partnerData.booking_name
    );
    if (this.widgetService?.widgetConf?.context?.show_meeting_type_picker
      &&
      this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId !== 1
    ) {
      this.getAppointmentSevicesByMeetingTypes();
    } else {
      this.getAppointmentSevices();
    }
    this.customEventService.toggleFillContainer.emit(true);
    this.setupTemplates();
  }

  getAppointmentSevicesByMeetingTypes(): void {
    LoggerService.log('Inside getAppointmentSevicesByMeetingTypes()');
    const meetingTypeId: number = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId;
    this.showDependenciesGhostElement = true;
    this.bookingService.getAppointmentSevicesByMeetingType(meetingTypeId).subscribe({
      next: (result: AppointmentServiceModel[]) => {
        if (result) {
          this.services = result;
          if (!this.widgetService.widgetConf.context.is_internal) {
            this.services = this.services.filter((service: AppointmentServiceModel) => {
              return service.is_internal !== 1;
            });
          }

          let filteredService: AppointmentServiceModel[] = [];
          // filter supported appointment service ids from all ther services
          if (this.supportedAppointmentServiceIds.length > 0) {
            filteredService = this.services.filter((service: AppointmentServiceModel) => {
              return this.supportedAppointmentServiceIds.includes(service.id);
            });
          }

          // show supported appointment service ids if present; in-case not present then show all the services
          if (filteredService.length > 0) {
            this.services = filteredService;
          }

          this.setupAppointmentServiceCategories(this.services);
          if (this.services?.length === 1) {
            LoggerService.log('Inside getAppointmentSevices() if block this.services ', this.services, this.bookingService
              .selectedBookingData
              .currentSelectedAppointmentState
              .selectedServiceIds);

            LoggerService.log('Selecting 0 index service');
            this.onSelect(this.services[0]);
            if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.WORKERS_VIEW) {
              // this.previous();
            } else {
              this.next(false);
            }
            // this.next();
          } else if (
            this.services?.length > 1 &&
            this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedServiceIds?.length > 0
          ) {
            LoggerService.log('Inside getAppointmentSevices() else if block this.services ', this.services);
            for (const service of this.services) {
              if (
                this.bookingService.selectedBookingData
                  .currentSelectedAppointmentState
                  .selectedServiceIds?.indexOf(service.id) > -1) {
                this.onSelect(service, false);
              }
            }
            if (this.bookingService.selectedBookingData?.selectedServices?.length > 0) {
              if (this.widgetService.isDefaultMeetingTypeIdSelected()) {
                if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.WORKERS_VIEW) {
                  this.previous();
                } else {
                  this.next(false);
                }
              } else {
                if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.WORKERS_VIEW) {
                  // this.previous();
                } else {
                  this.next(false);
                }
              }
            }
          }
        } else {
          // TODO show error if services are not there
          this.showDependenciesGhostElement = false;
        }
      },
      error: (error: HttpErrorResponse) => {
        this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_failed);
        this.bookingService.gtmStartTriggered = false;
        this.showDependenciesGhostElement = false;
        this.showErrorMessage = true;
        LoggerService.error(error);
      }
    });
  }


  getAppointmentSevices(): void {
    LoggerService.log('Inside getAppointmentSevices()');
    const storeId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId;
    const selectedStoreUuids = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreUuids;
    const workerId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId;
    const workerEmail = this.widgetService.widgetConf?.context?.worker_email;
    this.showDependenciesGhostElement = true;

    this.bookingService.getAppointmentSevices({
      store_id: selectedStoreUuids?.length ? null : storeId,
      worker_id: workerId || null,
      worker_email: workerEmail || null,
    }).subscribe({
      next: result => {
        if (result) {
          this.services = result;
          if (!this.widgetService.widgetConf.context.is_internal) {
            this.services = this.services.filter(service => service.is_internal !== 1);
          }

          let filteredService: AppointmentServiceModel[] = [];
          // filter supported appointment service ids from all ther services
          if (this.supportedAppointmentServiceIds.length > 0) {
            filteredService = this.services.filter((service: AppointmentServiceModel) => {
              return this.supportedAppointmentServiceIds.includes(service.id);
            });
          }

          // show supported appointment service ids if present; in-case not present then show all the services
          if (filteredService.length > 0) {
            this.services = filteredService;
          }

          this.setupAppointmentServiceCategories(this.services);
          if (this.services?.length === 1) {
            LoggerService.log('Selecting 0 index service');
            this.onSelect(this.services[0], false);
            if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.WORKERS_VIEW) {
              // this.previous();
            } else {
              // check if service is pre-selected via URL param then skip step even
              // though auto skip is disabled
              if (
                this.swRouteService.isAutoSkipStepsEnabled() ||
                this.widgetService.isDefaultAppointmentServiceIdsSelected()
              ) {
                this.next(false);
              }
            }
          } else if (
            this.services?.length > 1 &&
            this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedServiceIds?.length > 0
          ) {
            LoggerService.log('Inside getAppointmentSevices() else if block this.services ', this.services);
            for (const service of this.services) {
              if (
                this.bookingService.selectedBookingData
                  .currentSelectedAppointmentState
                  .selectedServiceIds?.indexOf(service.id) > -1) {
                this.onSelect(service, false);
              }
            }

            LoggerService.log('widgetConf ', this.widgetService.widgetConf);

            if (this.bookingService.selectedBookingData?.selectedServices?.length > 0) {
              if (
                this.widgetService.isDefaultAppointmentServiceIdsSelected()
              ) {
                if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.WORKERS_VIEW) {
                  this.previous();
                } else {
                  this.next(false);
                }
              } else {
                if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.WORKERS_VIEW) {
                  // this.previous();
                } else {
                  if (Number(this.bookingService.partnerData.is_widget_postcode_worker_random_selection_enabled) === 0) {
                    this.next(false);
                  }
                }
              }
            }
          }
        } else {
          // TODO show error if services are not there
          this.showDependenciesGhostElement = false;
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error is ', error);
      }
    });
  }

  setupAppointmentServiceCategories(services: AppointmentServiceModel[]): void {
    this.showDependenciesGhostElement = false;
    this.servicesGroupedData = this.helperService.groupBy(
      services,
      'appointment_service_category_id',
      'position',
      'position',
      'appointment_service_category'
    );
    LoggerService.log('data ', this.servicesGroupedData);
  }

  next(isUserInteracted = true): void {
    if (isUserInteracted) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_services_end);
    }
    this.bookingService.setAppointmentStateInLocalStorage(
      this.bookingService.selectedBookingData.currentSelectedAppointmentState
    );
    this.customEventService.toggleFillContainer.emit(false);

    this.nextPageEvent.emit(BOOKING_PAGE_VIEWS.WORKERS_VIEW);
  }

  previous(): void {
    if (this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_services_end);
    }
    this.customEventService.toggleFillContainer.emit(false);
    if (
      this.widgetService?.widgetConf?.context?.show_meeting_type_picker
      &&
      this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId !== 1
    ) {
      this.previousPageEvent.emit(BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW);
    } else {
      this.previousPageEvent.emit(BOOKING_PAGE_VIEWS.STORE_VIEW);
    }
  }

  toggleServiceSelection(serviceData: AppointmentServiceModel): void {
    if (this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) > -1) {
      this.onDeSelect(serviceData);
    } else {
      this.onSelect(serviceData);
    }
  }

  onSelect(serviceData: AppointmentServiceModel, isUserInteracted = true): void {
    if (isUserInteracted) {
      // trigger start event on first user interaction happen and
      // service selection selection step is configured to first step but
      // only single service selection is present
      this.bookingService.triggerApoointmentStartEvent();

      if (!this.isGTMEventSent) {
        this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_services_start);
        this.isGTMEventSent = true;
      }
    }
    this.bookingService.selectedBookingData.selectedServices.push(serviceData);

    if (this.bookingService.partnerData.allow_multiple_services_bookings === 1) {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.push(serviceData.id);
    } else {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds = [serviceData.id];
    }

    LoggerService.log('currentSelectedAppointmentState ', this.bookingService.selectedBookingData.currentSelectedAppointmentState);
  }

  onDeSelect(serviceData: AppointmentServiceModel): void {
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds = this.bookingService.selectedBookingData
      .currentSelectedAppointmentState.selectedServiceIds
      .filter((item: number) => {
        return item !== serviceData.id;
      });

    LoggerService.log('currentSelectedAppointmentState ', this.bookingService.selectedBookingData.currentSelectedAppointmentState);
  }

  setupTemplates(): void {
    this.serviceLabelTemplate = this.bookingService.widgetTemplates.find((template: WidgetTemplateModel) => {
      return (template).identifier === WIDGET_TEMPLATES_CONST.WIDGET_APPOINTMENT_SERVICE_LABEL;
    });
    if (this.serviceLabelTemplate) {
      this.serviceLabelTemplate.is_multi_language = 1;
    }
  }
}
