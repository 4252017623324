import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {WidgetTemplateModel} from '../../../../models/widget-template.model';
import {BOOKING_PAGE_VIEWS, GTM_EVENTS, WIDGET_TEMPLATES_CONST} from '../../../../constants/app.constants';
import {BookingService} from '../../../../services/feature/booking.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {WidgetService} from '../../../../services/feature/widget.service';
import {GoogleAnalyticsService} from '../../../../services/utils/google-analytics.service';

@Component({
  selector: 'app-booking-success-view',
  templateUrl: './booking-success-view.component.html',
  styleUrls: ['./booking-success-view.component.scss']
})
export class BookingSuccessViewComponent implements OnInit {

  successTemplate: WidgetTemplateModel;
  lang: string

  @Output() nextPageEvent = new EventEmitter<any>();
  @Output() previousPageEvent = new EventEmitter<any>();

  constructor(
    private bookingService: BookingService,
    private translate: TranslateService,
    public widgetService: WidgetService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_success);
    this.setupTemplates();
  }

  setupTemplates(): void {
    this.successTemplate = this.bookingService.widgetTemplates.find(template => {
      return (template).identifier === WIDGET_TEMPLATES_CONST.WIDGET_BOOKING_INFO_APPOINTMENT;
    });
    if (this.successTemplate) {
      this.successTemplate.is_multi_language = 1;
    }
  }

  onAddtionalBooking(): void {
    this.bookingService.gtmStartTriggered = false;
    if (this.widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
      this.nextPageEvent.emit(BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW);
    } else {
      if (this.widgetService?.widgetConf?.context?.show_meeting_type_picker) {
        this.nextPageEvent.emit(BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW);
      } else {
        this.nextPageEvent.emit(BOOKING_PAGE_VIEWS.STORE_VIEW);
      }
    }
  }
}
