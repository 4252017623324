<ng-container *ngIf="widgetService.partnerUUID">
  <link rel="stylesheet" type="text/css"
        [href]="('/assets/css/survey/' + widgetService.widgetConf.partner.booking_name + '_' + widgetService.widgetUUID + '.css') | calioSafe: 'resourceUrl'">
</ng-container>

<div *ngIf="showSkeleton">
  <div class="skeleton-box-card">
    <div class="">
      <div class="row mb-4" *ngFor="let ghostElement of [1,2,3,4, 5, 6]">
        <div class="col-md-12">
          <div class="d-flex stats-card">
            <div
              class="skeleton-animation w-100 d-flex justify-content-center flex-column">
              <!--<div class="bar bar-1"></div>
              <div class="bar bar-2"></div>-->
              <div class="">
                <div class="stat-box"></div>
                <div class="stat-box-1 mt-1"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3" *ngFor="let ghostElement of [1,2,3,4]">
          <div class="d-flex stats-card shadow">
            <div
              class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
              <!--<div class="bar bar-1"></div>
              <div class="bar bar-2"></div>-->
              <div class="">
                <div class="stat-box"></div>
                <div class="stat-box-1 mt-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="survey-app">
  <mat-horizontal-stepper
    #stepper
    [disableRipple]="true" class="pt-4"
    [ngStyle]="{ 'd-none': showSkeleton, 'd-block': !showSkeleton }"
    [linear]="showCustomForm"
    (selectionChange)="onStepChanged($event)"
  >
    <ng-container *ngFor="let surveyStep of surveyService?.surveySteps; let last=last; let index=index;">
      <form #stepperForm="ngForm">
        <mat-step [completed]="!showCustomForm"
        >
          <ng-template matStepLabel>
            {{  surveyStep | translation: 'title': lang  }}
          </ng-template>
          <div class="mt-4">
            <div>
              <app-csw-text
                [text]="(surveyStep.is_start === 1) ? ( surveyStep | translation: 'title': lang ) : surveyStep.step_number + ' - ' + ( surveyStep | translation: 'title': lang )"
                [styleConfig]="{ fontWeight: 600, fontSize: '28px' }"
              >
              </app-csw-text>
            </div>
            <div class="mt-3">
              <app-csw-text
                [text]="( surveyStep | translation: 'description': lang )"
                [styleConfig]="{ }"
              >
              </app-csw-text>
            </div>
          </div>

          <div class="mt-5">
            <div *ngIf="showErrorMessage" class="alert alert-danger">
              {{ errorMessage | translate }}
            </div>

            <ng-container *ngIf="surveyStep.is_start === 1">

              <app-csw-text
                [text]="((widgetService?.widgetConf | translation: 'start_text': lang) | calioSafe : 'html')"
                [styleConfig]="{ }"
              >
              </app-csw-text>

              <div class="mt-3" *ngIf="showCustomForm">
                <app-survey-customer-form></app-survey-customer-form>
              </div>

            </ng-container>

            <ng-container *ngIf="surveyStep.is_end === 1">
              <app-survey-end-step></app-survey-end-step>
            </ng-container>

            <ng-container *ngIf="surveyStep.is_start !== 1 && surveyStep.is_end !== 1">

              <ng-container *ngIf="showStepperContent">
                <ng-container *ngIf="surveyStep.is_appointment_booking_step === 0">
                  <app-survey-questions-view
                    [currentSurveyStep]="surveyStep"
                  >
                  </app-survey-questions-view>
                </ng-container>

                <ng-container *ngIf="surveyStep.is_appointment_booking_step === 1">

                  <app-survey-final-step
                    *ngIf="showFinalStep"
                    (endProcessEvent)="onEndProcessEvent();goForward(stepper, surveyStep);"
                    (bookAppointmentEvent)="onBookAppointmentEvent()"
                  >

                  </app-survey-final-step>
                  <div [ngStyle]="{
                    display: showBookingWidget ? 'block': 'none'
                  }">
                    <app-survey-integrate-booking-widget>

                    </app-survey-integrate-booking-widget>
                  </div>

                </ng-container>

              </ng-container>

            </ng-container>

          </div>

          <div class="cbw-survey-buttons">
            <div class="pe-2" *ngIf="surveyStep.is_start !== 1">
              <ng-container *ngIf="!last">
                <button
                  type="button"
                  class="btn btn-primary"
                  [ngStyle]="{
                  background: widgetService?.widgetConf?.button_background_color,
                  color: widgetService?.widgetConf?.button_font_color,
                  borderColor: widgetService?.widgetConf?.button_background_color,
                  borderRadius: '0px'
                    }"
                  (click)="goBackward(stepper)"
                >
                  <div class="d-flex align-items-center">
                    <div class="pe-3" *ngIf="surveyStep.is_start !== 1">
                      <mat-icon class="button-icon d-flex" svgIcon="chevron-left-solid"></mat-icon>
                    </div>
                    <div>
                      <app-csw-text
                        *ngIf="surveyStep.is_start !== 1"
                        [text]="('form_fields.previous' | translate) + ': ' + ( surveyService?.surveySteps[index - 1] | translation: 'title': lang )"
                        [styleConfig]="{ fontSize: '14px' }"
                      >
                      </app-csw-text>
                    </div>
                  </div>

                </button>
              </ng-container>
            </div>

            <div class="">
              <ng-container *ngIf="!last">
                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="handleNextButtonState(surveyStep, stepperForm.invalid)"
                  [ngStyle]="{
                    background: widgetService?.widgetConf?.button_background_color,
                    color: widgetService?.widgetConf?.button_font_color,
                    borderColor: widgetService?.widgetConf?.button_background_color,
                    borderRadius: '0px'
                  }"
                  (click)="goForward(stepper, surveyStep)"
                >
                  <div class="d-flex align-items-center">
                    <div>
                      <app-csw-text
                        *ngIf="surveyStep.is_start === 1"
                        [text]="'form_fields.start' | translate"
                        [styleConfig]="{ fontSize: '14px' }"
                      >
                      </app-csw-text>
                      <app-csw-text
                        *ngIf="surveyStep.is_start !== 1"
                        [text]="('form_fields.next' | translate) + ': ' + ( surveyService?.surveySteps[index + 1] | translation: 'title': lang )"
                        [styleConfig]="{ fontSize: '14px' }"
                      >
                      </app-csw-text>
                    </div>
                    <div class="ps-3" *ngIf="surveyStep.is_start !== 1">
                      <mat-icon class="button-icon d-flex" svgIcon="chevron-right-solid"></mat-icon>
                    </div>
                  </div>

                </button>
              </ng-container>

              <!--<ng-container *ngIf="last">
                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="stepperForm.invalid"
                  [ngStyle]="{
                    background: widgetService?.widgetConf?.button_background_color,
                    color: widgetService?.widgetConf?.button_font_color,
                    borderColor: widgetService?.widgetConf?.button_background_color
                  }"
                  (click)="onDone()"
                >
                  Done
                </button>
              </ng-container>-->

            </div>
          </div>
        </mat-step>
      </form>
    </ng-container>
  </mat-horizontal-stepper>
</div>

<div *ngIf="surveyService?.partnerData?.analytics?.GTM && surveyService?.partnerData?.analytics?.GTM.trim() !== ''">
  <app-gtm-operation
    [gtmID]="surveyService?.partnerData?.analytics?.GTM">
  </app-gtm-operation>
</div>
