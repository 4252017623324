<div class="message-container text-center px-2 cbsw-failed-booking-step">
  <div class="mb-1">
    <app-csw-text
      [text]="'booking_components.errorTitle' | translate"
      [styleConfig]="{ fontWeight: 600 }"
    />
  </div>

  <div>
    <app-csw-text [text]="errorMessage | translate" />
  </div>

  <div class="mt-4">
    <app-button
      [id]="'booking-failed-addional-booking-btn'"
      (clickEvent)="onAddtionalBooking()"
      [fontColor]="widgetService?.widgetConf?.button_font_color"
      [backgroundColor]="widgetService?.widgetConf?.button_background_color"
    >
      {{ 'booking_components.bookAppointmentAgain' | translate }}
    </app-button>
  </div>
</div>
