import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-csw-link',
  templateUrl: './csw-link.component.html',
  styleUrls: ['./csw-link.component.scss']
})
export class CswLinkComponent implements OnInit {

  @Input() isLink: boolean = Boolean(true);
  @Input() target: string = String('blank');
  @Input() text: string;
  @Input() link: string;
  @Input() styleConfig: {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
    textDecorationColor?: string
  } = {};

  @Input() showUnderLine: boolean = Boolean(false);

  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onLinkClick(): void {
    this.clickEvent.emit();
  }

}
