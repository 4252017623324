<a class="anchor-link" *ngIf="!isLink" href="javascript:void(0)" (click)="onLinkClick()"
   [ngClass]="{ 'show-under-line': showUnderLine  }"
   [ngStyle]="styleConfig"
>
  <span [ngStyle]="styleConfig">{{ text }}</span>
</a>

<a class="anchor-link" *ngIf="isLink" [href]="link" (click)="onLinkClick()"
   [target]="target"
   [ngClass]="{ 'show-under-line': showUnderLine  }"
   [ngStyle]="styleConfig"
>
  <span [ngStyle]="styleConfig">{{ text }}</span>
</a>
