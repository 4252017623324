import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-csw-text',
  templateUrl: './csw-text.component.html',
  styleUrls: ['./csw-text.component.scss']
})
export class CswTextComponent implements OnInit {

  @Input() text: any;
  @Input() styleConfig: {
    color?: string,
    fontSize?: string,
    fontWeight?: string
  } = {};
  @Input() customClass: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
