<ng-container *ngIf="widgetService.widgetUUID">
  <link
    rel="stylesheet" type="text/css"
    [href]="('/assets/css/booking/' + widgetService.widgetConf.partner.booking_name + '_' + widgetService.widgetUUID + '.css') | calioSafe: 'resourceUrl'"
  >
</ng-container>

<div
  class="bookings-section"
  [@fadeInFadeOut]="counter"
>
  <ng-container *ngIf="this.widgetTemplates?.length > 0">
    <ng-container
      *ngIf="selectedView === bookingPageViews.BOOKING_QUESTIONS_VIEW && widgetService?.widgetConf?.context?.show_questions_before_booking_process">
      <app-booking-questions-view
        [isFirstBookingQuestionActive]="isFirstBookingQuestionActive"
        [isLastBookingQuestionActive]="isLastBookingQuestionActive"
        (nextPageEvent)="increment(); isFirstBookingQuestionActive = false; isLastBookingQuestionActive = true; gotoView($event); bookingService.lastView = bookingPageViews.BOOKING_QUESTIONS_VIEW"
        (previousPageEvent)="decrement(); isFirstBookingQuestionActive = true; isLastBookingQuestionActive = false; gotoView($event); bookingService.lastView = bookingPageViews.BOOKING_QUESTIONS_VIEW"
      >
      </app-booking-questions-view>
    </ng-container>

    <ng-container
      *ngIf="selectedView === bookingPageViews.MEETING_TYPE_VIEW && widgetService?.widgetConf?.context?.show_meeting_type_picker">
      <app-meeting-type-selection
        (gotoPage)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.MEETING_TYPE_VIEW"
        (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.MEETING_TYPE_VIEW"
      ></app-meeting-type-selection>
    </ng-container>

    <ng-container
      *ngIf="selectedView === bookingPageViews.STORE_VIEW
      && (!widgetService?.widgetConf?.context?.show_meeting_type_picker
           ||
           (widgetService?.widgetConf?.context?.show_meeting_type_picker
             &&
            bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId === 1
           )
        )
      "
    >
      <app-store-selection
        (gotoPage)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.STORE_VIEW"
        (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.STORE_VIEW"
      ></app-store-selection>
    </ng-container>

    <ng-container *ngIf="selectedView === bookingPageViews.SERVICES_VIEW">
      <app-services-selection
        (nextPageEvent)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.SERVICES_VIEW"
        (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.SERVICES_VIEW"
      ></app-services-selection>
    </ng-container>

    <ng-container *ngIf="selectedView === bookingPageViews.WORKERS_VIEW">
      <app-workers-selection
        (nextPageEvent)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.WORKERS_VIEW"
        (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.SLOTS_VIEW"
      ></app-workers-selection>
    </ng-container>

    <ng-container *ngIf="selectedView === bookingPageViews.SLOTS_VIEW">
      <app-slots-selection
        (nextPageEvent)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.SLOTS_VIEW"
        (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.SLOTS_VIEW"
      ></app-slots-selection>
    </ng-container>

    <ng-container
      *ngIf="selectedView === bookingPageViews.BOOKING_QUESTIONS_VIEW && !widgetService?.widgetConf?.context?.show_questions_before_booking_process">
      <app-booking-questions-view
        [isFirstBookingQuestionActive]="isFirstBookingQuestionActive"
        [isLastBookingQuestionActive]="isLastBookingQuestionActive"
        (nextPageEvent)="increment(); isFirstBookingQuestionActive = false; isLastBookingQuestionActive = true; gotoView($event)"
        (previousPageEvent)="decrement(); isFirstBookingQuestionActive = true; isLastBookingQuestionActive = false; gotoView($event)"
      >
      </app-booking-questions-view>
    </ng-container>

    <ng-container *ngIf="selectedView === bookingPageViews.PERSONAL_FORM_VIEW">
      <app-personal-form-view
        (nextPageEvent)="increment(); gotoView($event)"
        (previousPageEvent)="decrement(); isFirstBookingQuestionActive = false; isLastBookingQuestionActive = true; gotoView($event);"
        [partnerData]="bookingService?.partnerData"
      ></app-personal-form-view>
    </ng-container>

    <ng-container *ngIf="selectedView === bookingPageViews.BOOKING_SUMMARY_VIEW">
      <app-booking-summary-view
        (nextPageEvent)="increment(); gotoView($event)"
        (previousPageEvent)="decrement(); gotoView($event)"
        (gotoPageEvent)="gotoView($event)"
      ></app-booking-summary-view>
    </ng-container>

    <ng-container *ngIf="selectedView === bookingPageViews.BOOKING_SUCCESS_VIEW">
      <app-booking-success-view
        (nextPageEvent)="increment(); gotoView($event)"
        (previousPageEvent)="decrement(); gotoView($event)"
      ></app-booking-success-view>
    </ng-container>

    <ng-container *ngIf="selectedView === bookingPageViews.BOOKING_FAILED_VIEW">
      <app-booking-failed-view
        (nextPageEvent)="increment(); gotoView($event)"
        (previousPageEvent)="decrement(); gotoView($event)"
      ></app-booking-failed-view>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="bookingService?.partnerData?.analytics?.GTM && bookingService?.partnerData?.analytics?.GTM.trim() !== ''">
  <app-gtm-operation
    [gtmID]="bookingService?.partnerData?.analytics?.GTM">
  </app-gtm-operation>
</div>
