import {NgModule} from '@angular/core';
import {LeadGeneratorComponent} from './lead-generator.component';
import {SharedModule} from '../../shared/shared.module';
import {AlertModule} from '../../ui-lib/alert/alert.module';
import {BookingQuestionsModule} from '../../ui-lib/booking-questions/booking-questions.module';
import {BookingQuestionsComponent} from './components/booking-questions/booking-questions.component';
import {FormsModule} from '@angular/forms';
import {ButtonsModule} from '../../ui-lib/buttons/buttons.module';
import {NgSelectModule} from '@ng-select/ng-select';
import { LeadWorkersSelectionComponent } from './components/lead-workers-selection/lead-workers-selection.component';


@NgModule({
  declarations: [
    LeadGeneratorComponent,
    BookingQuestionsComponent,
    LeadWorkersSelectionComponent,
  ],
  imports: [
    SharedModule,
    AlertModule,
    BookingQuestionsModule,
    FormsModule,
    ButtonsModule,
    NgSelectModule
  ],
  exports: [LeadGeneratorComponent],
})
export class LeadGeneratorModule {
}
