import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {YoutubeModalComponent} from './youtube-modal/youtube-modal.component';
import {CalioPipesModule} from '../../pipes/calio-pipes.module';
import {ImageModalComponent} from './image-modal/image-modal.component';


@NgModule({
  declarations: [
    YoutubeModalComponent,
    ImageModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    CalioPipesModule,
  ],
  exports: [
    YoutubeModalComponent,
    ImageModalComponent
  ]
})
export class ModalsModule {
}
