<form #bookingQuestionForm="ngForm">
  <ng-template #recursiveListTmpl let-customFieldDependencies="customFieldDependencies">
    <div *ngFor="
      let dependencyCustomField of customFieldDependencies;
      let childIndex = index;
      let childIsFirst = first;
      let childIsLast = last;
    ">
      <app-booking-question
        [customField]="dependencyCustomField"
        [selectedCustomFieldValues]="bookingService?.selectedBookingData?.selectedCustomFieldValues"
        (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
        [isNextButtonPressed]="isNextButtonPressed"
      >
      </app-booking-question>

      <div *ngIf="dependencyCustomField?.customFieldDependencies?.length > 0">
        <ng-container
          *ngTemplateOutlet="recursiveListTmpl; context:{ customFieldDependencies: dependencyCustomField?.customFieldDependencies }"></ng-container>
      </div>
    </div>
  </ng-template>


  <app-booking-question
    [customField]="widgetQuestion"
    [selectedCustomFieldValues]="bookingService?.selectedBookingData?.selectedCustomFieldValues"
    [isNextButtonPressed]="isNextButtonPressed"
    (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
    (changeCustomFieldSelectedValueEvent)="customFieldChangeDetection($event)"
  >
  </app-booking-question>

  <ng-container *ngIf="widgetQuestion?.customFieldDependencies?.length > 0">
    <ng-container
      *ngTemplateOutlet="recursiveListTmpl; context:{ customFieldDependencies: widgetQuestion?.customFieldDependencies }">
    </ng-container>
  </ng-container>

  <ng-container *ngIf="showDependenciesGhostElement">
    <div class="skeleton-box-card mt-4">
      <div class="">
        <div class="row">
          <div class="col-md-12 mb-4"
               *ngFor="let dependenciesGhostElement of dependenciesGhostElements">
            <div class="d-flex stats-card shadow">
              <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
                <div class="">
                  <div class="stat-box-1"></div>
                  <div class="stat-box mt-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!showDependenciesGhostElement" class="d-flex justify-content-end mt-4 cbw-custom-navigation">
    <ng-container *ngIf="widgetQuestion?.is_end_of_process !== 1 && !this.isEndOfProcessByChildDependencies">
      <div class="me-2">
        <app-button
          [id]="'booking-questions-previous-btn'"
          [isDisabled]="isFirst && widgetService?.widgetConf?.context?.show_questions_before_booking_process"
          (clickEvent)="previousQuestion()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          <span class="custom-button-icon back"></span>
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
            id: 129, type: 'template'
          }) | translation: 'template_content': lang
        )}}
        </app-button>
      </div>
      <div>
        <app-button
          [id]="'booking-questions-next-question-btn'"
          *ngIf="!isLast"
          (clickEvent)="nextQuestion()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
            id: 128, type: 'template'
          }) | translation: 'template_content': lang
        )}}
          <span class="custom-button-icon next"></span>
        </app-button>

        <app-button
          [id]="'booking-questions-finish-last-btn'"
          *ngIf="isLast"
          (clickEvent)="finishLastBookingQuestion()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
            id: 128, type: 'template'
            }) | translation: 'template_content': lang
          )}}
          <span class="custom-button-icon next"></span>
        </app-button>
      </div>
    </ng-container>
  </div>
</form>
