import {NgModule} from '@angular/core';
import {FillCalendarComponent} from './fill-calendar.component';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  declarations: [FillCalendarComponent],
  imports: [
    SharedModule
  ],
  exports: [FillCalendarComponent],
})
export class FillCalendarModule {
}
