<div class="alert alert-danger"
     *ngIf="showError"
>
  <div class="error-title">{{ errorTitle | translate }}</div>
  <div>{{ errorMessage | translate }}</div>

</div>


<div class="cbsw-booking-questions-step">
  <div class=""
    [@fadeInFadeOut]="counter"
  >
    <ng-container
      *ngFor="let widgetQuestion of widgetQuestions;
        let index = index;
        let isFirst = first;
        let isLast = last;"
    >
      <ng-container *ngIf="activeQuestion === index">
        <app-booking-question-view
          [widgetQuestion]="widgetQuestion"
          [isFirst]="isFirst"
          [isLast]="isLast"
          (previousQuestionEvent)="decrement(); previousQuestion(isFirst, widgetQuestion)"
          (nextQuestionEvent)="increment(); nextQuestion(widgetQuestion)"
          (finishLastBookingQuestionEvent)="increment(); finishLastBookingQuestion(widgetQuestion)"
        >
        </app-booking-question-view>
      </ng-container>
    </ng-container>
  </div>
</div>

