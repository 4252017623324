<div class="image-preview-wrapper">
  <div class="model-close-button">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (keydown.enter)="$event.preventDefault()"
      (keydown.escape)="close();"
      (click)="close();"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <img
    *ngIf="imageUrl"
    [src]="imageUrl"
    width="100%"
  />

  <p *ngIf="!imageUrl">Image source is missing.</p>
</div>
