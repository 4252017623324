import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { WIDGET_TYPE } from './constants/app.constants';
import { WINDOW_LISTENER_CONSTANTS } from './constants/window-listener.constants';
import { CalendarAppointmentModel } from './models/appointment-booking.model';
import { CUSTOM_CSS_WIDGET_TYPE, CssOverwrites } from './models/css-overwrites.model';
import { WidgetConfModel } from './models/widget-conf.model';
import { WorkerDbModel } from './models/worker-db.model';
import { AppointmentService } from './services/feature/appointment.service';
import { CssOverwriteService } from './services/feature/css-overwrite.service';
import { CustomEventService } from './services/feature/custom-event.service';
import { WidgetService } from './services/feature/widget.service';
import { DomManipulationService } from './services/utils/dom-manipulation.service';
import { HelperService } from './services/utils/helper.service';
import { LoggerService } from './services/utils/logger.service';
import { SwRouteService } from './services/utils/sw-route.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  readonly customCssWidgetType = CUSTOM_CSS_WIDGET_TYPE;

  showPopup = false;
  widgetTypes = WIDGET_TYPE;
  queryStringAppointmentUuid: string;
  localStorageaccessible = true;

  constructor(
    public widgetService: WidgetService,
    private translate: TranslateService,
    private customEventService: CustomEventService,
    private swRouteService: SwRouteService,
    private appointmentService: AppointmentService,
    private cssOverwriteService: CssOverwriteService,
    private domManipulationService: DomManipulationService,
    private helperService: HelperService
  ) {
    this.customEventService.setWidgetConfigurationFromQueryParamsEvent
      .subscribe((result: {
        partner_uuid: string,
        widget_uuid: string,
      }) => {
        this.setWidgetConfigurationFromQueryParams(
          result.partner_uuid,
          result.widget_uuid,
        );
      });

    this.customEventService.cssOverwriteTemplateUuid.subscribe({
      next: (template: { templateUuid: string }) => {
        const cssOverwritesObservable: Observable<CssOverwrites>[] = [];
        if (template?.templateUuid) {
          cssOverwritesObservable.push(this.cssOverwriteService.getCssOverwriteUsingUuid(template.templateUuid));
        } else {
          cssOverwritesObservable.push(this.cssOverwriteService.getCssOverwrites());
        }

        forkJoin(cssOverwritesObservable).subscribe({
          next: (res) => {
            if (res[0] === null) {
              LoggerService.warn('Invalid css-overwrite', template.templateUuid);
            } else if (this.widgetService.widgetConf.partner_id !== res[0].partner_id) {
              LoggerService.warn('Partner id is not matching for apply pre-define css-overwrite');
            } else if (res[0].type !== this.customCssWidgetType.smart_widget) {
              LoggerService.error('Invalid css-overwrite type');
            } else if (res[0].smart_widget_configuration_id !== this.widgetService.widgetConf.id) {
              if (template?.templateUuid) {
                LoggerService.warn(`Requested template(${template.templateUuid}) is not configured for this widgte(${this.widgetService.widgetConf.widget_uuid})`);
              } else {
                LoggerService.log('[css-overwrite]', `No CSS is active for this widgte(${this.widgetService.widgetConf.widget_uuid})`);
              }
            } else {
              if (template?.templateUuid) {
                LoggerService.log('[css-overwrite]', 'applying css-overwrite provided in url parameter', res[0].uuid);
              } else {
                LoggerService.log('[css-overwrite]', 'applying activated css-overwrite manually', res[0].uuid);
              }
              this.domManipulationService.applyCustomCss(res[0]);
            }
          },
          error: (error: HttpErrorResponse) => LoggerService.error(error)
        });
      }
    });
  }

  toggleWidget(): void {
    LoggerService.log('Inside toggleWidget()');
  }

  togglePopup(): void {
    this.showPopup = !this.showPopup;
  }

  ngOnInit(): void {
    LoggerService.log('Inside ngOnInit 1');

    // Show localstorage not accessable warning
    this.localStorageaccessible = this.helperService.storageAvailable('localStorage');

    this.customEventService.resetMainWidgetEvent.subscribe((result: any) => {
      this.widgetService.widgetConf = undefined;
      this.getWidgetConfiguration(this.widgetService.partnerUUID, this.widgetService.widgetUUID);
    });

    this.customEventService.queryStringAppointmentUuid.subscribe({
      next: (appointmentUuid: string) => {
        this.getAppointment(appointmentUuid);
      }
    });

    window.addEventListener('message', (event) => {
      if (event?.data) {
        if (event.data.eventName === WINDOW_LISTENER_CONSTANTS.PARENT_TO_CHILD_WEBSITE_EVENT) {
          LoggerService.log('before setting parentToChildOriginEvent');
          this.widgetService.parentToChildOriginEvent = event;
        }

        if (event.data.eventName === WINDOW_LISTENER_CONSTANTS.BOOT) {
          if (event.data.widgetConf) {
            this.widgetService.requestedClientDomain = event.data.clientDomain;
            this.widgetService.clientOriginEvent = event;
            this.widgetService.layoutType = event.data.widgetConf.layoutType;
            LoggerService.log('this.requestedClientDomain ', this.widgetService.requestedClientDomain);

            if (event.data.widgetConf.customerConfData) {
              // create customer data
              this.swRouteService.setCustomerDataFromIframePostMessage(event.data.widgetConf.customerConfData);
            }
            if (event.data.widgetConf.customFieldValues) {
              // create custom field values data
              this.swRouteService.setCustomFieldValuesFromIframePostMessage(event.data.widgetConf.customFieldValues);
            }
            // set reference appointment uuid
            if (
              event.data.widgetConf?.referenceAppointment &&
              event.data.widgetConf?.referenceAppointment?.uuid
            ) {
              LoggerService.warn('Received reference appointment UUID from post message');
              this.swRouteService.setReferenceAppointmentUuidFromIframePostMessage(event.data.widgetConf.referenceAppointment.uuid);
            }
            if (event.data.widgetConf.widgetContext) {
              // create custom field values data
              this.swRouteService.setWidgetContextDataFromIframePostMessage(event.data.widgetConf.widgetContext);
            }
            this.getWidgetConfiguration(event.data.widgetConf.partner_uuid, event.data.widgetConf.widget_uuid);
          }
        }

        if (event.data.eventName === WINDOW_LISTENER_CONSTANTS.LANGUAGE_CHANGED_EVENT) {
          LoggerService.log('INSIDE WINDOW_LISTENER_CONSTANTS.LANGUAGE_CHANGED_EVENT event.data ', event.data);
          if (event.data.language) {
            this.translate.use(event.data.language);
            this.translate.setDefaultLang(event.data.language);
          }
        }

        if (event.data.eventName === WINDOW_LISTENER_CONSTANTS.APPOINTMENT_BOOKING_DONE) {
          const appointmentBookings: CalendarAppointmentModel[] = event.data.bookingData;
          LoggerService.log('appointmentBookings ', appointmentBookings);
          this.customEventService.appointmentBookingDoneEvent.emit({
            bookingData: appointmentBookings
          });
        }
      }
    });

    this.helperService.registerSvgIcon(`calio-spinner-icon`, `assets/images/icons/spinner.svg`);
  }

  getAppointment(appointmentUuid: string): void {
    this.appointmentService.get(appointmentUuid).subscribe({
      next: (appointment) => {
        if (appointment?.id) {
          this.swRouteService.setReferenceAppointment = appointment;
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.warn('Ignoring appointment as invalid appointment uuid is passed', this.swRouteService.getReferenceAppointmentUuid);
        LoggerService.error(error);
      }
    });
  }

  setWidgetConfigurationFromQueryParams(
    partner_uuid: string,
    widget_uuid: string,
  ): void {
    LoggerService.log('Inside setWidgetConfigurationFromQueryParams()');
    this.widgetService.layoutType = 'IFRAME_URL';
    // this.widgetService.layoutType = 'CHATBOX';
    this.getWidgetConfiguration(
      partner_uuid,
      widget_uuid
    );
  }

  getWidgetConfiguration(partnerUUID: string, widgetUUID: string): void {
    this.widgetService.partnerUUID = partnerUUID;
    this.widgetService.widgetUUID = widgetUUID;
    this.widgetService.getWidgetConfiguration(partnerUUID, widgetUUID).subscribe({
      next: (result: WidgetConfModel) => {
        LoggerService.log('result ', result);
        if (!result) {
          LoggerService.warn('Widget Configuration is not available');
          return;
        }

        result = this.swRouteService.overrideWidgetConfigDataFromQueryParamData(result);

        if (this.widgetService.clientOriginEvent) {
          this.widgetService.sendMessageToMainJs(this.widgetService.clientOriginEvent, {
            eventName: WINDOW_LISTENER_CONSTANTS.INIT_CONFIG,
            widgetConf: result
          });
        }

        this.widgetService.widgetConf = result;
        this.cssOverwriteService.checkForCssTemplateOrActiveCss();
        this.setupWidgetLang(this.widgetService.widgetConf);
        this.setupWidgetWorker(this.widgetService.widgetConf);
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  setupWidgetLang(widgetConf: WidgetConfModel): void {
    LoggerService.log('Inside setupWidgetLang widgetConf?.context?.locale', widgetConf?.context?.locale);
    LoggerService.log('Inside setupWidgetLang widgetConf.locale', widgetConf.locale);
    if (widgetConf?.context?.locale) {
      LoggerService.log('Inside if setup widget lang');
      this.translate.use(widgetConf?.context?.locale);
      this.translate.setDefaultLang(widgetConf?.context?.locale);
    } else if (widgetConf?.locale) {
      // TODO Fallback to widget config locale but will remove this code in future
      LoggerService.log('Inside setup widget lang');
      this.translate.use(widgetConf.locale);
      this.translate.setDefaultLang(widgetConf.locale);
    }
  }

  setupWidgetWorker(widgetConf: WidgetConfModel): void {
    if (widgetConf?.context?.worker_uuid) {
      this.widgetService.getWidgetWorker(widgetConf.context?.worker_uuid).subscribe(
        (result: WorkerDbModel) => {
          if (result) {
            this.widgetService.widgetWorkerData = result;
          } else {
            console.warn('Widget worker is not available');
          }
        }
      );
    }
  }
}
