export const environment = {
  version: 'v6.1.1',
  production: false,
  restAPI: 'https://pm-dev-api.calenso.com/api/v1/',
  bookingWebcomponentUrl: 'https://pm-dev-webcomponent.calenso.com/booking.html',
  hostName: 'https://pm-dev-api.calenso.com',
  workerAvatarBaseUrl: 'https://pm-dev-api.calenso.com/webroot/files/workers/avatar/',
  storeBaseUrl: 'https://pm-dev-api.calenso.com/webroot/files/stores/logo/',
  deployUrl: 'https://pm-dev-smartwidget.calenso.com/',
  stripeAPIKey: 'pk_test_qC6Q2zbwLgHgHEzXkHdKATbR',
  encryptedKey: '6LfB5EchAAAAAMiJapMV9GumBAQBfKqboRWT5she'
};
