import {Component, OnInit} from '@angular/core';
import {WidgetService} from '../../../../services/feature/widget.service';
import {ControlContainer, NgForm} from '@angular/forms';
import {EMAIL_REGEX} from '../../../../constants/app.constants';

@Component({
  selector: 'app-survey-customer-form',
  templateUrl: './survey-customer-form.component.html',
  styleUrls: ['./survey-customer-form.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SurveyCustomerFormComponent implements OnInit {

  disableEmailField = false;
  customerFields = ['prename', 'lastname', 'email'];
  readonly emailRegex = EMAIL_REGEX;

  constructor(
    public widgetService: WidgetService
  ) {
  }

  ngOnInit(): void {
    if (this.widgetService.customerConfData.email) {
      this.disableEmailField = true;
    }

    if (this.widgetService.widgetConf.context?.ask_customer_city === 1) {
      this.customerFields.push('city');
    }

    if (this.widgetService.widgetConf.context?.ask_customer_zip === 1) {
      this.customerFields.push('zip');
    }
  }
}
