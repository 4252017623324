import { STEP_STATE, StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { GTM_EVENTS } from '../../constants/app.constants';
import { WINDOW_LISTENER_CONSTANTS } from '../../constants/window-listener.constants';
import { CalendarAppointmentModel } from '../../models/appointment-booking.model';
import { CustomFieldModel } from '../../models/custom-field.model';
import { CustomerDbModel } from '../../models/customer.model';
import { PartnerDbModel, TokenType } from '../../models/partner-db.model';
import { SurveyCustomerSmartWidgetConfigurationModel } from '../../models/survey-custom-smart-widget-configuration.model';
import { SurveyStepModel } from '../../models/survey-step.model';
import { CustomEventService } from '../../services/feature/custom-event.service';
import { SurveyService } from '../../services/feature/survey.service';
import { WidgetService } from '../../services/feature/widget.service';
import { DateUtilService } from '../../services/utils/date-util.service';
import { GoogleAnalyticsService } from '../../services/utils/google-analytics.service';
import { HelperService } from '../../services/utils/helper.service';
import { LoggerService } from '../../services/utils/logger.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit, AfterViewInit {

  errorMessage: string;
  showErrorMessage = false;
  showSkeleton = true;
  imagePath = environment.hostName;
  totalQuestionCount = 0;
  totalQuestionsAnsweredCount = 0;
  showStepperContent = false;
  showFinalStep = true;
  showBookingWidget = false;
  showCustomForm = false;
  hiddenBookingQuestions: CustomFieldModel[] = [];
  lang: string;
  partnerData: PartnerDbModel;

  @ViewChild('stepper') matStepper: MatStepper;

  constructor(
    public widgetService: WidgetService,
    public surveyService: SurveyService,
    private helperService: HelperService,
    private customEventService: CustomEventService,
    private translate: TranslateService,
    private dateUtilService: DateUtilService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe({
      next: (language: LangChangeEvent) => {
        this.lang = language.lang;
      }
    });
  }

  ngOnInit(): void {
    this.getWidgetSteps();
    this.customEventService.appointmentBookingDoneEvent.subscribe({
      next: (result: {
        bookingData: CalendarAppointmentModel[]
      }) => {
        LoggerService.log('Inside appointmentBookingDoneEvent subscribe result is ', result);
        this.surveyService.bookingData = result.bookingData;
      }
    });

    this.customEventService.progressBarCountEvent.subscribe({
      next: (result: {
        index: number,
        isLast: boolean,
      }) => {
        LoggerService.log('result is ', result);
        this.totalQuestionsAnsweredCount = result.index;
        this.sendProgressBarCount(result.isLast);
      }
    });

    if (this.widgetService.customerConfData.uuid || this.widgetService.customerConfData.email) {
      this.getFilteredCustomers();
    } else {
      this.showCustomForm = true;
    }

    this.initializePartnerData(this.widgetService.partnerUUID);
  }

  initializePartnerData(partnerUUID: string): void {
    this.surveyService.initializePartnerData(partnerUUID, TokenType.publicSmartwidget_survey).subscribe({
      next: (result: PartnerDbModel) => {
        LoggerService.log('Inside initializePartnerData testing result is ', result);
        if (result) {
          this.partnerData = result;
          this.surveyService.partnerData = this.partnerData;
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  getWidgetSteps(): void {
    this.surveyService.getWidgetSteps(this.widgetService.widgetConf.context.steps).subscribe({
      next: (result: SurveyStepModel[]) => {
        LoggerService.log('widget steps result is ', result);
        this.showSkeleton = false;
        if (result?.length > 0) {
          let totalQuestionCount: number = Number(0);
          let totalRequiredQuestionCount: number = Number(0);
          for (const survey of result) {
            if (survey.booking_questions?.length > 0) {
              for (const cf of survey.booking_questions) {
                if (cf.is_hidden === 1) {
                  this.hiddenBookingQuestions.push(cf);
                }
              }

              survey.booking_questions = survey.booking_questions.filter((item: CustomFieldModel) => {
                return item.is_hidden === 0;
              });
            }

            if (survey.booking_questions?.length > 0) {
              const tempFields: CustomFieldModel[] = survey.booking_questions.filter((item: CustomFieldModel) => {
                return item.required === 1;
              });
              totalRequiredQuestionCount = totalRequiredQuestionCount + tempFields.length;
              totalQuestionCount = totalQuestionCount + survey.booking_questions.length;
            }
          }

          this.totalQuestionCount = totalRequiredQuestionCount + 1;
          totalQuestionCount = totalQuestionCount + 1;
          LoggerService.log('this.totalQuestionCount ', this.totalQuestionCount);
          LoggerService.log('All questions count ', totalQuestionCount);

          this.handleHiddenBookingQuestions();
          this.surveyService.surveySteps = this.helperService.sortBy(result, 'number');
        } else {
          // TODO handle failed case
          this.showSkeleton = false;
        }

      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  ngAfterViewInit(): void {
    this.matStepper._getIndicatorType = (): string => STEP_STATE.NUMBER;
    // LoggerService.log('this.stepper ', this.stepper);
  }

  sendProgressBarCount(isLast: boolean): void {
    if (this.widgetService.clientOriginEvent) {
      this.widgetService.sendMessageToMainJs(this.widgetService.clientOriginEvent, {
        eventName: WINDOW_LISTENER_CONSTANTS.PROGRESSBAR_COUNT_EVENT,
        progressBarCount: {
          numberOfQuestions: this.totalQuestionCount,
          numberOfQuestionsAnswered: this.totalQuestionsAnsweredCount,
          isLast,
        }
      });
    }
  }

  getFilteredCustomers(): void {
    LoggerService.log('uuid ', this.widgetService.customerConfData.uuid);
    LoggerService.log('email ', this.widgetService.customerConfData.email);
    LoggerService.log('token ', this.widgetService.widgetConf.partner?.internal_token);
    this.surveyService
      .getFilteredCustomers(
        this.widgetService.customerConfData.uuid,
        this.widgetService.customerConfData.email,
        this.widgetService.widgetConf.partner?.internal_token
      ).subscribe({
        next: (result: CustomerDbModel[]) => {
          LoggerService.log('result is ', result);
          if (result?.length) {
            this.surveyService.customerDetail = result[0];
            this.showStepperContent = true;
            this.getCustomersSmartWidgetConfigurations(this.surveyService.customerDetail.id as number);
            this.handleHiddenBookingQuestions();
            this.goForward(this.matStepper, this.surveyService?.surveySteps[1]);
          } else {
            if (this.widgetService.customerConfData.uuid) {
              this.showCustomForm = true;
              return;
            }
            if (!this.widgetService.customerConfData.email
              || !this.widgetService.customerConfData.prename
              || !this.widgetService.customerConfData.lastname
            ) {
              this.showCustomForm = true;
              return;
            }
            if (this.widgetService.customerConfData.email
              && this.widgetService.customerConfData.prename
              && this.widgetService.customerConfData.lastname
            ) {
              this.createCustomer();
            } else {
              // TODO display some error message
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          LoggerService.error(error);
        }
      });
  }

  createCustomer(gotoNextStep?: boolean): void {
    if (this.widgetService.widgetConf.context.customer_type_id) {
      this.widgetService.customerConfData.customer_type_id
        = this.widgetService.widgetConf.context.customer_type_id;
    }
    const customerConfData: CustomerDbModel = new CustomerDbModel();
    customerConfData.prename = this.widgetService.customerConfData.prename;
    customerConfData.lastname = this.widgetService.customerConfData.lastname;
    customerConfData.email = this.widgetService.customerConfData.email;
    customerConfData.customer_type_id = this.widgetService.customerConfData.customer_type_id;

    if (this.widgetService.widgetConf.context?.ask_customer_city === 1) {
      customerConfData.city = this.widgetService.customerConfData.city;
    }

    if (this.widgetService.widgetConf.context?.ask_customer_zip === 1) {
      customerConfData.zip = this.widgetService.customerConfData.zip;
    }

    this.surveyService.createCustomer(customerConfData).subscribe({
      next: (result: { customer: CustomerDbModel }) => {
        LoggerService.log('result is ', result);
        if (result?.customer) {
          this.surveyService.customerDetail = result?.customer;
          this.showStepperContent = true;
          this.showCustomForm = false;
          /*if (gotoNextStep) {
            setTimeout(() => {
              this.matStepper.next();
            }, 500);
          }*/
          this.showErrorMessage = false;
          this.errorMessage = undefined;
          this.createCustomersSmartWidgetConfigurations(gotoNextStep);
          this.handleHiddenBookingQuestions();
          // this.getCustomersSmartWidgetConfigurations(this.surveyService.customerDetail.id);
        } else {
          this.errorMessage = 'survey_component.customer_addition_failed';
          this.showErrorMessage = true;
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
        this.errorMessage = 'survey_component.customer_addition_failed';
        this.showErrorMessage = true;
      }
    });
  }

  onDone(): void {
    this.surveyService.calculateSurveyProgressBarCount(true);
  }

  onEndProcessEvent(): void {
    this.onDone();
  }

  onBookAppointmentEvent(): void {
    this.showFinalStep = false;
    this.showBookingWidget = true;
  }

  onStepChanged(event: StepperSelectionEvent): void {
    LoggerService.log('Inside on step changed ', event);
    if (this.surveyService.surveySteps?.length > 0) {
      LoggerService.log('Inside on step changed 1 ', event);
      if (this.surveyService.surveyCustomerSWconfigurationData) {
        LoggerService.log('Inside on step changed 2 ', event);
        if (event.selectedIndex === (this.surveyService.surveySteps?.length - 1)) {
          LoggerService.log('Last Step Inside on step changed 3 ', event);
          this.updateCustomersSmartWidgetConfigurations(true, this.surveyService.surveySteps[event.selectedIndex].id);
        } else {
          LoggerService.log('Other steps Inside on step changed 4 ', event);
          this.updateCustomersSmartWidgetConfigurations(false, this.surveyService.surveySteps[event.selectedIndex].id);
        }

        this.fireGoogleEvent(event.selectedIndex);

      } else {
        LoggerService.log('Inside on step changed 5 ', event);
        // this.createCustomersSmartWidgetConfigurations();
      }
    } else {
      LoggerService.log('Empty this.surveyService.surveySteps ', this.surveyService.surveySteps);
    }
  }

  goBack(stepper: MatStepper): void {
    stepper.previous();
  }

  goForward(stepper: MatStepper, surveyStep: SurveyStepModel): void {
    const endOfProcessSurvey = this.widgetService.endOfProcessSurvey;
    const endOfProcessSurveyStatus = (endOfProcessSurvey?.status ? endOfProcessSurvey.status : false);
    const endOfProcessSurveyStep = (endOfProcessSurvey?.step ? endOfProcessSurvey.step : 0);

    if (surveyStep?.is_start === 1 && this.showCustomForm) {
      this.createCustomer(true);
    } else {
      (!(endOfProcessSurveyStatus && endOfProcessSurveyStep === surveyStep.step_number)) && stepper.next();
    }
  }

  handleNextButtonState(surveyStep: SurveyStepModel, formStatus: boolean): boolean {
    if (surveyStep.step_number === 1) {
      return formStatus;
    } else {
      const endOfProcessSurvey = this.widgetService.endOfProcessSurvey;
      const endOfProcessSurveyStatus = (endOfProcessSurvey?.status ? endOfProcessSurvey.status : false);
      const endOfProcessSurveyStep = (endOfProcessSurvey?.step ? endOfProcessSurvey.step : 0);
      return (endOfProcessSurveyStatus === true && endOfProcessSurveyStep === surveyStep.step_number) && formStatus === false;
    }
  }

  goBackward(stepper: MatStepper): void {
    stepper.previous();
  }

  getCustomersSmartWidgetConfigurations(customerId: number): void {
    if (!customerId) {
      LoggerService.warn('Customer Id is not defined');
      return;
    }
    this.surveyService
      .getCustomersSmartWidgetConfigurations(
        customerId,
        this.widgetService.widgetConf.id
      ).subscribe({
        next: result => {
          LoggerService.log('result is ', result);
          if (result?.length > 0) {
            this.surveyService.surveyCustomerSWconfigurationData = result[0];
          } else {
            this.createCustomersSmartWidgetConfigurations(false);
          }
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
  }

  createCustomersSmartWidgetConfigurations(gotoNextStep: boolean): void {
    const body: SurveyCustomerSmartWidgetConfigurationModel = new SurveyCustomerSmartWidgetConfigurationModel();
    body.start = this.dateUtilService.getFormattedDateByFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    body.end = null;
    body.customer_id = this.surveyService.customerDetail.id as number;
    body.smartwidget_configuration_id = this.widgetService.widgetConf.id;
    body.partner_id = this.widgetService.widgetConf.partner.id;
    body.widget_step_id = this.surveyService?.surveySteps?.length > 0 ? this.surveyService?.surveySteps[0].id : null;

    this.surveyService.createCustomersSmartWidgetConfigurations(body).subscribe({
      next: result => {
        this.surveyService.surveyCustomerSWconfigurationData = result;
        if (gotoNextStep) {
          this.matStepper.next();
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  updateCustomersSmartWidgetConfigurations(isLastStep: boolean, stepId: number): void {
    LoggerService.log('Inside updateCustomersSmartWidgetConfigurations');
    const body: SurveyCustomerSmartWidgetConfigurationModel = new SurveyCustomerSmartWidgetConfigurationModel();
    if (isLastStep) {
      body.end = this.dateUtilService.getFormattedDateByFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    }
    body.customer_id = this.surveyService.customerDetail.id as number;
    body.smartwidget_configuration_id = this.widgetService.widgetConf.id;
    body.partner_id = this.widgetService.widgetConf.partner.id;
    body.widget_step_id = stepId;

    this.surveyService.updateCustomersSmartWidgetConfigurations(body, this.surveyService.surveyCustomerSWconfigurationData.id).subscribe({
      next: result => this.surveyService.surveyCustomerSWconfigurationData = result,
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  handleHiddenBookingQuestions(): void {
    LoggerService.warn('Inside handleHiddenBookingQuestions() this.hiddenBookingQuestions', this.hiddenBookingQuestions);
    this.surveyService.handleHiddenBookingQuestions(
      this.hiddenBookingQuestions,
      this.surveyService.customerDetail
    );
  }

  fireGoogleEvent(index: number): void {
    if (this.surveyService.surveySteps?.length > 0) {
      if (index === 0) {
        this.googleAnalyticsService.emitSurveyEvent({
          eventStep: 1,
          eventName: GTM_EVENTS.survey_start_step,
          stepName: this.surveyService.surveySteps[0].title
        });
      } else if (index === (this.surveyService.surveySteps?.length - 1)) {
        this.googleAnalyticsService.emitSurveyEvent({
          eventStep: index + 1,
          eventName: GTM_EVENTS.survey_last_step,
          stepName: this.surveyService.surveySteps[this.surveyService.surveySteps?.length - 1].title
        });
      } else {
        this.googleAnalyticsService.emitSurveyEvent({
          eventStep: index + 1,
          eventName: GTM_EVENTS.other_survey_steps + (index + 1),
          stepName: this.surveyService.surveySteps[index].title
        });
      }
    }
  }
}
