import { WorkerDbModel } from "./worker-db.model";

export class StoreDbModel {
  id: number;
  uuid: string;
  logo: string;
  name: string;
  email: string;
  phone: string;
  street: string;
  zip: string;
  city: string;
  lng: number;
  lat: number;
  is_online: number;
  place: string;
  is_multi_language: number;
  has_only_internal_appointment_service?: number;
  distance?: string;
  position: number;
  country: StoreCountry;
  country_id: number;
  only_accessible_through_deeplink: number;
  default_worker_id: number;
  default_worker: WorkerDbModel;
  _translations: {
    [key: string]: StoreDbModelTranslation;
  };
}

export class StoreDbModelTranslation {
  name: string;
  locale: string;
}

export class StoreTranslationForm {
  is_multi_language: boolean;
  id: number;
  street: string;
  zip: number;
  city: string;
  lng: number;
  lat: number;
  is_online: number;
  place: string;
  email: string;
  phone: string;
  country_id: number;
}

export class StoreMultiTranslationForm {
  name: string;
}

export class StoreCountry {
  id: number;
  identifier: string;
  is_multi_language: number;
  name: string
  supported: number;
  _translations?: {
    [key: string]: StoreTranslation
  };
}

export class StoreTranslation {
  name: string;
  locale: string;
}
