<div class="row cbsw-slots-selection-step">

  <!--  bookingService.partnerData.disable_automatic_search ==> {{ bookingService.partnerData.disable_automatic_search }}-->

  <div class="col-md-12 my-2">
    <app-csw-text
      [text]="
        ((bookingService.widgetTemplates | calioMeetingTemplate: {
            id: 132, type: 'template'
          }) | translation: 'template_content': lang
        )"
      [styleConfig]="{ fontWeight: 600 }"
      [customClass]="'step-element-title'"
    />
  </div>

  <div class="col-md-6">
    <app-calio-calendar-picker
      *ngIf="loadCalendarPicker"
      [selectedBookingCartItems]="bookingService.selectedBookingData.cartItems"
      [displayBadgeInBookingFeature]="bookingService.partnerData.is_cart_supported === 1"
      [selectedDate]="selectedDate"
      [disableAutomaticSearch]="bookingService.partnerData.disable_automatic_search"
      [futureBookingThreshold]="bookingService.partnerData.future_booking_threshold_appointments"
      [availableDays]="availableDays"
      [disableAllDays]="true"
      [isMultiselect]="false"
      (selectDaysEvent)="onSelectedDays($event)"
      (monthChangedEvent)="onMonthChangedEvent($event)"
    />
  </div>

  <div class="col-md-6">
    <div class="mt-4 mt-md-0" *ngIf="showSlotGhostElement" #slotsCardSection>
      <div class="skeleton-box-card">
        <div class="">
          <div class="row">
            <div
              class="col-md-3 mb-4"
              *ngFor="let ghostElement of [0, 1, 2, 3]"
            >
              <div class="d-flex stats-card shadow p-2">
                <div class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
                  <div class="">
                    <div class="stat-box"></div>
                    <div class="stat-box mt-1"></div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-xs-12 col-sm-6 mb-4"
              *ngFor="let ghostElement of slotGhostElements"
            >
              <div class="d-flex stats-card shadow">
                <div class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
                  <div class="">
                    <div class="stat-box-1"></div>
                    <div class="stat-box mt-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 mt-md-0">
      <ng-container *ngIf="noAppointments">
        <div class="alert alert-warning">
          {{
            ((bookingService.widgetTemplates | calioMeetingTemplate: {
              id: 241, type: 'template'
            }) | translation: 'template_content': lang
          )}}
        </div>
      </ng-container>
      <ng-container *ngIf="freeAppointments?.length > 0">
        <app-slots-card-selection
          [freeAppointments]="freeAppointments"
          [noAppointments]="noAppointments"
          [freeAppointmentsCount]="freeAppointmentsCount"
          (gotoNextPageEvent)="next()"
        />
      </ng-container>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end mt-4 mb-4 cbw-custom-navigation">
  <div class="me-2">
    <app-button
      [id]="'slot-selection-previous-btn'"
      (clickEvent)="previous()"
      [fontColor]="widgetService?.widgetConf?.button_font_color"
      [backgroundColor]="widgetService?.widgetConf?.button_background_color"
    >
    <span class="custom-button-icon back"></span>
      {{
        ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 129, type: 'template'
        }) | translation: 'template_content': lang
      )}}
    </app-button>
  </div>

  <div>
    <app-button
      [id]="'slot-selection-next-btn'"
      (clickEvent)="next()"
      [isDisabled]="!(bookingService?.selectedBookingData?.cartItems?.length > 0)"
      [fontColor]="widgetService?.widgetConf?.button_font_color"
      [backgroundColor]="widgetService?.widgetConf?.button_background_color"
    >
      {{
        ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 128, type: 'template'
        }) | translation: 'template_content': lang
      )}}
      <span class="custom-button-icon next"></span>
    </app-button>
  </div>
</div>
