import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CalioCalendarPickerComponent} from './calio-calendar-picker/calio-calendar-picker.component';
import {CalendarModule, CalendarUtils, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {TranslateModule} from '@ngx-translate/core';
import {LoaderModule} from '../loader/loader.module';


@NgModule({
  declarations: [CalioCalendarPickerComponent],
  exports: [CalioCalendarPickerComponent],
  imports: [
    CommonModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    TranslateModule,
    LoaderModule
  ],
  providers: [
    CalendarUtils
  ]
})
export class CalioCalendarModule {
}
