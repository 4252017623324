import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import { SwiperModule } from 'swiper/angular';
import { SharedModule } from '../../shared/shared.module';
import { AlertModule } from '../../ui-lib/alert/alert.module';
import { BookingQuestionsModule } from '../../ui-lib/booking-questions/booking-questions.module';
import { ButtonsModule } from '../../ui-lib/buttons/buttons.module';
import { CalioCalendarModule } from '../../ui-lib/calio-calendar/calio-calendar.module';
import { CouponFormModule } from '../../ui-lib/coupon-form/coupon-form.module';
import { CwPaypalModule } from '../../ui-lib/cw-paypal/cw-paypal.module';
import { GtmOperationModule } from '../../ui-lib/gtm-operation/gtm-operation.module';
import { SliderModule } from '../../ui-lib/slider/slider.module';
import { StripeModule } from '../../ui-lib/stripe/stripe.module';
import { BookingComponent } from './booking.component';
import { BookingFailedViewComponent } from './components/booking-failed-view/booking-failed-view.component';
import { BookingQuestionViewComponent } from './components/booking-question-view/booking-question-view.component';
import { BookingQuestionsViewComponent } from './components/booking-questions-view/booking-questions-view.component';
import { BookingSuccessViewComponent } from './components/booking-success-view/booking-success-view.component';
import { BookingSummaryViewComponent } from './components/booking-summary-view/booking-summary-view.component';
import { MeetingTypeSelectionComponent } from './components/meeting-type-selection/meeting-type-selection.component';
import { PaymentMethodViewComponent } from './components/payment-method-view/payment-method-view.component';
import { PersonalFormViewComponent } from './components/personal-form-view/personal-form-view.component';
import { ServicesSelectionComponent } from './components/services-selection/services-selection.component';
import { SlotsCardSelectionComponent } from './components/slots-card-selection/slots-card-selection.component';
import { SlotsSelectionComponent } from './components/slots-selection/slots-selection.component';
import { StoreSelectionComponent } from './components/store-selection/store-selection.component';
import { WorkersSelectionComponent } from './components/workers-selection/workers-selection.component';

@NgModule({
  declarations: [
    BookingComponent,
    StoreSelectionComponent,
    ServicesSelectionComponent,
    WorkersSelectionComponent,
    SlotsSelectionComponent,
    SlotsCardSelectionComponent,
    PersonalFormViewComponent,
    BookingQuestionsViewComponent,
    BookingSummaryViewComponent,
    PaymentMethodViewComponent,
    BookingSuccessViewComponent,
    BookingFailedViewComponent,
    BookingQuestionViewComponent,
    MeetingTypeSelectionComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    NgSelectModule,
    ButtonsModule,
    AlertModule,
    SliderModule,
    SwiperModule,
    CalioCalendarModule,
    MatCheckboxModule,
    BookingQuestionsModule,
    MatRadioModule,
    MatTabsModule,
    StripeModule,
    CwPaypalModule,
    CouponFormModule,
    GtmOperationModule,
    MatIconModule
  ],
  exports: [BookingComponent]
})
export class BookingModule {
}
