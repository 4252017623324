<app-booking-question
  [prefix]="prefix"
  [surveyGlobalStep]="surveyGlobalStep"
  [customField]="widgetQuestion"
  [titleStyleConfig]="{ fontSize: '20px', fontWeight: '600' }"
  [labelStyleConfig]="{ fontSize: '16px', fontWeight: '500' }"
  [textContainerStyleConfig]="{ marginBottom: '26px' }"
  [customerDataIsNotMandatoryToUploadFiles]="false"
  [deactivateField]="!isFirst && (!currentSurveyStep?.booking_questions[currentIndex-1]?.customerSelectedValue && currentSurveyStep?.booking_questions[currentIndex-1]?.required === 1 && currentSurveyStep?.booking_questions[currentIndex-1]?.is_read_only === 0)"
  (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
  (changeCustomFieldSelectedValueEvent)="onChangeCustomFieldSelectedValueEvent(true, widgetQuestion, $event)"
  (blurCustomFieldSelectedValueEvent)="onBlurCustomFieldSelectedValueEvent($event)"
>
</app-booking-question>

<!-- dependent question of the questions -->
<ng-container *ngIf="widgetQuestion?.customFieldDependencies?.length > 0">
  <ng-container *ngFor="let dependencyCustomField of widgetQuestion?.customFieldDependencies; let dependentQIndex = index;">
    <app-booking-question
      [prefix]="prefix + '.' + (dependentQIndex + 1)"
      [surveyGlobalStep]="surveyGlobalStep"
      [customField]="dependencyCustomField"
      [parentField]="widgetQuestion"
      [titleStyleConfig]="{ fontSize: '20px', fontWeight: '500' }"
      [labelStyleConfig]="{ fontSize: '16px', fontWeight: '500' }"
      [textContainerStyleConfig]="{ marginBottom: '26px' }"
      [customerDataIsNotMandatoryToUploadFiles]="false"
      (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
      (changeCustomFieldSelectedValueEvent)="onChangeCustomFieldSelectedValueEvent(false, dependencyCustomField, $event)"
      (blurCustomFieldSelectedValueEvent)="onBlurCustomFieldSelectedValueEvent($event)"
    >
      <!-- dependent question of the selected options -->
      <ng-container *ngIf="dependencyCustomField?.loadOptionDependencies === true">
        <div class="row">
          <ng-container *ngFor="let dependendentOptionCustomFields of dependencyCustomField?.optionDependentQuestions; let dependentOQIndex = index;">
            <div
              [class]="'border-end no-right-border-last-child border-sm-right-none col-md-' + (12 / (widgetQuestion?.generate_entity_count ? widgetQuestion.generate_entity_count : 1))"
            >
            <ng-container *ngFor="let dependendentOptionCustomField of dependendentOptionCustomFields;">
                <app-booking-question
                  [customEventIndex]="dependentOQIndex"
                  [deactivateField]="(dependencyCustomField?.optionDependentQuestionIds[dependentOQIndex] && dependencyCustomField?.optionDependentQuestionIds[dependentOQIndex].includes(dependendentOptionCustomField.id)) ? false : true"
                  [prefix]=""
                  [surveyGlobalStep]="surveyGlobalStep"
                  [customField]="dependendentOptionCustomField"
                  [parentField]="dependencyCustomField"
                  [grandParentField]="widgetQuestion"
                  [titleStyleConfig]="{ fontSize: '20px', fontWeight: '500' }"
                  [labelStyleConfig]="{ fontSize: '16px', fontWeight: '500' }"
                  [textContainerStyleConfig]="{ marginBottom: '26px' }"
                  [customerDataIsNotMandatoryToUploadFiles]="false"
                  (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
                  (changeCustomFieldSelectedValueEvent)="onChangeCustomFieldSelectedValueEvent(false, dependendentOptionCustomField, $event)"
                  (blurCustomFieldSelectedValueEvent)="onBlurCustomFieldSelectedValueEvent($event)"
                >
                </app-booking-question>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- dependent question of the selected options -->
    </app-booking-question>
  </ng-container>
</ng-container>

<ng-container *ngIf="showDependenciesGhostElement">
  <div class="skeleton-box-card mt-4">
    <div class="row">
      <div
        class="col-md-12 mb-4"
        *ngFor="let dependenciesGhostElement of dependenciesGhostElements"
      >
        <div class="d-flex stats-card shadow">
          <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
            <div class="stat-box-1"></div>
            <div class="stat-box mt-1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
