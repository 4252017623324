import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CalioTelInputComponent} from './calio-tel-input/calio-tel-input.component';

@NgModule({
  declarations: [CalioTelInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [CalioTelInputComponent]
})
export class CalioIntlTelInputModule {
}
