import { AppointmentServiceModel } from './appointment-service.model';
import { CaptchaDbModel } from './captcha.model';
import { CouponDbModel } from './coupon-db.model';
import { MeetingTypesModel } from './meeting-types.model';
import { PayPalOrderDetailModel } from './partner-setting.model';
import { StoreDbModel } from './store-db.model';
import { UserInfo } from './user.model';
import { WorkerDbModel } from './worker-db.model';

export class SelectedBookingDataModel {

  selectedMeetingTypes: MeetingTypesModel[] = [];
  selectedServices: AppointmentServiceModel[] = [];
  currentSelectedAppointmentState = new CurrentSelectedAppointmentState();
  cartItems: AppointmentCartItem[] = [];
  selectedUser: UserInfo;
  selectedCustomFieldValues: {
    custom_field_id: number,
    type: string,
    value?: any, file_ids?: any,
    supported_appointment_service_ids?: number[]
  }[] = [];
  totalBookingAmount: number;
  subTotal: number;
  totalDiscount: number;
  paymentMethodData: PaymentMethodData = new PaymentMethodData();
  selectedCoupon: CouponDbModel;
  captcha: CaptchaDbModel;
}

export class CurrentSelectedAppointmentState {
  selectedStoreLeadGeneratorPostcode: string;
  selectedMeetingTypeId: number;
  selectedStoreId: number;
  selectedStore: StoreDbModel;
  selectedStoreUuids: string[] = [];
  selectedServiceIds: number[] = [];
  selectedWorkerId: number;
  selectedWorker: WorkerDbModel;
  selectedDate: Date;
  storeAndWorkerPreSelectedThroughWorkerSelectField = false
  workers_view: 'card' | 'dropdown';
}

export class AppointmentCartItem {
  cartItemId: string;
  storeId: number;
  store: StoreDbModel;
  date: Date;
  startTime: string;
  endTime: string;
  shortStart: string;
  startHour: number;
  workerId: number;
  worker: WorkerDbModel;
  serviceId: number;
  service: AppointmentServiceModel;
  selectedMeetingType: MeetingTypesModel = null;
}

export class PaymentMethodData {
  paymentType: string;
  stripePaymentMethodId: string;
  paypalOrderDetail: PayPalOrderDetailModel;
}
