import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GtmOperationComponent} from './gtm-operation.component';

@NgModule({
  declarations: [
    GtmOperationComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    GtmOperationComponent
  ]
})
export class GtmOperationModule {
}
