<div
  class="card {{ customCardClass }}"
  [ngStyle]="cardStylesConfig"
  [ngClass]="{
        'shadow': showShadow,
        'disabled-card-box': isDisabled,
        'cursor-pointer': showCursorPointer,
        'selected-card': isSelected
     }"
  (click)="onCardClick()"
>
  <div *ngIf="showHeader" class="card-header">
    <ng-content select="[cardHeader]"></ng-content>
  </div>

  <div *ngIf="showImage" class="cardImage">
    <ng-content select="[cardImage]"></ng-content>
  </div>

  <div *ngIf="showBody" class="card-body">
    <ng-content select="[cardBody]"></ng-content>
  </div>

  <div *ngIf="showFooter" class="card-footer">
    <ng-content select="[cardFooter]"></ng-content>
  </div>
</div>
