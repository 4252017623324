import {PartnerDbModel} from './partner-db.model';

export class WidgetConfModel {
  id: number;
  widget_uuid: string;
  locale: string;
  position: string;
  header_background_color: string; // this color we are going to use for background color of selected card
  header_font_color: string;
  button_background_color: string;
  button_font_color: string;
  title: string;
  description: string;
  logo_url: string;
  launcher_icon: number;
  type: number;
  trigger: number;
  whitelisted_domains: string[];
  partner_id: number;
  right_padding: number;
  left_padding: number;
  bottom_padding: number;
  context: WidgetContext;
  trigger_message: string;
  lead_intro_text: string;
  lead_outro_text: string;
  partner: PartnerDbModel;
  supported_widget_languages: string[] = [];
  is_multi_language: number;
  start_text: string;
  end_text: string;
  end_of_process_message: string;
  _translations: {
    [key: string]: WidgetConfTranslationsModel;
  };
}

class WidgetConfTranslationsModel {
  end_of_process_message?: string;
  end_text?: string;
  locale: string;
  start_text?: string;
  title?: string;
}

export class WidgetContext {
  locale: string;
  worker_email: string;
  worker_id: number;
  worker_ids: number[] = [];
  worker_uuid: string;
  appointment_service_ids: number[] = [];
  store_id: number;
  store_uuids: string[] = [];
  date: string;
  is_internal: boolean;
  booking_questions: number[] = [];
  meeting_providers: number[] = [];
  customer: string[] = [];
  customer_required: string[] = [];
  customer_type_id: number;
  show_questions_before_booking_process: boolean;
  steps: number[] = [];
  ask_for_customer_notification_preference: boolean;
  show_meeting_type_picker: boolean;
  show_appointment_services_list: boolean;
  show_worker_avatar: boolean;
  auto_skip_steps: 1 | 0 = 1;
  ask_customer_city: 0 | 1 = 0;
  ask_customer_zip: 0 | 1 = 0;
  workers_view: 'card' | 'dropdown';
}
