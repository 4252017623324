<ng-container *ngIf="widgetService.partnerUUID">
  <link rel="stylesheet" type="text/css"
        [href]="('/assets/css/lead/' + widgetService.widgetConf.partner.booking_name + '_' + widgetService.widgetUUID + '.css') | calioSafe: 'resourceUrl'">
</ng-container>

<div class="lead-generator">
  <ng-container *ngIf="showQuestionsScreen">
    <app-booking-questions
      (submitEvent)="onSubmitEvent($event)"
    >
    </app-booking-questions>
  </ng-container>

  <ng-container *ngIf="showAlertScreen && alertType && alertMessage">
    <app-alert
      [isAlertMessageTranslated]="isAlertMessageTranslated"
      [type]="alertType"
      [message]="alertMessage"
    >
    </app-alert>
  </ng-container>

</div>



