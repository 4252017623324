<form #workerForm="ngForm" *ngIf="workers?.length > 0">
  <!-- Show this section if worker length is greater than 10 -->
  <div class="row" *ngIf="workers?.length > 10">
    <div class="col-md-12">
      <label>
        <app-csw-text
          [text]="widgetResourceHeadingTemplate | translation: 'template_content': lang"
          [styleConfig]="{ fontWeight: 600 }"
        >
        </app-csw-text>
      </label>
      <ng-select
        id="store"
        name="store"
        placeholder=""
        [multiple]="false"
        [(ngModel)]="selectedWorkerId"
        (change)="onWorkerChange($event)"
        [required]="true"
        #workerControl="ngModel"
        [ngClass]="{ 'is-invalid': workerControl.touched && workerControl.invalid }"
      >
        <ng-option [value]="0">
          <div class="d-flex align-items-center">
            <div class="avatar">
              <img
                class="default-avatar rounded-circle"
                src="{{ baseUrl }}/img/sample-avatar.png"
              />
            </div>
            <div class="description">
              <div class="cbw-title">
                Anyone
              </div>
            </div>
          </div>
        </ng-option>
        <ng-container *ngFor="let worker of workers">
          <ng-container *ngIf="worker.id != null">
            <ng-option [value]="worker.id">
              <!--{{ worker.resource_name }}-->
              <div class="d-flex align-items-center">
                <div class="avatar">
                  <img
                    #image
                    *ngIf="worker.avatar !== null"
                    class="resource-avatar rounded-circle"
                    src="{{ workerAvatarBaseUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
                    (error)="image.src= baseUrl + '/img/sample-avatar.png'"
                  />
                  <img
                    *ngIf="worker.avatar === null"
                    class="default-avatar rounded-circle"
                    src="{{ baseUrl }}/img/sample-avatar.png"
                  />
                </div>

                <div class="description">
                  <div class="cbw-title">
                    <ng-container *ngIf="worker.is_resource === 0">
                      {{ worker.resource_name }}
                    </ng-container>
                    <ng-container *ngIf="worker.is_resource === 1">
                      <ng-container *ngIf="worker.is_multi_language === 1">
                        {{worker | translation: 'booking_label': lang }}
                      </ng-container>
                      <ng-container *ngIf="worker.is_multi_language === 0">
                        {{ worker.resource_name }}
                      </ng-container>
                    </ng-container>
                  </div>
                  <div *ngIf="worker.is_resource === 0" class="cbw-function">
                    <ng-container *ngIf="worker.is_multi_language === 1">
                      {{worker | translation: 'job_title': lang }}
                    </ng-container>
                    <ng-container *ngIf="worker.is_multi_language === 0">
                      {{ worker.job_title }}
                    </ng-container>

                  </div>
                  <div *ngIf="worker.is_resource !== 0" class="cbw-function">
                    <ng-container *ngIf="worker.is_multi_language === 1">
                      {{worker | translation: 'description': lang }}
                    </ng-container>
                    <ng-container *ngIf="worker.is_multi_language === 0">
                      {{ worker.description }}
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-option>
          </ng-container>
        </ng-container>
      </ng-select>

      <div class="text-danger"
           *ngIf="workerControl.touched && !workerControl.valid">
        <div *ngIf="workerControl.errors.required">
          This field is required.
        </div>
      </div>
    </div>
  </div>


  <!-- Show this section if worker length is less than 10 -->
  <ng-container *ngIf="workers?.length > 0 && workers?.length <= 10">
    <div class="">
      <app-csw-text
        [text]="widgetResourceHeadingTemplate | translation: 'template_content': lang"
        [styleConfig]="{ fontWeight: 600 }"
      >
      </app-csw-text>
    </div>

    <div class="row">
      <div *ngIf="workers?.length > 1" class="worker-slot-card col-md-6 col-lg-4 mt-3"
           (click)="selectedWorkerId = 0; onWorkerChange($event)">
        <app-info-card
          [showCardBorder]="true"
          [showShadow]="false"
          [showBody]="true"
          [isSelected]="selectedWorkerId === 0"
          [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
        >
          <div cardBody>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="avatar">
                  <img
                    class="default-avatar rounded-circle"
                    src="{{ baseUrl }}/img/sample-avatar.png"
                  />
                </div>
                <div class="description">
                  <div class="cbw-title">
                    {{ widgetAnyoneLabelTemplate | translation: 'template_content': lang }}
                  </div>
                  <div class="cbw-function">
                    {{ widgetAnyoneDescTemplate | translation: 'template_content': lang }}
                  </div>
                </div>
              </div>

              <div class="next-arrow-container">
                <span
                  *ngIf="selectedWorkerId === 0"
                  (click)="next()">
                  <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                </span>
              </div>
            </div>
          </div>
        </app-info-card>
      </div>

      <ng-container *ngFor="let worker of workers">
        <ng-container *ngIf="worker.id != null">
          <div class="worker-slot-card mt-3 col-md-6 col-lg-4"
               (click)="selectedWorkerId = worker.id; onWorkerChange($event)">
            <app-info-card
              [showCardBorder]="true"
              [showShadow]="false"
              [showBody]="true"
              [isSelected]="selectedWorkerId === worker.id"
              [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
            >
              <div cardBody>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="avatar">
                      <img
                        #image
                        *ngIf="worker.avatar !== null"
                        class="resource-avatar rounded-circle"
                        src="{{ workerAvatarBaseUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
                        (error)="image.src= baseUrl + '/img/sample-avatar.png'"
                      />
                      <img
                        *ngIf="worker.avatar === null"
                        class="default-avatar rounded-circle"
                        src="{{ baseUrl }}/img/sample-avatar.png"
                      />
                    </div>

                    <div class="description">
                      <div class="cbw-title">
                        <ng-container *ngIf="worker.is_resource === 0">
                          {{ worker.resource_name }}
                        </ng-container>
                        <ng-container *ngIf="worker.is_resource === 1">
                          <ng-container *ngIf="worker.is_multi_language === 1">
                            {{worker | translation: 'booking_label': lang }}
                          </ng-container>
                          <ng-container *ngIf="worker.is_multi_language === 0">
                            {{ worker.resource_name }}
                          </ng-container>
                        </ng-container>
                      </div>
                      <div *ngIf="worker.is_resource === 0" class="cbw-function">
                        <ng-container *ngIf="worker.is_multi_language === 1">
                          {{worker | translation: 'job_title': lang }}
                        </ng-container>
                        <ng-container *ngIf="worker.is_multi_language === 0">
                          {{ worker.job_title }}
                        </ng-container>

                      </div>
                      <div *ngIf="worker.is_resource !== 0" class="cbw-function">
                        <ng-container *ngIf="worker.is_multi_language === 1">
                          {{worker | translation: 'description': lang }}
                        </ng-container>
                        <ng-container *ngIf="worker.is_multi_language === 0">
                          {{ worker.description }}
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="next-arrow-container">
                    <span
                      *ngIf="selectedWorkerId === worker.id"
                      (click)="next()">
                      <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                    </span>
                  </div>
                </div>
              </div>
            </app-info-card>
          </div>
        </ng-container>
      </ng-container>
    </div>


  </ng-container>

  <div class="d-flex justify-content-end mt-4 mb-4 cbw-custom-navigation">
    <div class="me-2">
      <app-button
        [id]="'lead-workers-selection-previous-btn'"
        (clickEvent)="previous()"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
      <span class="custom-button-icon back"></span>
        {{ 'form_fields.previous' | translate }}
      </app-button>
    </div>

    <div>
      <app-button
        [id]="'lead-workers-selection-next-btn'"
        (clickEvent)="next()"
        [isDisabled]="selectedWorkerId === null || selectedWorkerId === undefined"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        {{ 'form_fields.next' | translate }}
        <span class="custom-button-icon next"></span>
      </app-button>
    </div>
  </div>

</form>
