import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CalioAddRequiredPipe } from './calio-add-required.pipe';
import { CalioAlternateStringPipe } from './calio-alternate-string.pipe';
import { CalioCurrencyPipe } from './calio-currency.pipe';
import { CalioDateAgoPipe } from './calio-date-ago.pipe';
import { CalioDateDiffPipe } from './calio-date-diff.pipe';
import { CalioDateUtcPipe } from './calio-date-utc.pipe';
import { CalioMeetingTemplatePipe } from './calio-meeting-template.pipe';
import { CalioSafePipe } from './calio-safe.pipe';
import { CalioSortByPipe } from './calio-sort-by.pipe';
import { CalioTranslationSortPipe } from './calio-translation-sort.pipe';
import { CalioTranslationPipe } from './calio-translation.pipe';
import { HourReplacePipe } from './hour-replace.pipe';
import { IsEmptyStringPipe } from './is-empty-string.pipe';
import { MinuteReplacePipe } from './minute-replace.pipe';
import { ReplaceCommaPipe } from './replace-comma.pipe';
import { TrustHtmlPipe } from './trust-html.pipe';

@NgModule({
  declarations: [
    CalioCurrencyPipe,
    CalioDateDiffPipe,
    CalioSafePipe,
    CalioDateAgoPipe,
    CalioAddRequiredPipe,
    CalioTranslationPipe,
    CalioAlternateStringPipe,
    CalioSortByPipe,
    CalioDateUtcPipe,
    MinuteReplacePipe,
    HourReplacePipe,
    ReplaceCommaPipe,
    IsEmptyStringPipe,
    CalioMeetingTemplatePipe,
    CalioTranslationSortPipe,
    TrustHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CalioCurrencyPipe,
    CalioDateDiffPipe,
    CalioSafePipe,
    CalioDateAgoPipe,
    CalioAddRequiredPipe,
    CalioTranslationPipe,
    CalioAlternateStringPipe,
    CalioSortByPipe,
    CalioDateUtcPipe,
    MinuteReplacePipe,
    HourReplacePipe,
    ReplaceCommaPipe,
    IsEmptyStringPipe,
    CalioMeetingTemplatePipe,
    CalioTranslationSortPipe,
    TrustHtmlPipe
  ],
  providers: [
    CurrencyPipe,
  ]
})
export class CalioPipesModule {
}
