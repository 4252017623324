import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CwPaypalComponent} from './cw-paypal.component';
import {NgxPayPalModule} from 'ngx-paypal';


@NgModule({
  declarations: [
    CwPaypalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxPayPalModule
  ],
  exports: [
    CwPaypalComponent
  ]
})
export class CwPaypalModule {
}
