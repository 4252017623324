import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoggerService} from '../../../../services/utils/logger.service';
import {BookingService} from '../../../../services/feature/booking.service';
import {UserInfo} from '../../../../models/user.model';
import {BOOKING_PAGE_VIEWS, GTM_EVENTS} from '../../../../constants/app.constants';
import {WidgetConfModel} from '../../../../models/widget-conf.model';
import {WidgetService} from '../../../../services/feature/widget.service';
import {CustomFieldModel} from '../../../../models/custom-field.model';
import {GoogleAnalyticsService} from '../../../../services/utils/google-analytics.service';
import {PartnerDbModel} from '../../../../models/partner-db.model';

@Component({
  selector: 'app-personal-form-view',
  templateUrl: './personal-form-view.component.html',
  styleUrls: ['./personal-form-view.component.scss']
})
export class PersonalFormViewComponent implements OnInit {

  @Input() partnerData: PartnerDbModel;
  @Output() nextPageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() previousPageEvent: EventEmitter<any> = new EventEmitter<any>();

  customerFormFields: string[] = [];
  customerRequiredFields: string[] = [];
  meetingTypes: number[] = [];
  userInfo: UserInfo = new UserInfo();
  showMeetingTypes: boolean = Boolean(true);
  isGTMEventSent: boolean;

  constructor(
    public bookingService: BookingService,
    private widgetService: WidgetService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.bookingService.loadAppointmentSelectedUserFromLocalStorage();
    if (this.bookingService.selectedBookingData.selectedUser) {
      this.userInfo = this.bookingService.selectedBookingData.selectedUser;
    }
    if (!this.userInfo) {
      this.userInfo = new UserInfo();
    }
    if (this.widgetService?.widgetConf?.context?.show_meeting_type_picker) {
      // this.userInfo.meeting_type_id = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId;
      this.showMeetingTypes = false;
    } else {
      this.showMeetingTypes = true;
    }

    if (this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreLeadGeneratorPostcode) {
      this.userInfo.zip = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreLeadGeneratorPostcode;
    }
  }

  ngOnInit(): void {
    this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_personal_data_start);
    this.isGTMEventSent = true;
    const config: WidgetConfModel = this.widgetService.widgetConf;
    this.meetingTypes = config?.context?.meeting_providers;
    this.customerFormFields = config?.context?.customer;
    if (config?.context?.customer_required?.length > 0) {
      this.customerRequiredFields = config?.context?.customer_required;
    } else {
      this.customerRequiredFields = [];
    }
  }

  gotoPreviousPage(): void {
    if (this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_personal_data_end);
    }

    if (this.widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
      this.nextPageEvent.emit(BOOKING_PAGE_VIEWS.SLOTS_VIEW);
    } else {
      if (this.widgetService.widgetConf?.context?.booking_questions?.length > 0) {
        this.previousPageEvent.emit(BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW);
      } else {
        this.previousPageEvent.emit(BOOKING_PAGE_VIEWS.SLOTS_VIEW);
      }
    }
  }

  onSubmitEvent(event: {
    userInfo: UserInfo,
    personalFormWidgetQuestions: CustomFieldModel[],
    hiddenCustomFields: CustomFieldModel[]
  }): void {
    if (event.hiddenCustomFields?.length > 0) {
      this.bookingService.hiddenCustomFields =
        this.bookingService.hiddenCustomFields.concat(event.hiddenCustomFields);
    }

    if (event.personalFormWidgetQuestions?.length > 0) {
      const personalFormCustomFieldValues:
        {
          custom_field_id: number,
          type: string,
          value?: any, file_ids?: any
        }[] = this.bookingService.setupFinalCustomFields(
        event.personalFormWidgetQuestions,
        this.bookingService.selectedBookingData.selectedCustomFieldValues
      );
      this.bookingService.selectedBookingData.selectedCustomFieldValues = personalFormCustomFieldValues;
    }

    this.bookingService.selectedBookingData.selectedUser = event.userInfo;

    LoggerService.log('this.bookingService.hiddenCustomFields ', this.bookingService.hiddenCustomFields);

    this.bookingService.selectedBookingData.selectedCustomFieldValues = this.bookingService.handleHiddenCustomFields(
      this.bookingService.selectedBookingData.selectedCustomFieldValues,
      this.bookingService.hiddenCustomFields
    );

    LoggerService.log('Final this.bookingService.selectedBookingData.selectedCustomFieldValues ',
      this.bookingService.selectedBookingData.selectedCustomFieldValues);
    LoggerService.log('event.userInfo ', event.userInfo);

    this.bookingService.setAppointmentSelectedUserInLocalStorage(
      this.bookingService.selectedBookingData.selectedUser
    );

    this.bookingService.setCustomFieldValuesInLocalStorage(
      this.bookingService.selectedBookingData.selectedCustomFieldValues
    );

    this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_personal_data_end);

    this.nextPageEvent.emit(BOOKING_PAGE_VIEWS.BOOKING_SUMMARY_VIEW);
  }

  previous(): void {
    if (this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_personal_data_end);
    }
    this.previousPageEvent.emit(BOOKING_PAGE_VIEWS.SLOTS_VIEW);
  }

}
