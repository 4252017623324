import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BOOKING_PAGE_VIEWS, GTM_EVENTS, WIDGET_TEMPLATES_CONST } from '../../../../constants/app.constants';
import { StoreDbModel } from '../../../../models/store-db.model';
import { WidgetTemplateModel } from '../../../../models/widget-template.model';
import { BookingService } from '../../../../services/feature/booking.service';
import { WidgetService } from '../../../../services/feature/widget.service';
import { GoogleAnalyticsService } from '../../../../services/utils/google-analytics.service';
import { LoggerService } from 'src/app/services/utils/logger.service';

@Component({
  selector: 'app-store-selection',
  templateUrl: './store-selection.component.html',
  styleUrls: ['./store-selection.component.scss'],
})
export class StoreSelectionComponent implements OnInit {

  @Output() gotoPage: EventEmitter<string> = new EventEmitter();
  @Output() previousPageEvent: EventEmitter<any> = new EventEmitter<any>();

  selectedStoreId: number;
  selectedStore: StoreDbModel;
  stores: StoreDbModel[];
  lang: string;
  storeLabelTemplate: WidgetTemplateModel;
  anyStoreLabelTemplate: WidgetTemplateModel;
  slotGhostElements = [1, 2, 3, 4, 5, 6];
  showGhostElement = true;
  showPreviousButton = false;
  searchStores: EventEmitter<string> = new EventEmitter<string>();
  isStoreLoading = false;
  selectedStoreLeadGeneratorPostcode: string;
  isGTMEventSent: boolean;
  supportedAppointmentServiceIds: number[];

  constructor(
    public translate: TranslateService,
    public widgetService: WidgetService,
    public bookingService: BookingService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.searchStores.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((term: string) => {
        this.isStoreLoading = true;
        if (term && term.trim() !== '' && String(term).length >= 4) {
          this.selectedStoreLeadGeneratorPostcode = term;
          this.stores = [];
          return this.bookingService.getStoresByPostCode(term);
        } else {
          return of(this.stores);
        }
      })
    ).subscribe({
      next: stores => {
        this.stores = stores;
        if (this.stores?.length === 1) {
          this.selectedStoreId = this.stores[0].id;
          this.onStoreChange(this.selectedStoreId);
          this.next();
        }

        this.isStoreLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.isStoreLoading = false;
        LoggerService.error(error);
      }
    });

    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    this.isGTMEventSent = false;
    this.lang = this.translate.getDefaultLang();

    this.bookingService.loadAppointmentStateFromLocalStorage();
    this.supportedAppointmentServiceIds = this.bookingService.getSupportedAppointmentServiceIdsFromLocalStorage(
      this.bookingService.partnerData.booking_name
    );

    if (
      this.widgetService?.widgetConf?.partner?.enable_store_postcode_feature === 1 ||
      this.widgetService?.widgetConf?.partner?.automatic_store_zip_search === 1
    ) {
      if (
        this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreLeadGeneratorPostcode
      ) {
        this.selectedStoreLeadGeneratorPostcode = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreLeadGeneratorPostcode;
        this.getStoresByPostCode(this.selectedStoreLeadGeneratorPostcode);
      } else {
        if (this.widgetService.isDefaultStoreIdSelected()) {
          this.fetchAvailableStores();
        } else {
          this.showGhostElement = false;
        }
      }
    } else {
      this.fetchAvailableStores();
    }

    this.setupTemplates();
    this.setupPreviousButton();
  }

  getStoresByPostCode(postcode: string): void {
    this.bookingService.getStoresByPostCode(postcode).subscribe({
      next: stores => {
        this.showGhostElement = false;
        if (stores?.length) {
          this.stores = stores;
          if (this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId) {
            this.selectedStore = this.stores.find(store =>
              (store.id === Number(this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId))
            );
            this.selectedStoreId = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId;
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore = this.selectedStore;
          }
        } else {
          this.stores = [];
        }
      },
      error: (error: HttpErrorResponse) => {
        this.showGhostElement = false;
      }
    });
  }

  setupPreviousButton(): void {
    this.widgetService?.widgetConf?.context?.show_questions_before_booking_process && (this.showPreviousButton = true);
    this.widgetService?.widgetConf?.context?.show_meeting_type_picker && (this.showPreviousButton = true);
  }

  // Fetch available stores
  fetchAvailableStores(): void {
    const appointmentServiceIds = (this.supportedAppointmentServiceIds.length ? this.supportedAppointmentServiceIds : null);
    this.bookingService.getStores(appointmentServiceIds).subscribe({
      next: stores => {
        this.showGhostElement = false;
        if (stores) {
          if (!this.widgetService.widgetConf.context.is_internal) {
            this.stores = stores.filter(store => store.has_only_internal_appointment_service === 0);
          } else {
            this.stores = stores;
          }

          if (this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId) {
            this.selectedStore = this.stores.find(store =>
              (store.id === Number(this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId))
            );
            this.selectedStoreId = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId;
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore = this.selectedStore;

            if (this.widgetService.isDefaultStoreIdSelected()) {
              if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.SERVICES_VIEW) {
                if (this.widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
                  this.previous();
                }
              } else {
                this.onStoreChange(this.selectedStoreId, false);
                this.next(false);
              }
            } else {
              if (this.selectedStoreId && this.selectedStore) {
                if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.SERVICES_VIEW) {
                } else {
                  this.onStoreChange(this.selectedStoreId, false);
                  this.next(false);
                }
              }
            }
          } else {
            if (this.stores?.length === 1) {
              this.selectedStoreId = this.stores[0].id;
              this.selectedStore = this.stores[0];
              this.next(false);
            }
          }
        } else {
          this.showGhostElement = false;
          this.stores = [];
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
      }
    });
  }

  onStoreChange(selectedStoreId: number, isUserInteracted = true): void {
    // trigger start event on first user interaction happen and
    // store selection step is configured to first step
    if (isUserInteracted) {
      this.bookingService.triggerApoointmentStartEvent();

      if (!this.isGTMEventSent) {
        this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_stores_start);
        this.isGTMEventSent = true;
      }
    }

    this.selectedStoreId = selectedStoreId;
    if (this.selectedStoreId !== 0) {
      this.selectedStore = this.stores.find(store => store.id === this.selectedStoreId);

      // When is_widget_postcode_worker_random_selection_enabled setting is enable and default worker from postcode
      // is not null then have to pre-select worker and skip worker selection step
      if (
        Number(this.bookingService.partnerData.is_widget_postcode_worker_random_selection_enabled) === 1 &&
        this.selectedStore.default_worker_id
      ) {
        LoggerService.log(`[Debug] Pre-selecting worker(${this.selectedStore.default_worker_id}) as selected store using postcode search has default worker`);
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId = this.selectedStore.default_worker_id;
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker = this.selectedStore.default_worker;
      }
    } else {
      this.selectedStore = new StoreDbModel();
      this.selectedStore.id = 0;
    }
  }

  previous(): void {
    this.isGTMEventSent = false;
    if (
      this.widgetService?.widgetConf?.context?.show_meeting_type_picker &&
      this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId === 1
    ) {
      this.previousPageEvent.emit(BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW);
    } else {
      this.previousPageEvent.emit(BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW);
    }
  }

  next(isUserInteracted = true): void {
    // trigger start event on first user interaction happen and
    // store selection step is configured to first step
    if (isUserInteracted) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_stores_end);
    }
    if (!this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId) {
      let selectedMeetingTypeId: number;
      if (
        this.widgetService?.widgetConf?.context?.show_meeting_type_picker &&
        this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId === 1
      ) {
        selectedMeetingTypeId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId;
      }

      // Reset current appointment state
      this.bookingService.selectedBookingData.currentSelectedAppointmentState = this.bookingService.resetCurrentSelectedAppointmentStateonStoreSelection();
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId = this.selectedStore.id;
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore = this.selectedStore;
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId = selectedMeetingTypeId;
    }

    if (
      this.widgetService?.widgetConf?.partner?.enable_store_postcode_feature === 1 ||
      this.widgetService?.widgetConf?.partner?.automatic_store_zip_search === 1
    ) {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreLeadGeneratorPostcode = this.selectedStoreLeadGeneratorPostcode;
    }

    this.bookingService.setAppointmentStateInLocalStorage(
      this.bookingService.selectedBookingData.currentSelectedAppointmentState
    );
    this.gotoPage.emit(BOOKING_PAGE_VIEWS.SERVICES_VIEW);
  }

  setupTemplates(): void {
    this.storeLabelTemplate = this.bookingService.widgetTemplates.find(template => (template.identifier === WIDGET_TEMPLATES_CONST.WIDGET_APPOINTMENT_STORE_LABEL));
    this.storeLabelTemplate && (this.storeLabelTemplate.is_multi_language = 1);

    this.anyStoreLabelTemplate = this.bookingService.widgetTemplates.find(template => template.identifier === WIDGET_TEMPLATES_CONST.WIDGET_ANY_STORES);
    this.anyStoreLabelTemplate && (this.anyStoreLabelTemplate.is_multi_language = 1);
  }

  allowOnlyNumbers(event: KeyboardEvent): boolean {
    const numReg = new RegExp('^[0-9]$');
    if (numReg.test(event.key)) {
      return true;
    }
    // Only ASCII charactar in that range allowed
    const ASCIICode = event.which ? event.which : event.keyCode;

    if (ASCIICode === 8) {
      return true;
    }

    if (ASCIICode === 65 && event.metaKey) {
      return true;
    }

    return false;
  }
}
