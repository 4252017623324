<!--<label id="payment-type">{{ templateTitle | translation: 'template_content': lang }}</label>-->
<!--<mat-radio-group
  aria-labelledby="payment-type"
  class="payment-type-radio-group"
  name="paymentType"
  [(ngModel)]="paymentType"
  (change)="onPaymentTypeChange($event)"
>
  <mat-radio-button class="payment-type-radio-button" *ngIf="isStripeEnabled" [value]="'creditcard'">
    {{ 'payment_method.creditcard' | translate }}
  </mat-radio-button>

  <mat-radio-button class="payment-type-radio-button" *ngIf="isBexioEnabled" [value]="'invoice'">
    {{ 'payment_method.invoice' | translate }}
  </mat-radio-button>

  <mat-radio-button class="payment-type-radio-button" *ngIf="isPaypalEnabled" [value]="'paypal'">
    {{ 'payment_method.paypal' | translate }}
  </mat-radio-button>

  <mat-radio-button class="payment-type-radio-button" *ngIf="isStoreEnabled" [value]="'store'">
    {{ 'payment_method.store' | translate }}
  </mat-radio-button>
</mat-radio-group>-->

<ng-container *ngIf="bookingService?.selectedBookingData?.totalBookingAmount > 0">
  <div class="mb-1">
    <app-csw-text
      [text]="((bookingService.widgetTemplates | calioMeetingTemplate: {
                    id: 122, type: 'template'
                    }) | translation: 'template_content': lang
                  )"
      [styleConfig]="{ fontWeight: 600 }"
    >
    </app-csw-text>
  </div>

  <div class="mb-2">
    <div class="d-flex align-items-center mt-1 radio-item" *ngIf="isStripeEnabled">
      <input type="radio" class="payment-type-radio-button"  [value]="'creditcard'"
             id="creditcard"
             (change)="onPaymentTypeChange('creditcard')"
             [(ngModel)]="paymentType" name="paymentType"
             [checked]="paymentType === 'creditcard' ? true: false"
      >
      <label class="radio-button-label mb-0" for="creditcard">{{ 'payment_method.creditcard' | translate }}</label>
    </div>

    <div class="d-flex align-items-center mt-1 radio-item" *ngIf="isBexioEnabled">
      <input type="radio" class="payment-type-radio-button"  [value]="'invoice'" id="invoice"
             (change)="onPaymentTypeChange('invoice')"
             [(ngModel)]="paymentType" name="paymentType"
             [checked]="paymentType === 'invoice' ? true: false"
      >
      <label class="radio-button-label mb-0" for="invoice">{{ 'payment_method.invoice' | translate }}</label>
    </div>

    <div class="d-flex align-items-center mt-1 radio-item" *ngIf="isPaypalEnabled">
      <input type="radio" class="payment-type-radio-button"  [value]="'paypal'"
             id="paypal-radio-btn"
             (change)="onPaymentTypeChange('paypal')" [(ngModel)]="paymentType" name="paymentType"
             [checked]="paymentType === 'paypal' ? true: false"
      >
      <label class="radio-button-label mb-0" for="paypal-radio-btn">{{ 'payment_method.paypal' | translate }}</label>
    </div>

    <div class="d-flex align-items-center mt-1 radio-item" *ngIf="isStoreEnabled">
      <input type="radio" class="payment-type-radio-button"  [value]="'store'" id="store"
             (change)="onPaymentTypeChange('store')" [(ngModel)]="paymentType" name="paymentType"
             [checked]="paymentType === 'store' ? true: false"
      >
      <label class="radio-button-label mb-0" for="store">
        {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 168, type: 'template'
        }) | translation: 'template_content': lang
      ) }}
      </label>
    </div>

    <div class="d-flex align-items-center mt-1 radio-item" *ngIf="isLexOfficePaymentTypeEnabled">
      <input type="radio" class="payment-type-radio-button" [value]="'lex_office_invoice'" id="lexoffice-radio-btn"
        (change)="onPaymentTypeChange('lex_office_invoice')" [(ngModel)]="paymentType" name="paymentType"
        [checked]="paymentType === 'lex_office_invoice' ? true : false">
      <label class="radio-button-label mb-0" for="lexoffice-radio-btn">{{ 'payment_method.lexoffice' | translate }}</label>
    </div>
  </div>

  <div class="mb-2">
    <app-csw-link
      [styleConfig]="{
          'color': widgetService?.widgetConf?.header_background_color,
          'textDecorationColor': widgetService?.widgetConf?.header_background_color
          }"
      [showUnderLine]="true"
      [isLink]="false"
      [text]="'booking_components.redeem_coupon' | translate"
      (clickEvent)="toggleCoupon()"
    ></app-csw-link>
  </div>

  <div *ngIf="showCouponField" class="d-flex flex-column align-items-start cw-stripe-box mb-2">
    <div class="stripe-title">
      {{ 'coupon_credit_card_info.apply_coupon' | translate }}:
    </div>
    <div class="stripe-field mt-2 w-100">
      <app-coupon-form>
      </app-coupon-form>
    </div>
  </div>

  <div
    *ngIf="
        isStripeEnabled
        && paymentType === 'creditcard'
        && bookingService?.selectedBookingData?.totalBookingAmount > 0
        "
    class="d-flex flex-column align-items-start cw-stripe-box mb-2">
    <div class="stripe-title">
      {{ 'payment_method.creditcard' | translate }}:
    </div>
    <div class="stripe-field mt-1 w-100">
      <app-stripe>
      </app-stripe>
    </div>
  </div>

  <div
    class="cw-paypal-box mt-4"
    *ngIf="
        isPaypalEnabled
        && paymentType === 'paypal'
        && bookingService?.selectedBookingData?.totalBookingAmount > 0
        "
  >
    <div *ngIf="showPaypalLoader">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="mt-2">
          Resetting Paypal
        </div>
      </div>
    </div>

    <app-cw-paypal
      *ngIf="!showPaypalLoader"
      [paypalSetting]="paypalSetting"
      [price]="bookingService?.selectedBookingData?.totalBookingAmount"
      [discount]="bookingService?.selectedBookingData?.totalDiscount"
    ></app-cw-paypal>
  </div>

</ng-container>

<ng-container *ngIf="bookingService.selectedBookingData.captcha">
  <div class="mb-2">
    <label class="input-label cbw-captcha-label">
      <span [innerHTML]="((bookingService.widgetTemplates | calioMeetingTemplate: { id: 242, type: 'template' }) | translation: 'template_content': lang | addRequired) | calioSafe: 'html'"></span>
    </label>
    <div>
      <img [src]="'data:image/png;base64,' + bookingService.selectedBookingData.captcha.captcha" alt="captcha" class="captcha-img cbw-captcha-img" />
    </div>
    <div class="pt-3 captcha-input-width">
      <div class="d-flex align-items-center justify-content-center gap-2">
        <input
          id="captcha"
          type="text"
          required
          name="captcha"
          #captcha="ngModel"
          placeholder="{{ 'captcha.placeholder' | translate }}"
          maxlength="6"
          minlength="6"
          spellcheck="false"
          [(ngModel)]="captchaInput"
          class="form-control cbw-input-field cbw-input-captcha-field"
        >
        <div *ngIf="validatingCaptchaChallenge"  class="d-inline-flex">
          <mat-icon class="spin-animation" svgIcon="calio-spinner-icon" />
        </div>
      </div>
      <div
        *ngIf="captcha.touched && !captcha?.valid"
        class="alert alert-danger captcha-warning cbw-captcha-error"
      >
        <div *ngIf="captcha.errors?.required">
          {{ 'captcha.required' | translate }}
        </div>
        <div *ngIf="captcha.errors?.minlength">
          {{ 'captcha.invalid' | translate }}
        </div>
        <div *ngIf="captcha.errors?.invalidCaptcha">
          {{ 'captcha.invalid' | translate }}
        </div>
      </div>

      <div
        *ngIf="captcha.touched && captcha?.valid"
        class="alert alert-success captcha-warning cbw-captcha-success"
      >
        <div>
          {{ 'captcha.valid' | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="alert alert-danger mt-3 mb-0" *ngIf="showError">
  <div class="error-title">{{ errorTitle | translate }}</div>
  <div>{{ errorMessage }}</div>
</div>

<div class="d-flex flex-column flex-md-row justify-content-md-between mt-4 mb-4">
  <div>
    <app-button
      [id]="'payment-method-book-additional-service-btn'"
      *ngIf="bookingService.partnerData.is_cart_supported === 1"
      [customClass]="'w-100'"
      [isDisabled]="disableAllButton"
      (clickEvent)="bookAdditionalService()"
      [fontColor]="widgetService?.widgetConf?.button_font_color"
      [backgroundColor]="widgetService?.widgetConf?.button_background_color"
    >
      {{ 'booking_components.add_service' | translate }}
    </app-button>
  </div>

  <div class="d-flex flex-column flex-md-row justify-content-md-end cbw-custom-navigation">
    <div class="me-md-2 my-2 my-md-0">
      <app-button
        [id]="'payment-method-previous-btn'"
        [customClass]="'w-100'"
        (clickEvent)="previous()"
        [isDisabled]="disableAllButton"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
      <span class="custom-button-icon back"></span>
        {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 129, type: 'template'
        }) | translation: 'template_content': lang
      )}}
      </app-button>
    </div>

    <div>
      <app-button
        [id]="'payment-method-book-btn'"
        [customClass]="'w-100'"
        (clickEvent)="book()"
        [isDisabled]="disableAllButton"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        <span
          [innerHTML]="((bookingService.widgetTemplates
            | calioMeetingTemplate: {
                id: 130,
                type: 'template'
              })
            | translation: 'template_content': lang
        )"></span>
        <span class="custom-button-icon next"></span>
      </app-button>
    </div>
  </div>
</div>



