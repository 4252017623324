<div *ngIf="customField" [ngClass]="{ 'deactive-field': deactivateField }" [class]="'csw-booking-question csw-'+customField.type">
  <ng-container [ngSwitch]="customField.type">
    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_SELECT">
      <div class="mt-3 row custom-select-field">
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none mt-3 mt-md-0 mt-no-first-child col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <ng-select
              #customFieldControl="ngModel"
              [id]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
              [name]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
              [placeholder]="customField.is_multi_language === 1 ? (customField | translation: 'placeholder': lang) : customField.placeholder"
              [multiple]="(customField.is_multiple_select === 1)"
              [closeOnSelect]="!(customField.is_multiple_select === 1)"
              [clearable]="!(customField.is_multiple_select === 1)"
              [(ngModel)]="customField.customerSelectedValue[(customEventIndex ? customEventIndex : innerIndex)]"
              (change)="onSelectChange($event, (customEventIndex ? customEventIndex : innerIndex))"
              [readonly]="!isEditAllowed || deactivateField"
              [searchable]="true"
              [required]="customField.required === 1  && customField.is_read_only === 0"
              [ngClass]="{ 'is-invalid': customFieldControl.touched && customFieldControl.invalid }"
              (open)="bindYoutubeInfoUrlClickHandler()"
              (close)="unbindYoutubeInfoUrlClickHandler()"
            >
              <ng-container *ngFor="let custom_field_option of customField.custom_field_options">
                <ng-container *ngIf="custom_field_option.id != null">
                  <ng-option [value]="custom_field_option.id" [disabled]="customField.is_read_only === 1">
                    <!-- Option icon -->
                    <ng-container
                      *ngIf="custom_field_option?.image | isEmptyString"
                      >
                      <img class="option-icon me-2" [src]="custom_field_option?.image" height="25" width="25" />
                    </ng-container>

                    <!-- Option text -->
                    {{ custom_field_option | translation: 'label': lang }}

                    <!-- Youtube -->
                    <span class="info-urls float-end" *ngIf="(custom_field_option?.youtube_url | isEmptyString)">
                      <a
                        class="select-option-youtube-element"
                        #selectOptionYoutubeElement
                        [routerLink]=""
                        [attr.data-option]="(custom_field_option | json)"
                      >
                        <img class="youtube-icon" src="/assets/images/logo/youtube_social_icon_red.png" />
                      </a>
                    </span>

                    <!-- Info -->
                    <span
                      class="info-urls float-end"
                      *ngIf="(custom_field_option?.info_url | isEmptyString)"
                      [ngClass]="{
                        'me-2': (custom_field_option?.youtube_url | isEmptyString)
                      }"
                    >
                      <a
                        target="_blank"
                        [href]="custom_field_option.info_url"
                      >
                        <img [src]="deployUrl + 'assets/images/icons/info-filled-icon.svg'" />
                      </a>
                    </span>
                  </ng-option>
                </ng-container>
              </ng-container>
            </ng-select>
            <div
              class="text-danger"
              *ngIf="customFieldControl.touched && customFieldControl.invalid"
            >
              <div *ngIf="customFieldControl?.errors?.required">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container
        *ngIf="customField?.showEndOfProcess === true"
        [ngTemplateOutlet]="endOfProcessBlock"
      ></ng-container>

      <ng-container
        *ngIf="customField.generate_entity_count && customField?.showEndOfProcess === false"
        [ngTemplateOutlet]="entityNameFieldTemplate"
        [ngTemplateOutletContext]="{
          $implicit: customField.generate_entity_count,
          widgetConf: widgetConf
        }"
      ></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_TEXT">
      <div class="mt-3 row">
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none mt-3 mt-md-0 mt-no-first-child col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <input
              [id]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
              [name]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
              type="text"
              class="form-control"
              [placeholder]="(customField.is_multi_language === 1 ?  (customField | translation: 'placeholder': lang) : (customField.placeholder ? customField.placeholder : ''))"
              [(ngModel)]="customField.customerSelectedValue[innerIndex]"
              #customFieldControl="ngModel"
              [disabled]="!isEditAllowed || deactivateField"
              [required]="customField.required === 1  && customField.is_read_only === 0"
              [minlength]="customField?.min_characters > 0 ? customField.min_characters : null"
              [maxlength]="customField?.max_characters > 0 ? customField.max_characters : null"
              (blur)="onChangeCustomFieldSelectedValue(); onBlurCustomFieldSelectedValue();"
            >
            <div
              class="text-danger"
              *ngIf="customFieldControl?.touched && customFieldControl?.invalid"
            >
              <div *ngIf="customFieldControl?.errors?.required">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
              <div *ngIf="customFieldControl?.errors?.maxlength">
                {{ 'error_messages.maximum_chars_error_messgae' | translate: {
                  requiredLength: customField.max_characters
                } }}
              </div>
              <div *ngIf="customFieldControl?.errors?.minlength">
                {{ 'error_messages.minimum_chars_error_messgae' | translate: {
                  requiredLength: customField.min_characters
                } }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_TEXT_AREA">
      <div class="mt-3 row">
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none mt-3 mt-md-0 mt-no-first-child col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <textarea
              [id]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
              [name]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
              type="text"
              class="form-control"
              [placeholder]="customField.is_multi_language === 1 ?  (customField | translation: 'placeholder': lang) : customField.placeholder"
              rows="4"
              [(ngModel)]="customField.customerSelectedValue[innerIndex]"
              #customFieldControl="ngModel"
              [disabled]="!isEditAllowed || deactivateField"
              [required]="customField.required === 1  && customField.is_read_only === 0"
              [minlength]="customField.min_characters > 0 ? customField.min_characters : null"
              [maxlength]="customField.max_characters > 0 ? customField.max_characters : null"
              (blur)="onChangeCustomFieldSelectedValue(); onBlurCustomFieldSelectedValue();"
            ></textarea>
            <div class="text-danger" *ngIf="customFieldControl.touched && customFieldControl.invalid">
              <div *ngIf="customFieldControl?.errors?.required">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
              <div *ngIf="customFieldControl?.errors?.maxlength">
                {{ 'error_messages.maximum_chars_error_messgae' | translate: {
                  requiredLength: customField.max_characters
                } }}
              </div>
              <div *ngIf="customFieldControl?.errors?.minlength">
                {{ 'error_messages.minimum_chars_error_messgae' | translate: {
                  requiredLength: customField.min_characters
                } }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_CHECKBOX">
      <div class="mt-3 row">
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none mt-3 mt-md-0 mt-no-first-child col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <div class="d-flex justify-content-between">
              <div class="custom-control custom-checkbox">
                <input
                  [id]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                  [name]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="customField.customerSelectedValue[innerIndex]"
                  #customFieldControl="ngModel"
                  (ngModelChange)="onCheckBoxFieldChange($event)"
                  [disabled]="!isEditAllowed || deactivateField"
                  [required]="customField.required === 1  && customField.is_read_only === 0"
                >
                <ng-template [ngIf]="customField.is_multi_language === 1">
                  <label [for]="customField.id + '_' + innerIndex" class="custom-control-label ps-2">
                    <span [innerHTML]="customField | translation: 'label': lang | calioSafe: 'html'"></span>
                    <span *ngIf="customField.required === 1">*</span>
                  </label>
                </ng-template>
                <ng-template [ngIf]="customField.is_multi_language === 0">
                  <label [for]="customField.id + '_' + innerIndex" class="custom-control-label ps-2">
                    <span [innerHTML]="customField.label | calioSafe: 'html'"></span>
                    <span *ngIf="customField.required === 1"> *</span>
                  </label>
                </ng-template>
              </div>
            </div>
            <div *ngIf="customFieldControl.touched && customFieldControl.invalid" class="text-danger">
              <div *ngIf="customFieldControl.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_RADIO">
      <div class="mt-3 row">
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none mt-3 mt-md-0 mt-no-first-child col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <ng-container *ngFor="let custom_field_option of customField.custom_field_options[innerIndex]">
              <ng-container *ngIf="custom_field_option.id != null">
                <div class="d-flex align-items-center mt-1 radio-item">
                  <input
                    [id]="customField.id + '_' + custom_field_option.id + '_' + innerIndex"
                    [name]="customField.id + '_' + custom_field_option.id + '_' + innerIndex"
                    type="radio"
                    [value]="custom_field_option.id"
                    [checked]="customField.customerSelectedValue[innerIndex] === custom_field_option.id"
                    (change)="onRadioValueChanged(custom_field_option, true, innerIndex)"
                    [attr.disabled]="(!isEditAllowed || deactivateField) ? true: null"
                    [disabled]="!isEditAllowed || deactivateField"
                    [required]="customField.required === 1  && customField.is_read_only === 0"
                    [(ngModel)]="customField.customerSelectedValue[innerIndex]"
                  >
                  <label class="radio-button-label" [for]="customField.id + '_' + custom_field_option.id + '_' + innerIndex">
                    <span [innerHTML]="(custom_field_option | translation: 'label': lang) | calioSafe: 'html'"></span>
                  </label>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <ng-container
        *ngIf="customField?.showEndOfProcess === true"
        [ngTemplateOutlet]="endOfProcessBlock"
        [ngTemplateOutletContext]="{
          customClass: {
            'mt-3': true
          }
        }"
      ></ng-container>

      <ng-container
        *ngIf="customField.generate_entity_count && customField?.showEndOfProcess === false"
        [ngTemplateOutlet]="entityNameFieldTemplate"
        [ngTemplateOutletContext]="{
          $implicit: customField.generate_entity_count,
          widgetConf: widgetConf
        }"
      ></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_DATE">
      <div class="mt-3 row csw-date-field">
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none mt-3 mt-md-0 mt-no-first-child col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <mat-form-field >
              <input
                class=""
                [id]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                [name]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                matInput
                [matDatepicker]="picker"
                [(ngModel)]="selectedDate[innerIndex]"
                #dateModel="ngModel"
                [disabled]="!isEditAllowed || deactivateField"
                [required]="customField.required === 1 && customField.is_read_only === 0"
                (dateChange)="onChangeDate($event, innerIndex)"
              >
              <mat-datepicker-toggle class="pb-2" matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div *ngIf="dateModel.touched && dateModel.invalid" class="text-danger">
              <div
                *ngIf="dateModel.errors['required']">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_INFORMATION">
      <div class="mt-3">
        <div class="alert"
          [ngClass]="{
            'alert-danger': customField.color === 'red',
            'alert-primary': customField.color === 'blue',
            'alert-warning': customField.color === 'yellow',
            'alert-light': customField.color === 'neutral'
          }"
        >
          <ng-template [ngIf]="customField.is_multi_language === 1">
            <div *ngIf="(customField | translation: 'title': lang) | isEmptyString" class="heading">
              <span [innerHTML]="(customField | translation: 'title': lang) | calioSafe: 'html'"></span>
            </div>
            <div *ngIf="(customField | translation: 'label': lang) | isEmptyString" class="d-flex justify-content-between">
              <label for="{{ customField.id }}" [ngClass]="{ 'input-label-1': ((customField | translation: 'title': lang) | isEmptyString) }">
                <span [innerHTML]="(customField | translation: 'label': lang) | calioSafe: 'html'"></span>
              </label>
            </div>
          </ng-template>
          <ng-template [ngIf]="customField.is_multi_language === 0">
            <div *ngIf="(customField.title | isEmptyString)" class="heading">
              <span [innerHTML]="customField.title | calioSafe: 'html'"></span>
            </div>
            <div *ngIf="(customField.label | isEmptyString)" class="d-flex justify-content-between">
              <label for="{{ customField.id }}" [ngClass]="{ 'input-label-1': (customField.title | isEmptyString) }">
                <span [innerHTML]="customField.label | calioSafe: 'html'"></span>
              </label>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_IMAGE_SELECT_CARD">
      <div class="mt-3 row">
        <div class="col-md-12">
          <div *ngIf="!isEditAllowed" class="alert alert-warning">
            {{ 'error_messages.custom_field_as_readonly' | translate }}
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
      </div>
      <div class="image-card-select-options row">
        <ng-container *ngIf="parentField?.generate_entity_count && columnRangeCount > 1">
          <div
            class="col-md-12 summary-warning"
            *ngIf="customField.required === 1 && customField.is_read_only === 0 && !validImageSelect"
          >
            <div class="alert alert-danger">
              {{ 'error_messages.image_select_required_error' | translate }}
            </div>
          </div>
          <div class="col-md-12 mb-3">
            <span>
              {{ 'survey_component.image_select_entity_selector' | translate }}
            </span>
            <ng-select
              name="imageSeletEntitySelectorSelectbox"
              [clearable]="false"
              [selectOnTab]="true"
              [openOnEnter]="true"
              #imageSelectEntitySelectorModel="ngModel"
              [(ngModel)]="imageSelectEntitySelector"
            >
              <ng-option
                *ngFor="let entity of columnRangeArray; let innerIndex = index;"
                [value]="innerIndex"
              >
                {{
                  (parentField.entity_names[innerIndex]
                    ? parentField.entity_names[innerIndex]
                    : 'survey_component.entity_name_label' | translate: {number: (innerIndex+1)}
                  )
                }}
              </ng-option>
            </ng-select>
          </div>
        </ng-container>

        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div
            [class]="'col-md-12 entity-image-select-options options-slot-' + innerIndex"
            [ngClass]="{
              'd-none': innerIndex !== imageSelectEntitySelector
            }"
          >
            <div class="row csw-cf-{{customField.id}}">
              <div
                class="mb-4 image-options"
                *ngFor="let option of customField.custom_field_options[innerIndex]"
                [ngClass]="{
                  'col-md-6': option.is_fullscreen_image === 1,
                  'col-md-3': option.is_fullscreen_image !== 1
                }"
              >
                <!-- {{
                  {
                    customerSelectedValue: customField?.customerSelectedValue,
                    innerIndex: innerIndex,
                    option: option.id
                  } | json
                }} -->
                <app-info-card
                  [showCardBorder]="true"
                  [showShadow]="false"
                  [showImage]="true"
                  [showBody]="true"
                  [showCursorPointer]="true"
                  [isSelected]="(
                    customField?.customerSelectedValue[innerIndex] &&
                    customField?.customerSelectedValue[innerIndex]?.indexOf(option.id) > -1
                  )"
                  [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
                  [customCardStylesConfig]="{ borderRadius: '0px' }"
                  (cardClickEvent)="onCardImageCustomField(null, option, innerIndex)"
                >
                  <div class="cursor-pointer" cardImage>
                    <img
                      #imageSelectionOption
                      [src]="option.image"
                      [ngClass]="{
                        'card-img-top': option.is_fullscreen_image === 1,
                        'icon-img': option.is_fullscreen_image !== 1
                      }"
                    />
                  </div>

                  <div class="cursor-pointer" cardBody>
                    <div class="d-flex align-items-center">
                      <mat-checkbox
                        class="cursor-pointer w-100"
                        [ngClass]="{
                          'has-card-info-url': (option?.info_url ? true : false)
                        }"
                        #checkboxModel
                        [disableRipple]="true"
                        [checked]="(
                          customField?.customerSelectedValue[innerIndex] &&
                          customField?.customerSelectedValue[innerIndex]?.indexOf(option.id) > -1
                        )"
                        [(ngModel)]="option.customerSelectedValue"
                        name="image_card_option"
                        [disabled]="!isEditAllowed || deactivateField"
                      >
                        <div class="d-flex justify-content-between image-title-block">
                          <div class="d-flex flex-column">
                            <div *ngIf="(option | translation: 'title': lang)" class="mb-1 option-title">
                              {{ option | translation: 'title': lang }}
                            </div>
                            <div
                              class="label"
                              [ngClass]="{
                                'text-muted': (option | translation: 'title': lang),
                                'option-title': !(option | translation: 'title': lang)
                              }"
                            >
                              {{ option | translation: 'label': lang }}
                            </div>
                          </div>
                          <div
                            class="d-flex justify-content-end"
                            *ngIf="option.is_fullscreen_image === 1"
                          >
                            <span
                              [ngClass]="{
                                'me-2': option?.info_url ? true : false
                              }"
                            >
                              <a href="javascript:void(0);" (click)="openImagePopup(option, imageSelectionOption); $event.stopImmediatePropagation();">
                                <img class="view-image-icon" [src]="deployUrl + 'assets/images/icons/full-screen.svg'" />
                              </a>
                            </span>

                            <span
                              [ngClass]="{
                                'me-2': option?.youtube_url ? true : false
                              }"
                              *ngIf="(option?.info_url | isEmptyString)"
                            >
                              <a target="_blank" href="{{ option?.info_url }}" (click)="$event.stopImmediatePropagation()">
                                <img [src]="deployUrl + 'assets/images/icons/info-filled-icon.svg'" />
                              </a>
                            </span>

                            <span *ngIf="(option?.youtube_url | isEmptyString)">
                              <a #youtubeElement href="javascript:void(0)" (click)="openYoutubeVideo(option, youtubeElement); $event.stopImmediatePropagation();">
                                <img class="youtube-icon" src="/assets/images/logo/youtube_social_icon_red.png">
                              </a>
                            </span>
                          </div>
                        </div>
                      </mat-checkbox>

                      <ng-container *ngIf="option.is_fullscreen_image !== 1">
                        <span class="card-info-url" *ngIf="(option?.info_url | isEmptyString)">
                          <a target="_blank" href="{{ option?.info_url }}" (click)="$event.stopImmediatePropagation()">
                            <img [src]="deployUrl + 'assets/images/icons/info-filled-icon.svg'" />
                          </a>
                        </span>

                        <span class="select-card-youtube-url" *ngIf="(option?.youtube_url | isEmptyString)">
                          <a #youtubeElement href="javascript:void(0)" (click)="openYoutubeVideo(option, youtubeElement); $event.stopImmediatePropagation();">
                            <img class="youtube-icon" src="/assets/images/logo/youtube_social_icon_red.png">
                          </a>
                        </span>
                      </ng-container>
                    </div>
                  </div>
                </app-info-card>
              </div>

              <div class="col-sm-12">
                <input
                  hidden
                  [id]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                  [name]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                  [required]="customField.required === 1 && customField.is_read_only === 0"
                  [(ngModel)]="customField.customerSelectedValue[innerIndex]"
                  #customFieldSelect="ngModel"
                  class="form-control cbw-input-field"
                >
                <div
                  class="alert alert-danger summary-warning mt-1"
                  *ngIf="customFieldSelect.touched && !customFieldSelect.valid"
                >
                  <div *ngIf="customFieldSelect.errors?.required">
                    {{ 'error_messages.required_error_message' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container
        *ngIf="customField?.showEndOfProcess === true"
        [ngTemplateOutlet]="endOfProcessBlock"
      ></ng-container>

      <ng-container
        *ngIf="customField.generate_entity_count && customField?.showEndOfProcess === false"
        [ngTemplateOutlet]="entityNameFieldTemplate"
        [ngTemplateOutletContext]="{
          $implicit: customField.generate_entity_count,
          widgetConf: widgetConf
        }"
      ></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_SELECT_CARD">
      <div class="mt-3 row">
        <div class="col-md-12">
          <div *ngIf="!isEditAllowed" class="alert alert-warning">
            {{ 'error_messages.custom_field_as_readonly' | translate }}
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
      </div>

      <div class="image-card-select-options row csw-cf-{{customField.id}}">
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <div class="row">
              <div
                class="mb-4"
                [ngClass]="{
                  'col-md-3': columnRangeCount == 1,
                  'col-md-6': (columnRangeCount > 1 && columnRangeCount <= 2),
                  'col-md-12': columnRangeCount >= 3
                }"
                *ngFor="let option of customField.custom_field_options[innerIndex]"
              >
                <app-info-card
                  [showCardBorder]="true"
                  [showShadow]="false"
                  [showBody]="true"
                  [showCursorPointer]="true"
                  [isSelected]="(
                    customField?.customerSelectedValue[innerIndex] &&
                    customField?.customerSelectedValue[innerIndex]?.indexOf(option.id) > -1
                  )"
                  [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
                  [customCardStylesConfig]="{ borderRadius: '0px' }"
                  (cardClickEvent)="onCardImageCustomField(null, option, innerIndex)"
                >
                  <div class="cursor-pointer" cardBody>
                    <div class="d-flex align-items-center">
                      <mat-checkbox
                        class="cursor-pointer"
                        [ngClass]="{
                          'has-card-info-url': (option?.info_url ? true : false),
                          'additional-padding': (option?.info_url && option?.youtube_url)
                        }"
                        #checkboxModel
                        [disableRipple]="true"
                        [checked]="(
                          customField?.customerSelectedValue[innerIndex] &&
                          customField?.customerSelectedValue[innerIndex]?.indexOf(option.id) > -1
                        )"
                        [(ngModel)]="option.customerSelectedValue"
                        name="image_card_option"
                        [disabled]="!isEditAllowed || deactivateField"
                      >
                        <div class="d-flex flex-column">
                          <div *ngIf="(option | translation: 'title': lang)" class="mb-1 option-title">
                            {{ option | translation: 'title': lang }}
                          </div>
                          <div
                            [ngClass]="{
                              'text-muted': (option | translation: 'title': lang),
                              'option-title': !(option | translation: 'title': lang)
                            }"
                          >
                            {{ option | translation: 'label': lang }}
                          </div>
                        </div>
                      </mat-checkbox>
                      <span
                        class="card-info-url"
                        *ngIf="(option?.info_url | isEmptyString)"
                      >
                        <a target="_blank" href="{{ option?.info_url }}" (click)="$event.stopImmediatePropagation()">
                          <img [src]="deployUrl + 'assets/images/icons/info-filled-icon.svg'" />
                        </a>
                      </span>

                      <span
                        class="select-card-youtube-url"
                        *ngIf="(option?.youtube_url | isEmptyString)"
                      >
                        <a #youtubeElement href="javascript:void(0)" (click)="openYoutubeVideo(option, youtubeElement); $event.stopImmediatePropagation();">
                          <img class="youtube-icon" src="/assets/images/logo/youtube_social_icon_red.png">
                        </a>
                      </span>
                    </div>
                  </div>
                </app-info-card>
              </div>

              <div class="col-sm-12">
                <input
                  [id]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                  [name]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                  hidden
                  [required]="customField.required === 1 && customField.is_read_only === 0"
                  [(ngModel)]="customField.customerSelectedValue[innerIndex]"
                  #customFieldSelect="ngModel"
                  class="form-control cbw-input-field"
                >
                <div
                  class="alert alert-danger summary-warning mt-1"
                  *ngIf="customFieldSelect.touched && !customFieldSelect.valid"
                >
                  <div *ngIf="customFieldSelect.errors.required">
                    {{ 'error_messages.required_error_message' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container
        *ngIf="customField?.showEndOfProcess === true"
        [ngTemplateOutlet]="endOfProcessBlock"
      ></ng-container>

      <ng-container
        *ngIf="customField.generate_entity_count && customField?.showEndOfProcess === false"
        [ngTemplateOutlet]="entityNameFieldTemplate"
        [ngTemplateOutletContext]="{
          $implicit: customField.generate_entity_count,
          widgetConf: widgetConf
        }"
      ></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_NUMBER_SELECT_CARD">
      <div class="mt-3 row">
        <div class="col-md-12">
          <div *ngIf="!isEditAllowed" class="alert alert-warning">
            {{ 'error_messages.custom_field_as_readonly' | translate }}
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
      </div>

      <div class="number-card-select-options row csw-cf-{{customField.id}}">
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <div class="row">
              <div
                class="mb-4"
                [ngClass]="{
                  'col-lg-6 col-md-6': columnRangeCount <= 1,
                  'col-md-12': columnRangeCount > 1
                }"
                *ngFor="let option of customField.custom_field_options[innerIndex];"
              >
                <app-info-card
                  [showCardBorder]="true"
                  [showShadow]="false"
                  [showBody]="true"
                  [customCardStylesConfig]="{ borderRadius: '0px' }"
                >
                  <div class="cursor-pointer" cardBody>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex flex-column">
                        <div *ngIf="(option | translation: 'title': lang)" class="mb-1 option-title">
                          {{ option | translation: 'title': lang }}
                        </div>
                        <div
                          [ngClass]="{
                            'text-muted': (option | translation: 'title': lang),
                            'option-title': !(option | translation: 'title': lang)
                          }"
                        >
                          {{ option | translation: 'label': lang }}
                        </div>
                      </div>

                      <div class="d-flex align-items-center">
                        <button
                          type="button"
                          [disabled]="(option.number_min === option?.option_value || option?.option_value < option.number_min) || !isEditAllowed"
                          class="btn btn-link d-flex align-items-center justify-content-center rounded-icon"
                          (click)="reduceNumberCardValue(option, innerIndex)"
                        >
                          <mat-icon class="minus-solid" svgIcon="minus-solid"></mat-icon>
                        </button>
                        <span class="px-4">{{ option?.option_value }}</span>
                        <button
                          type="button"
                          [disabled]="(option.number_max === option?.option_value || option?.option_value > option.number_max) || !isEditAllowed"
                          class="btn btn-link d-flex align-items-center justify-content-center rounded-icon"
                          (click)="addNumberCardValue(option, innerIndex)"
                        >
                          <mat-icon class="plus-solid" svgIcon="plus-solid"></mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </app-info-card>
              </div>
              <div class="col-sm-12">
                <input
                  hidden
                  [required]="customField.required === 1 && customField.is_read_only === 0"
                  [(ngModel)]="customField.customerSelectedValue[innerIndex]"
                  #customFieldSelect="ngModel"
                  [id]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                  [name]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                  class="form-control cbw-input-field"
                >
                <div class="alert alert-danger summary-warning mt-1" *ngIf="customFieldSelect.touched && !customFieldSelect.valid">
                  <div *ngIf="customFieldSelect.errors.required">
                    {{ 'error_messages.required_error_message' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_FILE">
      <div class="mt-3 row">
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <div class="input-group">
              <input
                [id]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                [name]="customField.id + '_' + (customEventIndex ? customEventIndex : innerIndex)"
                type="file"
                class="form-control input-file"
                placeholder="{{ customField.is_multi_language === 1 ?  (customField | translation: 'placeholder': lang) : customField.placeholder }}"
                [(ngModel)]="customField.customerSelectedInputValue[innerIndex]"
                #customFieldControl="ngModel"
                (change)="onFileChanged($event, innerIndex)"
                [multiple]="(customField.is_multiple_file === 1)"
                [disabled]="customField.is_read_only === 1 || (customField?.is_multiple_file === 0 && customField?.customerSelectedFileValue[index]?.length === 1) || !isEditAllowed || deactivateField"
                accept="{{customField.customSupportedMimeType}}"
                [required]="(customField.required === 1  && customField.is_read_only === 0 && (!customField.customerSelectedValue[innerIndex] || customField.customerSelectedValue[innerIndex]?.length === 0))"
              >
            </div>

            <small *ngIf="customField.is_multiple_file === 1" class="form-text text-muted">
              {{ 'error_messages.multiple_file_upload_helper_text' | translate }}
            </small>

            <small *ngIf="customField.is_multiple_file === 0" class="form-text text-muted">
              {{ 'error_messages.single_file_upload_helper_text' | translate }}
            </small>

            <div class="text-danger" *ngIf="waitForFileUpload[innerIndex]">
              <div>
                {{ 'error_messages.file_uploading' | translate }}
              </div>
            </div>

            <div class="text-danger" *ngIf="customFieldControl.touched && customFieldControl.invalid">
              <div *ngIf="customFieldControl?.errors?.required">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>

            <div class="text-danger" *ngIf="customField.errorMessage?.[innerIndex]">
              {{ customField.errorMessage[innerIndex] | translate: { fileLength: 5 } }}
            </div>

            <div class="">
              <ul class="ps-0 mt-2 file-list">
                <li class="pt-1" *ngFor="let file of customField.customerSelectedFileValue[innerIndex]; let i=index;">
                  <div class="d-flex align-items-center justify-content-between">
                    <span>
                      <a
                        *ngIf="widgetService.layoutType !== 'CHATBOX'"
                        class="ahref"
                        [href]="file?.file?.full_path"
                        target="_blank"
                      >
                        {{ file?.file?.origin_filename }}
                      </a>

                      <app-csw-link
                        *ngIf="widgetService.layoutType === 'CHATBOX'"
                        [isLink]="true"
                        [styleConfig]="{
                          'color': widgetService?.widgetConf?.header_background_color,
                          'textDecorationColor': widgetService?.widgetConf?.header_background_color
                        }"
                        [link]="file?.file?.full_path"
                        [text]="file?.file?.origin_filename"
                      />
                    </span>

                    <span *ngIf="isEditAllowed" class="file-trash-icon cursor-pointer" (click)="deleteFile(file, i, innerIndex)">
                      <mat-icon class="button-icon" svgIcon="times-solid" />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="customFieldConst.CUSTOM_FIELD_WORKER_SELECT">
      <div class="mt-3 row custom-worker-select-field">
        <ng-container [ngTemplateOutlet]="labelsTemplate"></ng-container>
        <ng-container *ngFor="
          let element of columnRangeArray;
          let innerIndex = index;"
        >
          <div [class]="'border-end no-right-border-last-child border-sm-right-none mt-3 mt-md-0 mt-no-first-child col-md-'+ (12 / columnRangeCount)">
            <ng-container
              *ngIf="parentField?.generate_entity_count"
              [ngTemplateOutlet]="entityNameTemplate"
              [ngTemplateOutletContext]="{
                $implicit: innerIndex,
                widgetConf: widgetConf
              }"
            ></ng-container>
            <ng-select
              #customFieldControl="ngModel"
              [id]="customField.id + '_' + innerIndex"
              [name]="customField.id + '_' + innerIndex"
              [placeholder]="customField.is_multi_language === 1 ? (customField | translation: 'placeholder': lang) : customField.placeholder"
              [multiple]="(customField.is_multiple_select === 1)"
              [closeOnSelect]="!(customField.is_multiple_select === 1)"
              [clearable]="!(customField.is_multiple_select === 1)"
              [(ngModel)]="customField.customerSelectedValue[(customEventIndex ? customEventIndex : innerIndex)]"
              (change)="onSelectChange($event, (customEventIndex ? customEventIndex : innerIndex))"
              [readonly]="!isEditAllowed || deactivateField"
              [searchable]="true"
              [required]="customField.required === 1  && customField.is_read_only === 0"
              [ngClass]="{ 'is-invalid': customFieldControl.touched && customFieldControl.invalid }"
              [loading]="customField?.isOptionsAreLoading"
            >
              <ng-container *ngIf="egalWerShowSetting === true">
                <ng-option value="null">
                  <div class="worker-option">
                    <ng-container *ngIf="customField?.show_avatar === 1">
                      <div class="avatar">
                        <img class="default-avatar rounded-circle" [src]="imagePath + '/img/sample-avatar.png'" />
                      </div>
                    </ng-container>

                    {{
                      (bookingService.widgetTemplates
                        | calioMeetingTemplate: { id: 126, type: 'template' })
                        | translation: 'template_content': lang
                    }}
                  </div>
                </ng-option>
              </ng-container>

              <ng-container *ngFor="let worker of workers">
                <ng-option [value]="worker.id">
                  <div class="worker-option">
                    <ng-container *ngIf="customField?.show_avatar === 1">
                      <div class="avatar">
                        <ng-container *ngIf="worker.avatar">
                          <img #image class="resource-avatar rounded-circle"
                            [src]="workerAvatarBaseUrl + worker.uuid + '/thumb_' + worker.avatar"
                            (error)="image.src= imagePath + '/img/sample-avatar.png'"
                          />
                        </ng-container>
                        <ng-container *ngIf="!worker.avatar">
                          <img class="default-avatar rounded-circle" [src]="imagePath + '/img/sample-avatar.png'" />
                        </ng-container>
                      </div>
                    </ng-container>

                    {{ worker.resource_name }}
                  </div>
                </ng-option>
              </ng-container>
            </ng-select>
            <div
              class="text-danger"
              *ngIf="customFieldControl.touched && customFieldControl.invalid"
            >
              <div *ngIf="customFieldControl?.errors?.required">
                {{ 'error_messages.required_error_message' | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container
        *ngIf="customField?.showEndOfProcess === true"
        [ngTemplateOutlet]="endOfProcessBlock"
      ></ng-container>

      <ng-container
        *ngIf="customField.generate_entity_count && customField?.showEndOfProcess === false"
        [ngTemplateOutlet]="entityNameFieldTemplate"
        [ngTemplateOutletContext]="{
          $implicit: customField.generate_entity_count,
          widgetConf: widgetConf
        }"
      ></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #entityNameTemplate let-innerIndex let-widgetConf="widgetConf">
  <p
    class="mb-1"
    [innerHtml]="(parentField.entity_names[innerIndex]
      ? parentField.entity_names[innerIndex]
      : 'survey_component.entity_name_label' | translate: {
          number: (innerIndex+1),
          name_of_questionnaire : (widgetConf | translation: 'title': lang)
        } | calioSafe: 'html'
    )"
  ></p>
</ng-template>

<ng-template #entityNameFieldTemplate let-count let-widgetConf="widgetConf">
  <div class="mt-3 row">
    <ng-container *ngFor="let element of helperService.createArrayRange(count); let i = index;">
      <div [class]="'border-end no-right-border-last-child border-sm-right-none mt-3 mt-md-0 mt-no-first-child col-md-'+ (12 / count)">
        <app-csw-text
          [customClass]="'csw-booking-question-title csw-booking-question-title-'+customField.id"
          [text]="'survey_component.entity_name_field_label' | translate: {
            number: (i+1),
            name_of_questionnaire : (widgetConf | translation: 'title': lang)
          } | calioSafe: 'html'"
          [styleConfig]="textContainerStyleConfig"
        >
        </app-csw-text>
        <input
          type="text"
          class="mt-1 form-control"
          [id]="'entity_name_' + i"
          [name]="'entity_name_' + i"
          #entity_name="ngModel"
          maxlength="300"
          [(ngModel)]="customField.entity_names[i]"
        />
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #labelsTemplate>
  <div class="col-md-12" [ngStyle]="textContainerStyleConfig">
    <div *ngIf="!((customField | translation: 'title': lang) | isEmptyString)">
      <app-csw-text
        *ngIf="prefix"
        [customClass]="'pe-2 csw-booking-question-title csw-booking-question-title-'+customField.id"
        [text]="prefix"
        [styleConfig]="titleStyleConfig"
      >
      </app-csw-text>
      <div
        *ngIf="(customField | translation: 'label': lang) && (customField.type !== customFieldConst.CUSTOM_FIELD_CHECKBOX)"
      >
        <app-csw-text
          [customClass]="'csw-booking-question-title csw-booking-question-title-'+customField.id"
          [text]="(customField | translation: 'label': lang) | calioSafe: 'html'"
          [styleConfig]="titleStyleConfig"
        >
        </app-csw-text>
        <app-csw-text
          *ngIf="customField.required === 1"
          [customClass]="'csw-booking-question-title csw-booking-question-title-'+customField.id"
          [text]="' *'"
          [styleConfig]="titleStyleConfig"
        >
        </app-csw-text>
      </div>
    </div>

    <ng-container *ngIf="(customField | translation: 'title': lang) | isEmptyString">
      <div>
        <app-csw-text
          *ngIf="prefix"
          [customClass]="'pe-2 csw-booking-question-title csw-booking-question-title-'+customField.id"
          [text]="prefix"
          [styleConfig]="titleStyleConfig"
        >
        </app-csw-text>
        <app-csw-text
          [customClass]="'csw-booking-question-title csw-booking-question-title-'+customField.id"
          [text]="(customField | translation: 'title': lang) | calioSafe: 'html'"
          [styleConfig]="titleStyleConfig"
        >
        </app-csw-text>
      </div>
      <div
        *ngIf="(customField | translation: 'label': lang) && (customField.type !== customFieldConst.CUSTOM_FIELD_CHECKBOX)"
      >
        <app-csw-text
          [customClass]="'label csw-booking-question-title csw-booking-question-title-'+customField.id"
          [text]="(customField | translation: 'label': lang) | calioSafe: 'html'"
          [styleConfig]="labelStyleConfig"
        >
        </app-csw-text>
        <app-csw-text
          *ngIf="customField.required === 1"
          [customClass]="'label csw-booking-question-title csw-booking-question-title-'+customField.id"
          [text]="' *'"
          [styleConfig]="labelStyleConfig"
        >
        </app-csw-text>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- options dependent questions -->
<ng-content></ng-content>
<!-- options dependent questions -->

<ng-template #endOfProcessBlock let-customClass="customClass">
  <div
    role="alert"
    class="alert alert-warning csw-end-of-process-block"
    [ngClass]="customClass ? customClass : null"
    [ngStyle]="textContainerStyleConfig"
  >
    <div
      class="csw-end-of-process-text"
      [innerHtml]="endOfProcessMessageObj | translation: 'end_of_process_message' : lang | calioSafe: 'html'"
    ></div>
  </div>
</ng-template>
