import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CalioPipesModule} from '../pipes/calio-pipes.module';
import {TypographyModule} from '../ui-lib/typography/typography.module';
import {CardsModule} from '../ui-lib/cards/cards.module';


@NgModule({
  declarations: [],
  imports: [],
  exports: [
    CommonModule,
    TranslateModule,
    CalioPipesModule,
    TypographyModule,
    CardsModule,
  ]
})
export class SharedModule {
}
