import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IS_CALENSO_AUTH_REQUIRED } from '../../constants/http-context';
import { CustomerDbModel } from '../../models/customer.model';
import { WidgetConfModel } from '../../models/widget-conf.model';
import { WorkerDbModel } from '../../models/worker-db.model';
import { HttpClientService } from '../utils/http-client.service';
import { LoggerService } from '../utils/logger.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  partnerUUID: string;
  widgetUUID: string;
  customerConfData: CustomerDbModel = new CustomerDbModel();
  widgetConf: WidgetConfModel;
  requestedClientDomain: string;
  clientOriginEvent: any;
  layoutType: string;
  widgetWorkerData: WorkerDbModel;
  parentToChildOriginEvent: any;
  private _endOfProcessSurvey: {[key: string]: boolean | number} = {};
  public get endOfProcessSurvey(): {[key: string]: boolean | number} { return this._endOfProcessSurvey; }
  public set endOfProcessSurvey(state: {[key: string]: boolean | number}) { this._endOfProcessSurvey = state; }

  constructor(private httpClientService: HttpClientService) {
  }

  getWidgetConfiguration(partnerUUID: string, widgetUUID: string): Observable<WidgetConfModel> {
    const body: {
      partner_uuid: string,
      widget_uuid: string,
    } = {
      partner_uuid: partnerUUID,
      widget_uuid: widgetUUID
    };

    return this.httpClientService.post('smart_widget_configurations/configuration', body, {
      context: new HttpContext().set(IS_CALENSO_AUTH_REQUIRED, true)
    });
  }

  sendMessageToMainJs(event: any, data: any): void {
    LoggerService.log('event is ', event);
    LoggerService.log('data is ', data);
    event.source.postMessage(data, event.origin);
  }

  getWidgetWorker(workerUUID: string): Observable<WorkerDbModel> {
    return this.httpClientService.get(`workers/${workerUUID}`, {});
  }

  isDefaultMeetingTypeIdSelected(): boolean {
    return !!this.customerConfData.meeting_type_id;
  }

  isDefaultStoreIdSelected(): boolean {
    return !!this.widgetConf.context.store_id;
  }

  isDefaultAppointmentServiceIdsSelected(): boolean {
    return this.widgetConf.context?.appointment_service_ids?.length > 0;
  }

  isDefaultWorkerIdSelected(): boolean {
    return this.widgetConf.context?.worker_id >= 0;
  }
}
