<div class="cbsw-booking-summary-step">
  <ng-container *ngIf="bookingService.selectedBookingData?.cartItems?.length > 0">
    <div class="mb-2">
      <app-csw-text
        [text]="templateTitle | translation: 'template_content': lang"
        [styleConfig]="{ fontWeight: 600 }"
        [customClass]="'step-element-title cbsw-booking-summary-step-title'"
      >
      </app-csw-text>
      <div class="text-muted cbsw-booking-summary-step-subtitle">
        {{ templateSubTitle | translation: 'template_content': lang }}
      </div>
    </div>

    <!-- Cart Items Starts -->

    <ul class="list-group mb-3 mt-3">
      <li
        *ngFor="let cartItem of bookingService.selectedBookingData.cartItems; let index = index;"
        class="list-group-item d-flex justify-content-between lh-condensed"
      >
        <div>
          <div class="my-0 list-item-title"
              [innerHTML]="(cartItem?.service | translation: 'name' : lang) | calioSafe: 'html'"></div>
          <div class="text-muted description cbw-summary-date-time">{{ cartItem.date | date: 'dd.MM.y' }}
            , {{ cartItem.startTime | dateutc: "HH:mm" }} - {{
            cartItem.endTime | dateutc: "HH:mm" }}
            <!--{{ 'booking_components.hoursShort' | translate }}-->
          </div>
          <div class="description text-muted cbw-summary-resource-name">{{ cartItem?.worker?.resource_name }}</div>
          <ng-container *ngIf="cartItem.selectedMeetingType || bookingService.selectedBookingData.selectedUser?.meeting_type_id; else defaultMeetingTypeWithLocation">
            <ng-container
              *ngIf="(bookingService.widgetTemplates | calioMeetingTemplate: {
                id: cartItem.selectedMeetingType?.id || bookingService.selectedBookingData.selectedUser?.meeting_type_id,
                type: 'meeting_template'
              }) as widgetTemplate;"
            >
              <div
                class="description text-muted cbw-summary-meeting-type"
                [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content' : lang) | calioSafe: 'html'"
              ></div>
            </ng-container>
            <ng-container
              *ngIf="cartItem.selectedMeetingType?.id === 1 || bookingService.selectedBookingData.selectedUser?.meeting_type_id === 1"
              [ngTemplateOutlet]="inStoreMeetingTypeLocation"
            ></ng-container>
          </ng-container>

          <ng-template #defaultMeetingTypeWithLocation>
            <ng-container
              *ngIf="(bookingService.widgetTemplates | calioMeetingTemplate: {
                id: 1,
                type: 'meeting_template'
              }) as widgetTemplate;"
            >
              <div
                class="description text-muted cbw-summary-meeting-type"
                [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content' : lang) | calioSafe: 'html'"
              ></div>
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="inStoreMeetingTypeLocation"
            ></ng-container>
          </ng-template>

          <ng-template #inStoreMeetingTypeLocation>
            <div class="cbw-summary-worker-store-location">
              <span class="cbw-summary-worker-store-location-name">{{ cartItem.store | translation: 'name' : lang }}</span>
              <ng-container *ngIf="cartItem.store?.is_online === 0">
                <span class="cbw-summary-worker-store-location-separator">,&nbsp;</span>
                <span class="cbw-summary-worker-store-location-address">
                  <ng-container *ngIf="cartItem.store.street">{{ cartItem.store.street }},&nbsp;</ng-container>
                  <ng-container *ngIf="cartItem.store.zip">{{ cartItem.store.zip }}&nbsp;</ng-container>
                  <ng-container *ngIf="cartItem.store.city">{{ cartItem.store.city }}</ng-container>
                  <ng-container *ngIf="cartItem.store.country">,&nbsp;{{ cartItem.store.country | translation: 'name' : lang }}</ng-container>
                </span>
              </ng-container>
            </div>
          </ng-template>
        </div>
        <div class="d-flex flex-column align-items-end justify-content-between">
          <div class="text-muted">
            <ng-container *ngIf="cartItem?.service?.price === 0">
              {{ 'booking_components.free' | translate }}
            </ng-container>
            <ng-container *ngIf="cartItem?.service?.price === -1">
              &nbsp;
            </ng-container>
            <ng-container *ngIf="cartItem?.service?.price > 0">
              {{ cartItem.service.price | calioCurrency: bookingService.partnerData['currency'] }}
            </ng-container>
          </div>
          <div class="text-danger remove-button" (click)="removeCartItem(index)">
            {{ 'booking_components.remove' | translate }}
          </div>
        </div>

      </li>

      <li *ngIf="subTotal > 0" class="list-group-item d-flex justify-content-between lh-condensed">
        <div>
          <div class="my-0 list-item-title"> {{ 'booking_components.subtotal' | translate }}</div>
        </div>
        <div>
        <span class="text-muted">
          <ng-container *ngIf="subTotal === 0">
            {{ 'booking_components.free' | translate }}
          </ng-container>
          <ng-container *ngIf="subTotal === -1">
            &nbsp;
          </ng-container>
          <ng-container *ngIf="subTotal > 0">
            {{ subTotal | calioCurrency: bookingService.partnerData['currency'] }}
          </ng-container>
        </span>
        </div>
      </li>

      <li *ngIf="showCoupon" class="list-group-item d-flex justify-content-between bg-light">
        <div class="text-success">
          <div class="my-0 list-item-title">
            {{ 'coupon_credit_card_info.coupon' | translate }}
          </div>
          <div class="description">
            {{ couponCode }}
          </div>
        </div>
        <div class="d-flex flex-column align-items-end justify-content-between">
          <div class="text-success">
            -{{ finalCouponDiscount | calioCurrency: bookingService.partnerData['currency'] }}
          </div>
          <div class="text-danger remove-button" (click)="removeCoupon()">
            {{ 'booking_components.remove' | translate }}
          </div>
        </div>
      </li>

      <li *ngIf="totalAmount > 0" class="list-group-item d-flex justify-content-between">
        <div>
          <div class="my-0 list-item-title">{{ 'booking_components.total' | translate }}</div>
        </div>
        <strong>
          {{ totalAmount | calioCurrency: bookingService.partnerData['currency'] }}
        </strong>
      </li>
    </ul>

    <app-payment-method-view
      (previousPageEvent)="previous()"
      (onAdditionalServiceEvent)="onAdditionalService()">
    </app-payment-method-view>
  </ng-container>

  <ng-container *ngIf="bookingService.selectedBookingData?.cartItems?.length === 0">
    <div class="alert alert-danger">
      <div>
        {{ 'booking_components.emptyCartWarning' | translate }}
      </div>
    </div>

    <div *ngIf="bookingService.partnerData.is_cart_supported === 1" class="d-flex justify-content-center mt-4 mb-4">
      <div>
        <app-button
          [id]="'booking-summary-additional-service-btn'"
          (clickEvent)="onAdditionalService()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color">
          {{ 'booking_components.add_service' | translate }}
        </app-button>
      </div>
    </div>

  </ng-container>
</div>
