import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {CustomEventService} from '../../services/feature/custom-event.service';
import {CouponDbModel} from '../../models/coupon-db.model';
import {BookingService} from '../../services/feature/booking.service';

@Component({
  selector: 'app-coupon-form',
  templateUrl: './coupon-form.component.html',
  styleUrls: ['./coupon-form.component.scss']
})
export class CouponFormComponent implements OnInit {

  coupon: string;
  showError: boolean;
  showSuccessMsg: boolean;

  constructor(
    private customEventService: CustomEventService,
    private bookingService: BookingService,
  ) {
  }

  ngOnInit(): void {
    this.customEventService.removeCouponEvent.subscribe(
      (result: any) => {
        this.showError = false;
        this.showSuccessMsg = false;
        this.coupon = undefined;
      }
    );
  }

  onApplyCoupon(): void {
    this.showError = false;
    this.showSuccessMsg = false;
    this.customEventService.couponFailedEvent.emit({disableBookingButton: false, invalidCoupon: true});
    // this.couponFailedEvent.emit();
    if (!this.coupon || this.coupon.trim() === '') {
      // this.customEventService.resetPayPalOrderDetailEvent.emit();
      return;
    }
    this.bookingService.applyCoupon(this.coupon).subscribe(
      (result: CouponDbModel) => {
        // LoggerService.log('result is ', result);
        if (result.errors && result.errors.length === 1 && Number(result.errors[0].code) === 404) {
          this.showError = true;
          this.customEventService.couponFailedEvent.emit({
            disableBookingButton: true,
            invalidCoupon: true
          });
          // this.customEventService.resetPayPalOrderDetailEvent.emit();
        } else if (result.errors && result.errors.length === 1 && Number(result.errors[0].code) === 400) {
          this.showError = true;
          this.customEventService.couponFailedEvent.emit({
            disableBookingButton: true, invalidCoupon: true
          });
          // this.customEventService.resetPayPalOrderDetailEvent.emit();
        } else if (result.errors && result.errors.length === 1 && Number(result.errors[0].code) === 401) {
          this.showError = true;
          this.customEventService.couponFailedEvent.emit({disableBookingButton: true, invalidCoupon: true});
          // this.customEventService.resetPayPalOrderDetailEvent.emit();
        } else {
          this.showSuccessMsg = true;
          this.customEventService.couponSuccessEvent.emit(result);
        }
      },
      (error: HttpErrorResponse) => {
        this.showError = true;
        this.customEventService.couponFailedEvent.emit({disableBookingButton: false, invalidCoupon: true});
        // LoggerService.log('error is ', error);
      }
    );
  }
}
