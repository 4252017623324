import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {WidgetService} from '../../../../services/feature/widget.service';

@Component({
  selector: 'app-survey-final-step',
  templateUrl: './survey-final-step.component.html',
  styleUrls: ['./survey-final-step.component.scss']
})
export class SurveyFinalStepComponent implements OnInit {

  isBookAppointmentCardSelected: boolean = Boolean(false);
  isEndProcessCardSelected: boolean = Boolean(false);

  @Output() endProcessEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() bookAppointmentEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public widgetService: WidgetService,
  ) {
  }

  ngOnInit(): void {
  }

  onEndProcessCardClick(): void {
    this.isEndProcessCardSelected = true;
    this.isBookAppointmentCardSelected = false;
    this.endProcessEvent.emit();
  }

  onBookAppointmentCardClick(): void {
    this.isEndProcessCardSelected = false;
    this.isBookAppointmentCardSelected = true;
    this.bookAppointmentEvent.emit();
  }

}
