import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CUSTOM_FIELD_TYPES } from '../../../../constants/app.constants';
import { CustomFieldModel, CustomFieldOptionModel } from '../../../../models/custom-field.model';
import { SurveyStepModel } from '../../../../models/survey-step.model';
import { CustomFieldsService } from '../../../../services/feature/custom-fields.service';
import { SurveyService } from '../../../../services/feature/survey.service';
import { HelperService } from '../../../../services/utils/helper.service';
import { LoggerService } from '../../../../services/utils/logger.service';

@Component({
  selector: 'app-survey-question-view',
  templateUrl: './survey-question-view.component.html',
  styleUrls: ['./survey-question-view.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SurveyQuestionViewComponent implements OnInit {

  @Input() isFirst: boolean;
  @Input() currentIndex: number;
  @Input() prefix: string;
  @Input() widgetQuestion: CustomFieldModel;
  @Input() currentSurveyStep: SurveyStepModel;
  @Input() surveyGlobalStep: number;

  dependenciesGhostElements = [1, 2, 3];
  showDependenciesGhostElement = false;
  hiddenBookingQuestions: CustomFieldModel[] = [];

  constructor(
    private customFieldsService: CustomFieldsService,
    public helperService: HelperService,
    private surveyService: SurveyService
  ) {
  }

  ngOnInit(): void {
    this.prefillCustomFieldValues(this.widgetQuestion);
  }

  prefillCustomFieldValues(widgetQuestion: CustomFieldModel): void {
    if (this.surveyService.customerDetail?.custom_fields?.length > 0) {
      const filteredCustomFields = this.surveyService.customerDetail?.custom_fields.filter((customerCustomField) => {
        return customerCustomField.custom_field.id === widgetQuestion.id;
      });
      if (filteredCustomFields?.length > 0) {
        this.customFieldsService.prefillCustomFieldSelectedValue(widgetQuestion, filteredCustomFields);
        this.surveyService.calculateSurveyProgressBarCount(false);
        LoggerService.log('Prefilled widgetQuestion customer selected values ', widgetQuestion.customerSelectedValue);
      } else {
        this.customFieldsService.prefillCustomFieldSelectedValue(widgetQuestion, []);
        LoggerService.log(
          'Filtered widgetQuestion customer selected values are not avaialble => widgetQuestion.customerSelectedValue',
          widgetQuestion.customerSelectedValue
        );
      }
    } else {
      LoggerService.log('Customer custom fields are not available');
      this.customFieldsService.prefillCustomFieldSelectedValue(widgetQuestion, []);
    }
  }

  getCustomFieldDependencies(
    customFieldData: CustomFieldModel,
    cfDependencies: number[],
  ): void {
    this.showDependenciesGhostElement = true;
    this.customFieldsService.getCustomFieldsByIds(cfDependencies).subscribe({
      next: (result: CustomFieldModel[]) => {
        this.showDependenciesGhostElement = false;
        if (result && result.length > 0) {
          for (const customField of result) {
            if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT
              || customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
              if (customField.alphabetically_sorted === 1) {
                customField.custom_field_options = this.helperService
                  .sortByNumber(customField.custom_field_options, 'label');
              } else {
                customField.custom_field_options = this.helperService
                  .sortByNumber(customField.custom_field_options, 'position');
              }
            }
          }
          for (const cf of result) {
            if (cf.is_hidden === 1) {
              this.hiddenBookingQuestions.push(cf);
            }
          }

          result = result.filter((item: CustomFieldModel) => {
            return item.is_hidden === 0;
          });

          customFieldData.customFieldDependencies = result;

          this.surveyService.handleHiddenBookingQuestions(
            this.hiddenBookingQuestions,
            this.surveyService.customerDetail
          );

        } else {
          // Handle this case if dependencies are undefined
        }
        if (customFieldData?.customFieldDependencies?.length > 0) {
          for (const customFieldDependency of customFieldData?.customFieldDependencies) {
            LoggerService.warn('Loading preselected custom field dependency ', customFieldDependency.id);
            this.prefillCustomFieldValues(customFieldDependency);
          }

          this.entityNamesPrefill(customFieldData);
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.log('Error while fetching custom fields');
        LoggerService.error(error);
        this.showDependenciesGhostElement = false;
      }
    });
  }

  entityNamesPrefill(customFieldData: CustomFieldModel): void {
    if (this.surveyService.customerDetail?.custom_fields) {
      let filteredEntityNames = this.surveyService.customerDetail?.custom_fields?.filter((customerCustomField) => {
        return customerCustomField.entity_name !== null && customerCustomField.belongs_to_column !== null;
      });
      if (filteredEntityNames.length) {
        filteredEntityNames = this.helperService.sortByNumber(filteredEntityNames, 'id');
        for (const names of filteredEntityNames) {
          customFieldData.entity_names[names.belongs_to_column - 1] = names.entity_name;
        }
      }
    }
  }

  onRadioButtonChangeEvent(event: {
    customField: CustomFieldModel,
    customFieldOption: CustomFieldOptionModel
    oldCustomFieldDependencyIds: number[],
    removeOldCustomFieldDependencyIds: boolean,
    entityIndex?: number
  }): void {
    LoggerService.log('event?.customFieldOption?.dependencies ', event?.customFieldOption?.dependencies);
    if (event?.customFieldOption?.dependencies && event?.customFieldOption?.dependencies?.trim() !== '') {
      const cfDependencies: number[] = event.customFieldOption.dependencies.split(',').map((i: string) => Number(i));
      this.getCustomFieldDependencies(
        event.customField,
        cfDependencies
      );
    }
  }

  onChangeCustomFieldSelectedValueEvent(
    enableProgressBar: boolean,
    widgetQuestion: CustomFieldModel,
    eventData: { [key: string]: any }
  ): void {
    let loadOptionDependencies = false;
    LoggerService.log('widgetQuestion ', widgetQuestion);
    if (enableProgressBar) {
      this.surveyService.calculateSurveyProgressBarCount(false);
    }

    LoggerService.log('before processCustomFieldSelectedValue');
    this.surveyService.processCustomFieldSelectedValue(
      widgetQuestion,
      this.surveyService.customerDetail,
      eventData
    );

    if (eventData?.checkForOptionsDependentQuestions === true) {
      if (widgetQuestion.optionDependentQuestionIds === undefined) {
        widgetQuestion.optionDependentQuestionIds = [];
      }

      for (let index = 0; index < widgetQuestion.customerSelectedValue.length; index++) {
        widgetQuestion.optionDependentQuestionIds[index] = [];
        if (widgetQuestion.customerSelectedValue[index]) {
          widgetQuestion.custom_field_options
            .filter((option: CustomFieldOptionModel) => {
              return widgetQuestion.customerSelectedValue[index].includes(option.id);
            })
            .forEach((option: CustomFieldOptionModel) => {
              if (option.dependencies) {
                loadOptionDependencies = true;
                const ids = option.dependencies.split(',').map((id: string) => parseInt(id));
                widgetQuestion.optionDependentQuestionIds[index].push(...ids);
              }
            });
        }
      }

      if (loadOptionDependencies) {
        widgetQuestion.loadOptionDependencies = false;
        this.getOptionsCustomFieldDependencies(widgetQuestion);
      }
    }
  }

  getOptionsCustomFieldDependencies(widgetQuestion: CustomFieldModel): void {
    let questionIds = widgetQuestion.optionDependentQuestionIds.flat();
    questionIds = [...new Set(questionIds)];
    this.customFieldsService.getCustomFieldsByIds(questionIds).subscribe({
      next: (customFields: CustomFieldModel[]) => {
        if (customFields && customFields.length > 0) {
          for (const customField of customFields) {
            if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT || customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
              if (customField.alphabetically_sorted === 1) {
                customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'label');
              } else {
                customField.custom_field_options = this.helperService.sortByNumber(customField.custom_field_options, 'position');
              }
            }

            if (customField.is_hidden === 1) {
              this.hiddenBookingQuestions.push(customField);
            }
          }

          customFields = customFields.filter((item: CustomFieldModel) => item.is_hidden === 0);

          // widgetQuestion.optionDependentQuestions = Array(widgetQuestion.optionDependentQuestionIds.length).fill(customFields);

          widgetQuestion.optionDependentQuestions = [];
          for (let index = 0; index < widgetQuestion.optionDependentQuestionIds.length; index++) {
            widgetQuestion.optionDependentQuestions[index] = JSON.parse(JSON.stringify(customFields));
          }

          this.surveyService.handleHiddenBookingQuestions(
            this.hiddenBookingQuestions,
            this.surveyService.customerDetail
          );
        }

        widgetQuestion.loadOptionDependencies = true;

        setTimeout(() => {
          for (let index = 0; index < widgetQuestion.optionDependentQuestionIds.length; index++) {
            const questions = widgetQuestion.optionDependentQuestions[index] as CustomFieldModel[];
            if (questions) {
              for (const customFieldDependency of questions) {
                if (
                  widgetQuestion?.optionDependentQuestionIds[index] &&
                  widgetQuestion?.optionDependentQuestionIds[index].includes(customFieldDependency.id)
                ) {
                  customFieldDependency.is_read_only = 0;
                  LoggerService.warn('Loading preselected custom field dependency ', customFieldDependency.id);
                  this.prefillCustomFieldValues(customFieldDependency);
                } else {
                  customFieldDependency.is_read_only = 1;
                }
              }
            }
          }
        }, 500);

      },
      error: (error: HttpErrorResponse) => {
        LoggerService.log('Error while fetching option\'s custom fields');
        LoggerService.error(error);
      }
    });
  }

  onBlurCustomFieldSelectedValueEvent(
    eventData: { [key: string]: any }
  ): void {
    this.surveyService.processBlurCustomFieldSelectedValue(
      this.widgetQuestion,
      this.surveyService.customerDetail,
      eventData
    );
  }
}
