import {NgModule} from '@angular/core';
import {CswTextComponent} from './csw-text/csw-text.component';
import {CswLinkComponent} from './csw-link/csw-link.component';
import {CswClampTextComponent} from './csw-clamp-text/csw-clamp-text.component';
import {CommonModule} from '@angular/common';


@NgModule({
  declarations: [CswTextComponent, CswLinkComponent, CswClampTextComponent],
  imports: [
    CommonModule,
  ],
  exports: [
    CswTextComponent, CswLinkComponent, CswClampTextComponent
  ]
})
export class TypographyModule {
}
