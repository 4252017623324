import { HttpErrorResponse, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { IS_CALENSO_AUTH_REQUIRED } from '../constants/http-context';
import { AuthTokenService } from '../services/utils/auth-token.service';
import { LoggerService } from '../services/utils/logger.service';

@Injectable({
  providedIn: 'root'
})
export class NoopHttpInterceptorService {

  constructor(
    private authTokenService: AuthTokenService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      filter((event): event is HttpResponse<any> => event instanceof HttpResponse),
      map(event => {
        const body = event.body;
        if (body && body.errors && body.errors[0]) {
          throw new HttpErrorResponse({
            error: new Error(body.errors[0].message),
            statusText: body.errors[0].message,
            status: body.errors[0].code
          });
        } else if (body && body.errors) {
          if (body.errors.code === 'malware') {
            throw new HttpErrorResponse({
              error: new Error(body.errors.message),
              statusText: body.errors.code,
              status: 400
            });
          } else if (body.errors.code === 'file-size') {
            throw new HttpErrorResponse({
              error: new Error(body.errors.message),
              statusText: body.errors.code,
              status: 400
            });
          }
        } else if (body && body.error) {
          if (body.message) {
            throw new HttpErrorResponse({
              error: new Error(body.message),
              statusText: body.message,
              status: body.status
            });
          }
        } else {
          if (request.context.has(IS_CALENSO_AUTH_REQUIRED)) {
            const authToken = event?.body?.token;
            authToken && this.authTokenService.setToken(authToken);
          }

          return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        LoggerService.error(error);
        if (error.status === 401) {
          LoggerService.warn('401 Error');
        }
        if (error.status === 403) {
          LoggerService.warn('403 Error');
        }
        throw error;
      })
    );
  }
}
