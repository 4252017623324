import {NgModule} from '@angular/core';
import {WidgetLayoutComponent} from './widget-layout/widget-layout.component';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  declarations: [WidgetLayoutComponent],
  imports: [
    SharedModule,
  ],
  exports: [
    WidgetLayoutComponent
  ]
})
export class LayoutModule {
}
