<div *ngIf="showBookingQuestions">
  <app-info-card
    *ngIf="(widgetService.widgetConf | translation: 'lead_intro_text': lang)?.trim() !== ''"
    [showBody]="true"
  >
    <div cardBody>
      <app-csw-text [text]="(widgetService.widgetConf | translation: 'lead_intro_text': lang)"></app-csw-text>
    </div>
  </app-info-card>

  <div class="mt-4">
    <ng-container
      *ngFor="let widgetQuestion of widgetQuestions;
        let index = index;
        let isFirst = first;
        let isLast = last;"
    >
      <form #bookingQuestionForm="ngForm">
        <ng-container *ngIf="activeQuestion === index">
          <app-booking-question
            [customField]="widgetQuestion"
            (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
            [isNextButtonPressed]="isNextButtonPressed"
          >
          </app-booking-question>

          <ng-container *ngIf="widgetQuestion?.customFieldDependencies?.length > 0">
            <ng-container
              *ngFor="let dependencyCustomField of widgetQuestion?.customFieldDependencies;
              let childIndex = index;
              let childIsFirst = first;
              let childIsLast = last;"
            >
              <app-booking-question
                [customField]="dependencyCustomField"
                (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
                [isNextButtonPressed]="isNextButtonPressed"
              >
              </app-booking-question>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="showDependenciesGhostElement">
            <div class="skeleton-box-card mt-4">
              <div class="">
                <div class="row">
                  <div class="col-md-12 mb-4"
                       *ngFor="let dependenciesGhostElement of dependenciesGhostElements">
                    <div class="d-flex stats-card shadow">
                      <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
                        &lt;!&ndash;<div class="bar bar-1"></div>
                        <div class="bar bar-2"></div>&ndash;&gt;
                        <div class="">
                          <div class="stat-box-1"></div>
                          <div class="stat-box mt-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div *ngIf="!showDependenciesGhostElement" class="d-flex justify-content-end mt-4">
            <ng-container *ngIf="widgetQuestion?.is_end_of_process !== 1  && !this.isEndOfProcessByChildDependencies">
              <div class="me-2">
                <app-button
                  [id]="'booking-questions-previous-btn'"
                  (clickEvent)="previousQuestion()"
                  [isDisabled]="isFirst"
                  [fontColor]="widgetService?.widgetConf?.button_font_color"
                  [backgroundColor]="widgetService?.widgetConf?.button_background_color"
                >
                  {{ 'form_fields.previous' | translate }}
                </app-button>
              </div>
              <div>
                <app-button
                  [id]="'booking-questions-next-btn'"
                  *ngIf="!isLast"
                  (clickEvent)="nextQuestion()"
                  [isDisabled]="bookingQuestionForm.invalid"
                  [fontColor]="widgetService?.widgetConf?.button_font_color"
                  [backgroundColor]="widgetService?.widgetConf?.button_background_color">
                  {{ 'form_fields.next' | translate }}
                </app-button>

                <app-button
                  [id]="'booking-questions-next-btn'"
                  *ngIf="isLast"
                  (clickEvent)="finishLastBookingQuestion()"
                  [isDisabled]="bookingQuestionForm.invalid"
                  [fontColor]="widgetService?.widgetConf?.button_font_color"
                  [backgroundColor]="widgetService?.widgetConf?.button_background_color">
                  {{ 'form_fields.next' | translate }}
                </app-button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </form>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="showWrokerForm && workers?.length > 0">
  <div>
    <app-lead-workers-selection
      [workers]="workers"
      (previousPageEvent)="gotoLastQuestion()"
      (nextPageEvent)="gotoPersonalForm()"
    />
  </div>
</ng-container>
<ng-container *ngIf="showPersonalForm">
  <div>
    <div *ngIf="(widgetService.widgetConf | translation: 'lead_outro_text': lang)?.trim() !== ''">
      <app-info-card [showBody]="true">
        <div cardBody>
          <app-csw-text [text]="(widgetService.widgetConf | translation: 'lead_outro_text': lang)"></app-csw-text>
        </div>
      </app-info-card>
    </div>
    <div class="mt-4">
      <app-personal-form
        [customerRequiredFields]="customerRequiredFields"
        [userInfo]="userInfo"
        [customerFormFields]="customerFormFields"
        [meetingTypes]="meetingTypes"
        (submitEvent)="onSubmitEvent($event)"
        (previousEvent)="gobackToStepFromPersonalForm()"
      />
    </div>
  </div>
</ng-container>
