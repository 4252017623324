<div class="stripe-integration">
  <form #checkout="ngForm" (ngSubmit)="onSubmit()" class="checkout">
    <div class="row stripe-row">
      <!-- <div class="col-md-12 mb-2">
        <span class="stripe-title"> {{ 'stripe.stripe_title' | translate }} </span>
      </div> -->
      <div class="col-lg-12">
        <!-- <label for="card-info">{{ 'coupon_credit_card_info.credit_or_debit_card' | translate }}</label> -->
        <div id="card-info" #cardInfo></div>
      </div>
      <!-- <div class="col-md-6 button-box">
        <button type="submit">{{ 'stripe.submit_payment' | translate }}</button>
      </div> -->

      <div id="card-errors" class="card-errors col-md-12" role="alert" *ngIf="error">{{ error }}</div>
    </div>
  </form>
</div>