<ng-container *ngIf="surveyService?.bookingData?.length > 0">
  <ng-container
    *ngTemplateOutlet="
      bookingsAvailableTemplate;
      context: {
        bookings: surveyService.bookingData
      }
    "
  >
  </ng-container>
</ng-container>

<ng-container
  *ngIf="
    !surveyService?.bookingData || surveyService?.bookingData?.length === 0
  "
>
  <ng-container *ngTemplateOutlet="bookingsNotAvailableTemplate">
  </ng-container>
</ng-container>

<ng-template #bookingsNotAvailableTemplate>
  <div class="row">
    <div class="col-md-12">
      <app-csw-text
        [text]="
          widgetService?.widgetConf
            | translation: 'end_text':lang
            | calioSafe: 'html'
        "
        [styleConfig]="{}"
      >
      </app-csw-text>

      <div class="mt-5">
        <button
          type="button"
          class="btn btn-primary"
          [ngStyle]="{
            background: widgetService?.widgetConf?.button_background_color,
            color: widgetService?.widgetConf?.button_font_color,
            borderColor: widgetService?.widgetConf?.button_background_color
          }"
          (click)="exportSurveySummary(bookingData)"
        >
          {{ "survey_component.download_summary" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #bookingsAvailableTemplate let-bookings="bookings">
  <div class="row" *ngFor="let bookingData of bookings">
    <div class="col-md-8">
      <app-csw-text
        [text]="
          widgetService?.widgetConf
            | translation: 'end_text':lang
            | calioSafe: 'html'
        "
        [styleConfig]="{}"
      >
      </app-csw-text>
    </div>
    <div *ngIf="bookingData" class="col-md-4 appointment-detail-section">
      <div class="section-heading">
        {{ "survey_component.appointment_details" | translate }}
      </div>

      <div class="d-flex mb-2">
        <div class="">
          <mat-icon
            class="final-step-icon"
            svgIcon="calendar-regular"
          ></mat-icon>
        </div>
        <div class="final-step-value ps-3">
          {{ bookingData?.start | date: "dd. MMMM yyyy":"UTC" }}
        </div>
      </div>

      <div class="d-flex mb-2">
        <div>
          <mat-icon class="final-step-icon" svgIcon="clock-regular"></mat-icon>
        </div>
        <div class="final-step-value ps-3">
          {{ bookingData?.start | date: "HH:mm":"UTC" }}
          - {{ bookingData?.end | date: "HH:mm":"UTC" }}
        </div>
      </div>

      <div class="d-flex mb-2">
        <div>
          <mat-icon
            class="final-step-icon"
            svgIcon="map-marker-alt-solid"
          ></mat-icon>
        </div>
        <div class="final-step-value ps-3">
          <ng-container *ngIf="bookingData?.meeting_type_id === 1">
            <span class="value">
              {{ bookingData?.location }}
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 2">
            <span class="value">
              <a [href]="bookingData?.location" target="_blank">
                <div class="d-flex align-items-center">
                  <div class="pe-1">
                    <img
                      src="/assets/images/meetings/zoom.svg"
                      class="meeting_type_id_2"
                      alt="Zoom"
                      width="16"
                    />
                  </div>
                  <div>
                    Online Zoom Meeting
                    <i class="b-fa b-fa-external-link-alt"></i>
                  </div>
                </div>
              </a>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 3">
            <span class="value">
              <a [href]="bookingData?.location" target="_blank">
                <div class="d-flex align-items-center">
                  <div class="pe-1">
                    <img
                      src="/assets/images/meetings/calenso.svg"
                      class="meeting_type_id_3"
                      alt="Calenso"
                      width="16"
                    />
                  </div>
                  <div>
                    Online-Meeting <i class="b-fa b-fa-external-link-alt"></i>
                  </div>
                </div>
              </a>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 4">
            <span class="value">
              <a href="tel:{{ bookingData?.location }}">
                <div class="d-flex align-items-center">
                  <div class="pe-1">
                    <img
                      src="/assets/images/meetings/phone.svg"
                      class="meeting_type_id_4"
                      alt="Phone"
                      width="16"
                    />
                  </div>
                  <div>{{ bookingData?.location }}</div>
                </div>
              </a>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 5">
            <span class="value">
              <div class="d-flex align-items-center">
                <div class="pe-1">
                  <img
                    src="/assets/images/meetings/customer.svg"
                    class="meeting_type_id_5"
                    alt="Customer"
                    width="16"
                  />
                </div>
                <div>{{ bookingData?.location }}</div>
              </div>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 6">
            <span class="value">
              <div class="d-flex align-items-center">
                <div class="pe-1">
                  <img
                    src="/assets/images/meetings/unblu.svg"
                    class="meeting_type_id_6"
                    alt="Unblu"
                    width="16"
                  />
                </div>
                <div>
                  {{ "appointment_detail_component.join_unblu" | translate }}
                </div>
              </div>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 7">
            <span class="value">
              <a [href]="bookingData?.location" target="_blank">
                <div class="d-flex align-items-center">
                  <div class="pe-1">
                    <img
                      src="/assets/images/meetings/teams.svg"
                      class="meeting_type_id_7"
                      alt="Microsoft Teams"
                      width="16"
                    />
                  </div>
                  <div>
                    Microsoft Teams <i class="b-fa b-fa-external-link-alt"></i>
                  </div>
                </div>
              </a>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 8">
            <span class="value">
              <a [href]="bookingData?.location" target="_blank">
                <div class="d-flex align-items-center">
                  <div class="pe-1">
                    <img
                      src="/assets/images/meetings/meet.svg"
                      class="meeting_type_id_8"
                      alt="Google Meet"
                      width="16"
                    />
                  </div>
                  <div>
                    Google Meet <i class="b-fa b-fa-external-link-alt"></i>
                  </div>
                </div>
              </a>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 9">
            <span class="value">
              <a [href]="bookingData?.location" target="_blank">
                <div class="d-flex align-items-center">
                  <div class="pe-1">
                    <img
                      src="/assets/images/meetings/gotomeeting.png"
                      class="meeting_type_id_9"
                      alt="GoTo Meeting"
                      width="16"
                    />
                  </div>
                  <div>
                    GoTo Meeting <i class="b-fa b-fa-external-link-alt"></i>
                  </div>
                </div>
              </a>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 10">
            <span class="value">
              <a [href]="bookingData?.location" target="_blank">
                <div class="d-flex align-items-center">
                  <div class="pe-1">
                    <img
                      src="/assets/images/meetings/webex.png"
                      class="meeting_type_id_10"
                      alt="Cisco Webex"
                      width="16"
                    />
                  </div>
                  <div>
                    Cisco Webex <i class="b-fa b-fa-external-link-alt"></i>
                  </div>
                </div>
              </a>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 11">
            <span class="value">
              <a href="tel:{{ bookingData?.location }}">
                <div class="d-flex align-items-center">
                  <div class="pe-1">
                    <img
                      src="/assets/images/meetings/phone.svg"
                      class="meeting_type_id_11"
                      alt="Phone"
                      width="16"
                    />
                  </div>
                  <div>{{ bookingData?.location }}</div>
                </div>
              </a>
            </span>
          </ng-container>

          <ng-container *ngIf="bookingData?.meeting_type_id === 13">
            <span class="value">
              <a href="tel:{{ bookingData?.location }}">
                <div class="d-flex align-items-center">
                  <div class="pe-1">
                    <img
                      src="/assets/images/meetings/metaverse.svg"
                      class="meeting_type_id_11"
                      alt="Metaverse"
                      width="16"
                    />
                  </div>
                  <ng-container
                    *ngIf="(bookingService.widgetTemplates | calioMeetingTemplate: {
                      id: 13,
                      type: 'meeting_template'
                    }) as widgetTemplate"
                  >
                    <div class="ps-2">
                      <div [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content': lang) | calioSafe: 'html'"></div>
                    </div>
                  </ng-container>
                </div>
              </a>
            </span>
          </ng-container>
        </div>
      </div>

      <div class="mt-3">
        <button
          type="button"
          class="btn btn-primary"
          [ngStyle]="{
            background: widgetService?.widgetConf?.button_background_color,
            color: widgetService?.widgetConf?.button_font_color,
            borderColor: widgetService?.widgetConf?.button_background_color
          }"
          (click)="exportSurveySummary(bookingData)"
        >
          {{ "survey_component.download_summary" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
