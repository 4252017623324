<div class="personal-form-view cbsw-personal-form-step">
  <app-personal-form
    [customerRequiredFields]="customerRequiredFields"
    [showMeetingTypes]="showMeetingTypes"
    [selectedCustomFieldValues]="bookingService?.selectedBookingData?.selectedCustomFieldValues"
    [userInfo]="userInfo"
    [customerFormFields]="customerFormFields"
    [meetingTypes]="meetingTypes"
    (submitEvent)="onSubmitEvent($event)"
    (previousEvent)="gotoPreviousPage()"
    [partnerData]="partnerData"
  >
  </app-personal-form>
</div>
