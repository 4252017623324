import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StripeComponent} from './stripe.component';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    StripeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    StripeComponent
  ]
})
export class StripeModule {
}
