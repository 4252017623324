import {NgModule} from '@angular/core';
import {InfoCardComponent} from './info-card/info-card.component';
import {CommonModule} from '@angular/common';


@NgModule({
  declarations: [InfoCardComponent],
  imports: [
    CommonModule
  ],
  exports: [
    InfoCardComponent
  ]
})
export class CardsModule {
}
