<div class="d-flex justify-content-end mb-2">
  <button type="button" class="close" aria-label="Close" (keydown.enter)="$event.preventDefault()" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<ng-container *ngIf="youtubeUrl">
  <iframe
    width="500"
    height="315"
    src=""
    title="YouTube video player"
    [src]="youtubeUrl | calioSafe: 'resourceUrl'"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</ng-container>
