<form #workerForm="ngForm" *ngIf="workers?.length > 0">
  <div class="cbsw-workers-selection-step">
    <!-- Show this section if worker length is greater than 10 -->
    <div class="row" *ngIf="workersView === workersViews.DROPDOWN">
      <div class="col-md-12">
        <label>
          <app-csw-text
            [text]="widgetResourceHeadingTemplate | translation: 'template_content': lang"
            [styleConfig]="{ fontWeight: 600 }"
            [customClass]="'step-element-title'"
          >
          </app-csw-text>
        </label>
        <ng-select
          id="store"
          name="store"
          placeholder=""
          [multiple]="false"
          [(ngModel)]="selectedWorkerId"
          (change)="onWorkerChange($event)"
          [required]="true"
          #workerControl="ngModel"
          [ngClass]="{ 'is-invalid': workerControl.touched && workerControl.invalid }"
        >
          <ng-option [value]="0" *ngIf="widgetService?.widgetConf?.partner?.egal_wer_show === 1">
            <div class="d-flex align-items-center">
              <div class="avatar">
                <img
                  class="default-avatar rounded-circle"
                  src="{{ baseUrl }}/img/sample-avatar.png"
                />
              </div>
              <div class="description">
                <div class="cbw-title">
                  {{ widgetAnyoneLabelTemplate | translation: 'template_content': lang }}
                </div>
                <div class="cbw-function">
                  {{ widgetAnyoneDescTemplate | translation: 'template_content': lang }}
                </div>
              </div>
            </div>
          </ng-option>
          <ng-container *ngFor="let worker of workers">
            <ng-container *ngIf="worker.id != null">
              <ng-option [value]="worker.id">
                <!--{{ worker.resource_name }}-->
                <div class="d-flex align-items-center">
                  <div class="avatar">
                    <img
                      #image
                      *ngIf="worker.avatar !== null"
                      class="resource-avatar rounded-circle"
                      src="{{ workerAvatarBaseUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
                      (error)="image.src= baseUrl + '/img/sample-avatar.png'"
                    />
                    <img
                      *ngIf="worker.avatar === null"
                      class="default-avatar rounded-circle"
                      src="{{ baseUrl }}/img/sample-avatar.png"
                    />
                  </div>

                  <div class="description">
                    <div class="cbw-title">
                      <ng-container *ngIf="worker.is_resource === 0">
                        {{ worker.resource_name }}
                      </ng-container>
                      <ng-container *ngIf="worker.is_resource === 1">
                        <ng-container *ngIf="worker.is_multi_language === 1">
                          {{worker | translation: 'booking_label': lang }}
                        </ng-container>
                        <ng-container *ngIf="worker.is_multi_language === 0">
                          {{ worker.resource_name }}
                        </ng-container>
                      </ng-container>
                    </div>

                    <div *ngIf="worker.is_resource === 0" class="cbw-function">
                      <ng-container *ngIf="worker.is_multi_language === 1">
                        {{worker | translation: 'job_title': lang }}
                      </ng-container>
                      <ng-container *ngIf="worker.is_multi_language === 0">
                        {{ worker.job_title }}
                      </ng-container>

                    </div>

                    <div *ngIf="worker.is_resource !== 0" class="cbw-function">
                      <ng-container *ngIf="worker.is_multi_language === 1">
                        {{ worker | translation: 'description': lang }}
                      </ng-container>
                      <ng-container *ngIf="worker.is_multi_language === 0">
                        {{ worker.description }}
                      </ng-container>
                    </div>

                    <ng-container *ngIf="widgetShowResourceStoreName === 1">
                      <div class="cbw-worker-store-location">
                        <span class="cbw-worker-store-location-name">{{ worker.store | translation: 'name' : lang }}</span>
                        <ng-container *ngIf="worker.store.is_online === 0">
                          <span class="cbw-worker-store-location-separator">,&nbsp;</span>
                          <span class="cbw-worker-store-location-address">
                            <ng-container *ngIf="worker.store.street">{{ worker.store.street }},&nbsp;</ng-container>
                            <ng-container *ngIf="worker.store.zip">{{ worker.store.zip }}&nbsp;</ng-container>
                            <ng-container *ngIf="worker.store.city">{{ worker.store.city }}</ng-container>
                            <ng-container *ngIf="worker.store.country">,&nbsp;{{ worker.store.country | translation: 'name' : lang }}</ng-container>
                          </span>
                      </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-option>
            </ng-container>
          </ng-container>
        </ng-select>

        <div class="text-danger"
             *ngIf="workerControl.touched && !workerControl.valid">
          <div *ngIf="workerControl.errors.required">
            {{ 'error_messages.required_error_message' | translate }}
          </div>
        </div>
      </div>
    </div>

    <!-- Show this section if worker length is less than 10 -->
    <ng-container *ngIf="workersView === workersViews.CARD">
      <div>
        <app-csw-text
          [text]="widgetResourceHeadingTemplate | translation: 'template_content': lang"
          [styleConfig]="{ fontWeight: 600 }"
          [customClass]="'step-element-title'"
        >
        </app-csw-text>
      </div>

      <div class="row cbsw-workers-selection">
        <div
          *ngIf="workers?.length > 1 && widgetService?.widgetConf?.partner?.egal_wer_show === 1"
          class="worker-slot-card col-md-6 col-lg-4 mt-3"
          (click)="selectedWorkerId = 0; onWorkerChange($event)"
        >
          <app-info-card
            [showCardBorder]="true"
            [showShadow]="false"
            [showBody]="true"
            [isSelected]="selectedWorkerId === 0"
            [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
            [customCardClass]="(selectedWorkerId === worker?.id) ? 'cbsw-next-icon' : ''"
          >
            <div cardBody>
              <div class="d-flex align-items-center justify-content-between cbsw-workers-avatar-wrapper">
                <div class="d-flex align-items-center cbsw-workers-avatar-sub-wrapper">
                  <div class="avatar">
                    <img
                      class="default-avatar rounded-circle"
                      src="{{ baseUrl }}/img/sample-avatar.png"
                      height="200"
                    />
                  </div>
                  <div class="description">
                    <div class="cbw-title">
                      {{ widgetAnyoneLabelTemplate | translation: 'template_content': lang }}
                    </div>
                    <div class="cbw-function">
                      {{ widgetAnyoneDescTemplate | translation: 'template_content': lang }}
                    </div>
                  </div>
                </div>

                <div class="next-arrow-container">
                  <span
                    *ngIf="selectedWorkerId === 0"
                    (click)="next()"
                  >
                    <div class="next-button-wrapper">
                      <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </app-info-card>
        </div>

        <ng-container *ngFor="let worker of workers">
          <ng-container *ngIf="worker.id != null">
            <div
              class="worker-slot-card mt-3 col-md-6 col-lg-4"
              (click)="selectedWorkerId = worker.id; onWorkerChange($event)"
            >
              <app-info-card
                [showCardBorder]="true"
                [showShadow]="false"
                [showBody]="true"
                [isSelected]="selectedWorkerId === worker.id"
                [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
                [customCardClass]="(selectedWorkerId === worker?.id) ? 'cbsw-next-icon' : ''"
              >
                <div cardBody>
                  <div class="d-flex align-items-center justify-content-between cbsw-workers-avatar-wrapper">
                    <div class="d-flex align-items-center cbsw-workers-avatar-sub-wrapper">
                      <div class="avatar">
                        <img
                          #image
                          *ngIf="worker.avatar !== null"
                          class="resource-avatar rounded-circle"
                          src="{{ workerAvatarBaseUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
                          (error)="image.src= baseUrl + '/img/sample-avatar.png';image.classList.add('default-avatar-margin');"
                          height="200"
                        />
                        <img
                          *ngIf="worker.avatar === null"
                          class="default-avatar rounded-circle default-avatar-margin"
                          src="{{ baseUrl }}/img/sample-avatar.png"
                          height="200"
                        />
                      </div>

                      <div class="description">
                        <ng-container *ngIf="worker?.store?.name">
                          <div class="cbw-store-name">
                            <ng-container *ngIf="worker?.store?.is_multi_language === 1">
                              {{ worker?.store | translation: 'name': lang }}
                            </ng-container>
                            <ng-container *ngIf="worker?.store?.is_multi_language === 0">
                              {{ worker?.store?.name }}
                            </ng-container>
                          </div>
                        </ng-container>

                        <div class="cbw-title">
                          <ng-container *ngIf="worker.is_resource === 0">
                            {{ worker.resource_name }}
                          </ng-container>
                          <ng-container *ngIf="worker.is_resource === 1">
                            <ng-container *ngIf="worker.is_multi_language === 1">
                              {{worker | translation: 'booking_label': lang }}
                            </ng-container>
                            <ng-container *ngIf="worker.is_multi_language === 0">
                              {{ worker.resource_name }}
                            </ng-container>
                          </ng-container>
                        </div>

                        <div *ngIf="worker.is_resource === 0" class="cbw-function title">
                          <ng-container *ngIf="worker.is_multi_language === 1">
                            <span [innerHTML]="(worker | translation: 'job_title': lang) | calioSafe: 'html'"></span>
                          </ng-container>
                          <ng-container *ngIf="worker.is_multi_language === 0">
                            <span [innerHTML]="worker.job_title | calioSafe: 'html'"></span>
                          </ng-container>
                        </div>

                        <div *ngIf="worker.is_resource !== 0" class="cbw-function description">
                          <ng-container *ngIf="worker.is_multi_language === 1">
                            <span [innerHTML]="(worker | translation: 'description': lang) | calioSafe: 'html'"></span>
                          </ng-container>
                          <ng-container *ngIf="worker.is_multi_language === 0">
                            <span [innerHTML]="worker.description | calioSafe: 'html'"></span>
                          </ng-container>
                        </div>

                        <!-- Showing description for custom AMAG smart widget, By defaut it will be display:none-->
                        <ng-container *ngIf="worker?.store?.logo">
                          <div class="cbw-custom-store-logo">
                            <img
                              src="{{ storeBaseUrl }}{{ worker.store.uuid }}/{{ worker?.store?.logo }}"
                              height="40"
                            />
                          </div>
                        </ng-container>

                        <!-- Showing description for custom AMAG smart widget, By defaut it will be display:none-->
                        <ng-container *ngIf="worker?.description">
                          <div class="cbw-custom-description">
                            <ng-container *ngIf="worker.is_multi_language === 1">
                              <span [innerHTML]="(worker | translation: 'description': lang) | calioSafe: 'html'"></span>
                            </ng-container>
                            <ng-container *ngIf="worker.is_multi_language === 0">
                              <span [innerHTML]="worker.description | calioSafe: 'html'"></span>
                            </ng-container>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="widgetShowResourceStoreName === 1">
                          <div class="cbw-worker-store-location">
                            <span class="cbw-worker-store-location-name">{{ worker.store | translation: 'name' : lang }}</span>
                            <ng-container *ngIf="worker.store.is_online === 0">
                              <span class="cbw-worker-store-location-separator">,&nbsp;</span>
                              <span class="cbw-worker-store-location-address">
                                <ng-container *ngIf="worker.store.street">{{ worker.store.street }},&nbsp;</ng-container>
                                <ng-container *ngIf="worker.store.zip">{{ worker.store.zip }}&nbsp;</ng-container>
                                <ng-container *ngIf="worker.store.city">{{ worker.store.city }}</ng-container>
                                <ng-container *ngIf="worker.store.country">,&nbsp;{{ worker.store.country | translation: 'name' : lang }}</ng-container>
                              </span>
                          </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="next-arrow-container">
                      <span
                        *ngIf="selectedWorkerId === worker.id"
                        (click)="next()"
                      >
                        <div class="next-button-wrapper">
                          <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </app-info-card>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <div class="d-flex justify-content-end mt-4 mb-4 cbw-custom-navigation">
      <div class="me-2">
        <app-button
          [id]="'workers-selection-previous-btn'"
          (clickEvent)="previous()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
        <span class="custom-button-icon back"></span>
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
            id: 129, type: 'template'
          }) | translation: 'template_content': lang
        )}}
        </app-button>
      </div>

      <div>
        <app-button
          [id]="'workers-selection-next-btn'"
          (clickEvent)="next()"
          [isDisabled]="selectedWorkerId === null || selectedWorkerId === undefined"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
            id: 128, type: 'template'
          }) | translation: 'template_content': lang
        )}}
        <span class="custom-button-icon next"></span>
        </app-button>
      </div>
    </div>
  </div>
</form>
