import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';
import { LoggerService } from './app/services/utils/logger.service';
import { environment } from './environments/environment';
import { SentryConfig } from './sentry.config';

if (environment?.production) {
  enableProdMode();
  Sentry.init(SentryConfig);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(error => LoggerService.error(error));
