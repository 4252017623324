import * as Sentry from "@sentry/angular-ivy";
import { environment } from "./environments/environment";

export const SentryConfig: Sentry.BrowserOptions = {
  environment: "production",
  release: environment.version,
  dsn: "https://9d7bcbf13263939d931c5312023e152a@o4506308503273472.ingest.sentry.io/4506465916157952",
  ignoreErrors: [],
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.8,
};
