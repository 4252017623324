<div class="coupon-form-box">
  <form #couponForm="ngForm" class="coupon-form mb-0">
    <div class="">
      <!-- <div class="col-md-12 mb-2">
        <span class="stripe-title"> {{ 'coupon_credit_card_info.apply_coupon' | translate }}</span>
      </div> -->

      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'coupon_credit_card_info.coupon' | translate }}"
          [(ngModel)]="coupon"
          name="coupon"
          #couponModel="ngModel"
          id="coupon"
        >
        <button [disabled]="!coupon" type="submit" class="btn btn-secondary redeem-button" (click)="onApplyCoupon()">
            {{ 'coupon_credit_card_info.redeem_coupon' | translate }}
        </button>
      </div>
      <!--<div class="">
        <input [(ngModel)]="coupon" placeholder="{{ 'coupon_credit_card_info.coupon' | translate }}" name="coupon" #couponModel="ngModel" id="coupon"
          class="form-control cbw-input-field" (blur)="onApplyCoupon()">
      </div>-->
      <div id="coupon-errors" class="coupon-errors col-md-12 mt-1" role="alert" *ngIf="showError">
        {{ 'coupon_credit_card_info.invalid_coupon' | translate }}
      </div>
      <div id="coupon-success" class="coupon-success col-md-12 mt-1" role="alert" *ngIf="showSuccessMsg">
        {{ 'coupon_credit_card_info.applied_successfully' | translate }}
      </div>
    </div>
  </form>
</div>
