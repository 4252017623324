<button
  id="{{ id }}"
  type="button"
  class="btn btn-{{ type }} {{ customClass }}"
  (click)="onClick()"
  [disabled]="isDisabled"
  [ngStyle]="{
    background: backgroundColor,
    color: fontColor,
    borderColor: backgroundColor
  }"
>
  <ng-content></ng-content>
</button>
