<div class="mt-4" *ngIf="showGhostElement">
  <div class="skeleton-box-card">
    <div class="">
      <div class="row">

        <div class="col-md-12 mb-4"
             *ngFor="let ghostElement of slotGhostElements">
          <div class="d-flex stats-card shadow">
            <div
              class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
              <!--<div class="bar bar-1"></div>
              <div class="bar bar-2"></div>-->
              <div class="">
                <div class="stat-box-1"></div>
                <div class="stat-box mt-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<form #storeForm="ngForm" *ngIf="!showGhostElement">
  <!-- Store Card View Starts -->
  <ng-container
    *ngIf="stores?.length > 0
       && widgetService?.widgetConf?.partner?.enable_store_postcode_feature !== 1
       && widgetService?.widgetConf?.partner?.show_stores_as_dropdown !== 1
    "
  >
    <div class="row cbsw-store-selection-step">
      <div class="col-md-12">
        <div>
          <app-csw-text
            [customClass]="'step-element-title'"
            [text]="storeLabelTemplate | translation: 'template_content': lang"
            [styleConfig]="{ fontWeight: 600 }"
          >
          </app-csw-text>
        </div>
      </div>

      <ng-container *ngIf="stores?.length > 1 && bookingService?.partnerData?.widget_show_all_stores_card === 1">
        <div class="mt-3 col-md-6 cursor-pointer">
          <app-info-card
            [showCardBorder]="true"
            [showShadow]="false"
            [showBody]="true"
            [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
            [isSelected]="selectedStoreId === 0"
          >
            <div cardBody (click)="onStoreChange(0)">

              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div>
                    <app-csw-text
                      [text]="anyStoreLabelTemplate | translation: 'template_content': lang"
                      [styleConfig]="{ fontWeight: 600, fontSize: '13px' }"
                    >
                    </app-csw-text>
                  </div>
                </div>

                <div class="next-arrow-container">
                <span
                  *ngIf="selectedStoreId === 0"
                  (click)="next()">
                  <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                </span>
                </div>
              </div>
            </div>
          </app-info-card>
        </div>
      </ng-container>

      <div class="mt-3 col-md-6 cursor-pointer" *ngFor="let store of stores">
        <app-info-card
          [showCardBorder]="true"
          [showShadow]="false"
          [showBody]="true"
          [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
          [isSelected]="selectedStoreId === store.id"
        >
          <div cardBody (click)="onStoreChange(store.id)">

            <div class="d-flex align-items-center justify-content-between">
              <div>
                <div>
                  <app-csw-text
                    [text]="(store | translation: 'name': lang) | calioSafe: 'html'"
                    [styleConfig]="{ fontWeight: 600, fontSize: '13px' }"
                  >
                  </app-csw-text>
                </div>
                <div class="cbw-description" *ngIf="store.is_online === 0">
                  <span *ngIf="store.street">{{ store.street }}</span>
                  <span *ngIf="store?.zip">{{ ', ' + store?.zip }}</span>
                  <span *ngIf="store.city">{{ ' ' + store.city }}</span>
                  <span *ngIf="store?.country?.name">{{ ', ' + store?.country?.name }}</span>
                </div>
              </div>

              <div class="next-arrow-container">
                <span
                  *ngIf="selectedStoreId === store.id"
                  (click)="next()">
                  <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                </span>
              </div>
            </div>

          </div>
        </app-info-card>
      </div>

    </div>
  </ng-container>
  <!-- Store Card View Ends -->

  <!-- Stores dropdown view starts -->
  <ng-container
    *ngIf="stores?.length > 0
       && widgetService?.widgetConf?.partner?.enable_store_postcode_feature !== 1
       && widgetService?.widgetConf?.partner?.automatic_store_zip_search !== 1
       && widgetService?.widgetConf?.partner?.show_stores_as_dropdown === 1
    "
  >
    <div class="row">
      <div class="col-md-12">
        <div>
          <app-csw-text
            [customClass]="'step-element-title'"
            [text]="storeLabelTemplate | translation: 'template_content': lang"
            [styleConfig]="{ fontWeight: 600 }"
          >
          </app-csw-text>
        </div>
      </div>
      <div class="col-md-12">
        <ng-select
          class="mt-3"
          id="store"
          name="store"
          placeholder=""
          [multiple]="false"
          [(ngModel)]="selectedStoreId"
          (change)="onStoreChange($event)"
          [required]="true"
          #storeControl="ngModel"
          [ngClass]="{ 'is-invalid': storeControl.touched && storeControl.invalid }"
        >
          <ng-container *ngIf="stores?.length > 1 && bookingService?.partnerData?.widget_show_all_stores_card === 1">
            <ng-option [value]="0">
              {{ anyStoreLabelTemplate | translation: 'template_content': lang }}
            </ng-option>
          </ng-container>

          <ng-container *ngFor="let store of stores">
            <ng-container *ngIf="store.id != null">
              <ng-option [value]="store.id">
                {{ store | translation: 'name': lang }}
              </ng-option>
            </ng-container>
          </ng-container>
        </ng-select>

        <div class="text-danger"
             *ngIf="storeControl.touched && !storeControl.valid">
          <div *ngIf="storeControl?.errors?.required">
            {{ 'error_messages.required_error_message' | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Stores dropdown view ends -->


  <!-- Zip Code Stores dropdown view starts -->
  <ng-container
    *ngIf="(
      widgetService?.widgetConf?.partner?.enable_store_postcode_feature === 1
         &&
      widgetService?.widgetConf?.partner?.show_stores_as_dropdown === 1
    ) || widgetService?.widgetConf?.partner?.automatic_store_zip_search === 1
  "
  >
    <div class="row">
      <div class="col-md-12">
        <div>
          <app-csw-text
            [customClass]="'step-element-title'"
            [text]="storeLabelTemplate | translation: 'template_content': lang"
            [styleConfig]="{ fontWeight: 600 }"
          >
          </app-csw-text>
        </div>
      </div>

      <div class="col-md-12">
        <ng-select
          class="mt-3"
          [loading]="isStoreLoading"
          id="store"
          name="store"
          placeholder=""
          [multiple]="false"
          [(ngModel)]="selectedStoreId"
          (change)="onStoreChange($event)"
          [required]="true"
          #storeControl="ngModel"
          [ngClass]="{ 'is-invalid': storeControl.touched && storeControl.invalid }"
          [typeahead]="searchStores"
          [searchable]="true"
          [typeToSearchText]="'booking_components.search_store_postcode_helper_text' | translate"
          (keydown)="allowOnlyNumbers($event)"
          [notFoundText]="'booking_components.store_not_found' | translate"
        >
          <ng-container *ngFor="let store of stores">
            <ng-container *ngIf="store.id != null">
              <ng-option [value]="store.id">
                <span>{{ store | translation: 'name': lang }}</span>
                <span *ngIf="store.distance && widgetService?.widgetConf?.partner?.show_distance_in_stores === 1">
                  ({{ 'booking_components.distance_from_store' | translate: {distance: (store.distance | number:'1.0-0')} }}
                  )
                </span>
              </ng-option>
            </ng-container>
          </ng-container>


          <ng-template ng-loadingspinner-tmp>
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </ng-template>

        </ng-select>

        <div>
          <small class="text-muted">
            {{ 'booking_components.search_store_by_postcode_placeholder' | translate }}
          </small>
        </div>

        <div class="text-danger"
             *ngIf="storeControl.touched && !storeControl.valid">
          <div *ngIf="storeControl?.errors?.required">
            {{ 'error_messages.required_error_message' | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Zip Code Stores dropdown view ends -->


  <div class="d-flex justify-content-end mt-4 cbw-custom-navigation">
    <div *ngIf="showPreviousButton" class="me-2">
      <app-button
        [id]="'store-selection-previous-btn'"
        (clickEvent)="previous()"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
       <span class="custom-button-icon back"></span>
        {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 129, type: 'template'
        }) | translation: 'template_content': lang
      )}}
      </app-button>
    </div>

    <div>
      <app-button
        [id]="'store-selection-next-btn'"
        (clickEvent)="next()"
        [isDisabled]="!selectedStoreId && selectedStoreId !== 0"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 128, type: 'template'
        }) | translation: 'template_content': lang
      )}}
      </app-button>
      <span class="custom-button-icon next"></span>
    </div>
  </div>

</form>
