<ng-container *ngIf="!noAppointments;">
  <ng-container
    *ngIf="hasGroups"
  >
    <mat-tab-group
      [selectedIndex]="0"
      [dynamicHeight]="true"
      [disableRipple]="true"
    >
      <ng-container
        *ngFor="let appointmentsGroup of freeAppointments; let tabIndex = index"
      >
        <mat-tab
          *ngIf="appointmentsGroup.widgetGroup"
        >
          <ng-template mat-tab-label>
            {{ appointmentsGroup.widgetGroup | translation: 'name' : lang }}
            <span
              class="badge tabs-slot-counter ms-2"
              [style.background-color]="(widgetService?.widgetConf?.header_background_color || undefined)"
              *ngIf="appointmentsGroup.selectedSlots > 0"
            >
              {{ appointmentsGroup.selectedSlots }}
            </span>
          </ng-template>
          <div class="row">
            <ng-container
              *ngTemplateOutlet="slotsBlock; context: { $implicit: appointmentsGroup, tabIndex: tabIndex }"
            ></ng-container>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </ng-container>

  <ng-container
    *ngIf="!hasGroups"
  >
    <ng-container
      *ngFor="let appointmentsGroup of freeAppointments; let tabIndex = index"
    >
      <div class="row mt--3">
        <ng-container
          *ngTemplateOutlet="slotsBlock; context: { $implicit: appointmentsGroup, tabIndex: tabIndex }"
        ></ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #slotsBlock let-appointmentsGroup let-tabIndex="tabIndex">
    <ng-container *ngFor="let appointment of appointmentsGroup.appointments;  let appointmentindex = index">
      <div
        *ngIf="appointmentsGroup?.loadMore === true || appointmentindex < 10"
        class="mt-3 col-xs-12 col-sm-6"
      >
        <app-info-card
          [showBody]="true"
          [showShadow]="false"
          [isSelected]="appointment.selected"
          [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
          [isDisabled]="appointment.disabled"
          [showCardBorder]="true"
          [customCardClass]="appointment.selected ? 'cbsw-next-icon' : ''"
          [customCardStylesConfig]="{
            borderWidth: '1px',
            borderColor: '#d6d6d6',
            borderStyle: 'soDlid',
            borderRadius: '8px'
          }"
        >
          <div cardBody>
            <div class="d-flex align-items-center">
              <mat-checkbox
                [disableRipple]="true"
                (change)="onSelectAppointment($event, appointment)"
                [checked]="appointment.selected"
                [disabled]="appointment.disabled"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex flex-column align-items-center justify-content-center w-100">
                    <div
                      class="resource-name"
                      *ngIf="selectedWorkerId > 0 || bookingService?.partnerData?.anyone_feature_hide_ressource === 0"
                    >
                      <ng-container *ngIf="appointment?.worker?.is_resource === 1">
                        <ng-container *ngIf="appointment?.worker?.is_multi_language === 1">
                          <ng-container
                            *ngIf="(appointment?.worker | translation: 'booking_label': lang)"> {{ appointment?.worker | translation: 'booking_label': lang }}
                          </ng-container>
                          <ng-container
                            *ngIf="!(appointment?.worker | translation: 'booking_label': lang)"> {{ appointment?.worker | translation: 'resource_name': lang }}
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="appointment?.worker?.is_multi_language === 0">
                          <ng-container
                            *ngIf="appointment?.worker?.booking_label"
                          >
                            {{ appointment?.worker?.booking_label }}
                          </ng-container>
                          <ng-container
                            *ngIf="!appointment?.worker?.booking_label"
                          >
                            {{ appointment?.worker?.resource_name }}
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <ng-container *ngIf="appointment?.worker?.is_resource === 0">
                        {{ appointment?.worker?.resource_name }}
                      </ng-container>
                    </div>

                    <div
                      [ngClass]="{
                        'hour-slot': true,
                        'font-weight-bold': (selectedWorkerId === 0)
                      }"
                    >
                      {{ appointment.start | dateutc:timeFormat }} - {{ appointment.end | dateutc:timeFormat }}
                    </div>
                  </div>
                </div>
              </mat-checkbox>
              <div
                class="next-arrow-container"
                *ngIf="appointment.selected"
              >
                <span
                  (click)="gotoNextPage()"
                >
                  <div class="next-button-wrapper">
                    <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                  </div>
                </span>
              </div>
            </div>
          </div>
        </app-info-card>
      </div>
    </ng-container>

    <ng-container *ngIf="
      (appointmentsGroup?.loadMore === false || appointmentsGroup?.loadMore === undefined) &&
      appointmentsGroup.appointments &&
      appointmentsGroup.appointments.length >= 10
    ">
      <div class="col-md-12 mt-4 text-center load-more-link">
        <app-csw-link
          [styleConfig]="{
            'color': widgetService?.widgetConf?.header_background_color,
            'textDecorationColor': widgetService?.widgetConf?.header_background_color
          }"
          [isLink]="false"
          [text]="'booking_components.load_more' | translate"
          (clickEvent)="loadMore(tabIndex)"
        ></app-csw-link>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
