import {Component, Input, OnInit} from '@angular/core';
import {SurveyStepModel} from '../../../../models/survey-step.model';
import {LoggerService} from '../../../../services/utils/logger.service';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'app-survey-questions-view',
  templateUrl: './survey-questions-view.component.html',
  styleUrls: ['./survey-questions-view.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SurveyQuestionsViewComponent implements OnInit {

  @Input() currentSurveyStep: SurveyStepModel;

  constructor() {
  }

  ngOnInit(): void {
    LoggerService.log('currentSurveyStep ', this.currentSurveyStep);
  }

}
