<div id="body">
  <ng-container *ngIf="!localStorageaccessible">
    <div class="cbsw-calenso-warnings p-2">
      <app-alert
        isAlertMessageTranslated="true"
        type="warning"
        message="store_access_warning"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="widgetService.widgetConf?.partner?.bookingwidget_enabled === 0">
    <div class="card card-warning bg-white rounded card-box m-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="text-center icon-block ps-1 pe-1">
            <div class="notification-icon">
              <img src="assets/images/notifications/warning_ico.svg" />
            </div>
          </div>
          <div class="content-block ps-3 pe-3">
            <div class="notification-title text-warning">
                {{ 'error_messages.widget_disabled' | translate }}
            </div>
            <div class="notification-description mt-1">
                {{ 'error_messages.widget_disabled_description' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-widget-layout
    *ngIf="widgetService.widgetConf?.partner?.bookingwidget_enabled === 1 && widgetService.widgetConf && widgetService.layoutType === 'CHATBOX'"
  >
    <div mainWidgetBody>
      <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.LEAD_GENERATOR">
        <app-lead-generator />
      </ng-container>

      <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.SURVEY">
        <app-survey />
      </ng-container>

      <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.BOOKING">
        <app-booking />
      </ng-container>

      <!-- <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.FILL_CALENDAR">
        <app-fill-calendar />
      </ng-container> -->
    </div>
  </app-widget-layout>

  <div
    *ngIf="widgetService.widgetConf?.partner?.bookingwidget_enabled === 1 && widgetService.layoutType === 'IFRAME'"
  >
    <div *ngIf="!widgetService.widgetConf">
      <div class="d-flex flex-column justify-content-center align-items-center loader-container">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div *ngIf="widgetService.widgetConf">
      <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.LEAD_GENERATOR">
        <app-lead-generator />
      </ng-container>

      <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.SURVEY">
        <app-survey />
      </ng-container>

      <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.BOOKING">
        <app-booking />
      </ng-container>

      <!-- <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.FILL_CALENDAR">
        <app-fill-calendar />
      </ng-container> -->
    </div>
  </div>

  <div
    class="container-fluid"
    *ngIf="widgetService.widgetConf?.partner?.bookingwidget_enabled === 1 && widgetService.layoutType === 'IFRAME_URL'"
  >
    <div *ngIf="!widgetService.widgetConf">
      <div class="d-flex flex-column justify-content-center align-items-center loader-container">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <div *ngIf="widgetService.widgetConf">
      <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.LEAD_GENERATOR">
        <app-lead-generator />
      </ng-container>

      <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.SURVEY">
        <app-survey />
      </ng-container>

      <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.BOOKING">
        <app-booking />
      </ng-container>

      <!-- <ng-container *ngIf="widgetService?.widgetConf?.type === widgetTypes.FILL_CALENDAR">
        <app-fill-calendar />
      </ng-container> -->
    </div>
  </div>
</div>
