<ng-container *ngIf="customerFields.includes('prename')">
  <div class="mb-1">
    <label class="mb-1" for="firstName">{{ 'form_fields.preName' | translate | addRequired }}</label>
    <input
      type="text"
      class="form-control"
      id="firstName"
      placeholder=""
      required="true"
      name="forename"
      [(ngModel)]="widgetService.customerConfData.prename"
      #forename="ngModel"
      [ngClass]="{ 'is-invalid': forename.touched && forename.invalid }"
    >
    <div *ngIf="forename.touched && forename.invalid" class="invalid-feedback">
      <div *ngIf="forename.errors?.required">
        {{ 'error_messages.required_error_message' | translate }}
      </div>
      <div *ngIf="forename.errors?.minlength">
        {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
      </div>
      <div *ngIf="forename.errors?.maxlength">
        {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="customerFields.includes('lastname')">
  <div class="mb-1">
    <label class="mb-1" for="lastName">{{ 'form_fields.lastName' | translate | addRequired }}</label>
    <input
      type="text"
      class="form-control"
      id="lastName"
      placeholder=""
      required="true"
      name="lastName"
      [(ngModel)]="widgetService.customerConfData.lastname"
      #lastName="ngModel"
      [ngClass]="{ 'is-invalid': lastName.touched && lastName.invalid }"
    >
    <div *ngIf="lastName.touched && lastName.invalid" class="invalid-feedback">
      <div *ngIf="lastName.errors?.required">
        {{ 'error_messages.required_error_message' | translate }}
      </div>
      <div *ngIf="lastName.errors?.minlength">
        {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
      </div>
      <div *ngIf="lastName.errors?.maxlength">
        {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="customerFields.includes('email')">
  <div class="mb-1">
    <label class="mb-1" for="email">{{ 'form_fields.email' | translate | addRequired }}</label>
    <input
      type="email"
      class="form-control"
      id="email"
      name="eMail"
      [(ngModel)]="widgetService.customerConfData.email"
      #eMail="ngModel"
      required="true"
      [pattern]="emailRegex"
      [ngClass]="{ 'is-invalid': eMail.touched && eMail.invalid }"
      [disabled]="disableEmailField"
    >
    <div *ngIf="eMail.touched && eMail.invalid" class="invalid-feedback">
      <div *ngIf="eMail.errors?.required">
        {{ 'error_messages.required_error_message' | translate }}
      </div>
      <div *ngIf="eMail.errors?.minlength">
        {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
      </div>
      <div *ngIf="eMail.errors?.maxlength">
        {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
      </div>
      <div *ngIf="eMail.errors?.email">
        {{ 'error_messages.email_error_message' | translate }}
      </div>
      <div *ngIf="eMail.errors?.pattern">
        {{ 'error_messages.email_error_message' | translate }}
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="customerFields.includes('city') || customerFields.includes('zip')">
  <div class="row mb-1">
    <ng-container *ngIf="customerFields.includes('city')">
      <div class="col-8">
        <label class="mb-1" for="city">{{ 'form_fields.city' | translate | addRequired }}</label>
        <input
          type="city"
          class="form-control"
          id="city"
          name="city"
          [(ngModel)]="widgetService.customerConfData.city"
          #city="ngModel"
          required="true"
          [ngClass]="{ 'is-invalid': city.touched && city.invalid }"
        >
        <div *ngIf="city.touched && city.invalid" class="invalid-feedback">
          <div *ngIf="city.errors?.required">
            {{ 'error_messages.required_error_message' | translate }}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="customerFields.includes('zip')">
      <div class="col-4">
        <label class="mb-1" for="zip">{{ 'form_fields.zip' | translate | addRequired }}</label>
        <input
          type="zip"
          class="form-control"
          id="zip"
          name="zip"
          [(ngModel)]="widgetService.customerConfData.zip"
          #zip="ngModel"
          required="true"
          pattern="^[a-zA-Z0-9\s-]{1,10}$"
          [ngClass]="{ 'is-invalid': zip.touched && zip.invalid }"
        >
        <div *ngIf="zip.touched && zip.invalid" class="invalid-feedback">
          <div *ngIf="zip.errors?.required">
            {{ 'error_messages.required_error_message' | translate }}
          </div>
          <div *ngIf="zip.errors?.pattern">
            {{ 'error_messages.invalid_zip' | translate }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
