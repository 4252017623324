<div class="mt-4" *ngIf="showGhostElement">
  <div class="skeleton-box-card">
    <div class="">
      <div class="row">

        <div class="col-md-12 mb-4"
             *ngFor="let ghostElement of slotGhostElements">
          <div class="d-flex stats-card shadow">
            <div
              class="skeleton-animation w-100 d-flex justify-content-center flex-column align-items-center">
              <!--<div class="bar bar-1"></div>
              <div class="bar bar-2"></div>-->
              <div class="">
                <div class="stat-box-1"></div>
                <div class="stat-box mt-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<form #storeForm="ngForm" *ngIf="rawMeetingTypes?.length > 0">
  <div class="row cbsw-meeting-type-selection-step">
    <div class="col-md-12">
      <div>
        <app-csw-text
          [customClass]="'step-element-title'"
          [text]="((bookingService.widgetTemplates | calioMeetingTemplate: {
                    id: 203, type: 'template'
                    }) | translation: 'template_content': lang
                  ) | calioSafe: 'html'"
          [styleConfig]="{ fontWeight: 600 }"
        >
        </app-csw-text>
      </div>
    </div>

    <div class="mt-3 col-md-6 cursor-pointer" *ngFor="let meetingType of rawMeetingTypes">
      <app-info-card
        [showCardBorder]="true"
        [showShadow]="false"
        [showBody]="true"
        [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
        [isSelected]="selectedMeetingTypeId === meetingType.id"
      >
        <div cardBody (click)="onMeetingTypeChange(meetingType.id)">

          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="avatar">
                <img *ngIf="meetingType.id === 1" src="{{ imagePath }}/images/meetings/store.svg" class="meeting_type_id_1" width="26px" />
                <img *ngIf="meetingType.id === 2" src="{{ imagePath }}/images/meetings/zoom.svg" class="meeting_type_id_2" width="26px" />
                <img *ngIf="meetingType.id === 3" src="{{ imagePath }}/images/meetings/calenso.svg" class="meeting_type_id_3" width="26px" />
                <img *ngIf="meetingType.id === 4" src="{{ imagePath }}/images/meetings/phone.svg" class="meeting_type_id_4" width="26px" height="30px" />
                <img *ngIf="meetingType.id === 5" src="{{ imagePath }}/images/meetings/customer.svg" class="meeting_type_id_5" width="26px" />
                <img *ngIf="meetingType.id === 6" src="{{ imagePath }}/images/meetings/unblu.svg" class="meeting_type_id_6" width="26px" />
                <img *ngIf="meetingType.id === 7" src="{{ imagePath }}/images/meetings/teams.svg" class="meeting_type_id_7" width="26px" />
                <img *ngIf="meetingType.id === 8" src="{{ imagePath }}/images/meetings/meet.svg" class="meeting_type_id_8" width="26px" />
                <img *ngIf="meetingType.id === 9" src="{{ imagePath }}/images/meetings/gotomeeting.png" class="meeting_type_id_9" width="26px" />
                <img *ngIf="meetingType.id === 10" src="{{ imagePath }}/images/meetings/webex.png"class="meeting_type_id_10" width="26px" />
                <img *ngIf="meetingType.id === 11" src="{{ imagePath }}/images/meetings/phone.svg" class="meeting_type_id_11" width="26px" height="30px" />
                <img *ngIf="meetingType.id === 13" src="{{ imagePath }}/images/meetings/metaverse.svg" class="meeting_type_id_11" width="26px" />
              </div>

              <ng-container
                *ngIf="(bookingService.widgetTemplates | calioMeetingTemplate: {
                    id: meetingType.id,
                    type: 'meeting_template'
                }) as widgetTemplate">
                <div class="ps-2">
                  <div>
                    <app-csw-text
                      [text]="(widgetTemplate.titleTemplate | translation: 'template_content': lang) | calioSafe: 'html'"
                      [styleConfig]="{ fontWeight: 600, fontSize: '13px' }"
                    >
                    </app-csw-text>
                  </div>
                  <div class="cbw-description"
                       [innerHTML]="(widgetTemplate.descTemplate | translation: 'template_content': lang) | calioSafe: 'html'"
                  >
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="next-arrow-container">
              <span
                *ngIf="selectedMeetingTypeId === meetingType.id"
                (click)="next()"
              >
                <div class="next-button-wrapper">
                  <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                </div>
              </span>
            </div>
          </div>
        </div>
      </app-info-card>
    </div>
  </div>

  <div class="d-flex justify-content-end mt-4">
    <div *ngIf="widgetService?.widgetConf?.context?.show_questions_before_booking_process" class="me-2">
      <app-button
        [id]="'meeting-type-previous-btn'"
        (clickEvent)="previous()"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 129, type: 'template'
        }) | translation: 'template_content': lang
      )}}
      </app-button>
    </div>

    <div>
      <app-button
        [id]="'meeting-type-selection-next-btn'"
        (clickEvent)="next()"
        [isDisabled]="!selectedMeetingTypeId"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
          id: 128, type: 'template'
        }) | translation: 'template_content': lang
      )}}
      </app-button>
    </div>
  </div>

</form>
