import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() id: string;
  @Input() customClass: string;
  @Input() isDisabled: boolean = Boolean(false);
  @Input() type: string = String('primary');
  @Input() fontColor: string;
  @Input() backgroundColor: string;

  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(): void {
    this.clickEvent.emit();
  }
}
