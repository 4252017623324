<div class="mt-3 cbsw-services-selection-step">

  <div *ngIf="showErrorMessage">
    <app-alert
      [isAlertMessageTranslated]="true"
      [type]="'danger'"
      [message]="'error_messages.no_services_found'"
    >
    </app-alert>
  </div>

  <ng-container *ngIf="showDependenciesGhostElement">
    <div class="skeleton-box-card">
      <div class="">
        <div class="row mt-4" *ngFor="let dependenciesGhostElement of dependenciesGhostElements">
          <div class="col-12 mb-3">
            <div class="heading-element"></div>
          </div>

          <div class="col-6 mb-4"
               *ngFor="let dependenciesGhostElement of dependenciesGhostElements">
            <div class="d-flex stats-card shadow">
              <div class="skeleton-animation w-100 d-flex flex-column">
                <div class="body-container">
                  <div class="stat-box-1"></div>
                  <div class="stat-box mt-2"></div>
                </div>
                <div class="border-top">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="servicesGroupedData?.length > 0">
    <div class="service-title">
      <app-csw-text
        [customClass]="'step-element-title'"
        [text]="serviceLabelTemplate | translation: 'template_content': lang"
        [styleConfig]="{ fontWeight: 600 }"
      >
      </app-csw-text>
    </div>


    <ng-container *ngFor="let serviceGroupedData of servicesGroupedData;">

      <div class="category-title">
        <app-csw-text
          [text]="(serviceGroupedData?.temp?.appointment_service_category | translation: 'name': lang) | calioSafe: 'html'"
          [styleConfig]="{ fontWeight: 600, fontSize: '13px' }">
        </app-csw-text>
      </div>

      <ng-container *ngIf="!widgetService?.widgetConf?.context?.show_appointment_services_list">
        <swiper
          [slidesPerView]="2"
          [spaceBetween]="20"
          [freeMode]="true"
        >
          <ng-template
            swiperSlide
            *ngFor="let serviceData of serviceGroupedData.value"
          >
            <div class="mt-3">
              <ng-container
                *ngTemplateOutlet="serviceDataTemplate;context:{ serviceData: serviceData }">
              </ng-container>
            </div>
          </ng-template>
        </swiper>
      </ng-container>

      <ng-container *ngIf="widgetService?.widgetConf?.context?.show_appointment_services_list">
        <div class="row service-list-container">
          <div
            class="col-md-6 mt-3"
            *ngFor="let serviceData of serviceGroupedData.value"
          >
            <div>
              <ng-container
                *ngTemplateOutlet="serviceDataTemplate;context:{ serviceData: serviceData }">
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>

    </ng-container>

    <div class="d-flex justify-content-end mt-4 mb-4 pe-3 cbw-custom-navigation">
      <div class="me-2">
        <app-button
          [id]="'services-selection-previous-btn'"
          (clickEvent)="previous()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
        <span class="custom-button-icon back"></span>
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
            id: 129, type: 'template'
          }) | translation: 'template_content': lang
        )}}
        </app-button>
      </div>

      <div>
        <app-button
          [id]="'services-selection-next-btn'"
          (clickEvent)="next()"
          [isDisabled]="bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.length === 0"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: {
            id: 128, type: 'template'
          }) | translation: 'template_content': lang
        )}}
        <span class="custom-button-icon next"></span>
        </app-button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #serviceDataTemplate let-serviceData="serviceData">
  <app-info-card
    [showCursorPointer]="true"
    [showBody]="true"
    [showFooter]="true"
    [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
    [isSelected]="bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) > -1"
    (cardClickEvent)="toggleServiceSelection(serviceData)"
  >
    <div cardBody>
      <div>
        <app-csw-text [text]="(serviceData | translation: 'name': lang) | calioSafe: 'html'"
                      [styleConfig]="{ fontWeight: 600, fontSize: '13px' }">

        </app-csw-text>
      </div>
      <div>
        <app-csw-text
          *ngIf="(serviceData.duration | replaceComma | toHour) > 0"
          [text]="(serviceData.duration | replaceComma | toHour) + ('booking_components.durationHour' | translate) "
          [styleConfig]="{ fontSize: '13px' }"
        >
        </app-csw-text>
        <app-csw-text
          *ngIf="(serviceData.duration | replaceComma | toMinute) > 0"
          [text]="(serviceData.duration | replaceComma | toMinute) + ('booking_components.durationMinute' | translate) "
          [styleConfig]="{ fontSize: '13px' }"
        >
        </app-csw-text>

        <ng-container *ngIf="serviceData.price > 0">
          <app-csw-text
            [text]="'/ ' + (serviceData.price | calioCurrency: bookingService.partnerData['currency'])"
            [styleConfig]="{ fontSize: '13px' }"
          >
          </app-csw-text>

          <small class="taxes text-muted" *ngIf="serviceData?.tax?.rate > 0">
            {{ 'booking_components.taxes' | translate: {percentage: serviceData?.tax?.rate} }}
          </small>
        </ng-container>
        <ng-container *ngIf="serviceData.price === 0">
          {{ 'booking_components.free' | translate }}
        </ng-container>
      </div>
    </div>
    <div cardFooter>
      <div
        class="d-flex align-items-center"
        [ngClass]="{
          'justify-content-between': bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) > -1,
          'justify-content-center': bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) === -1
        }"
      >
        <div class="d-flex align-items-center">
          <app-csw-link
            *ngIf="bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) === -1"
            [isLink]="false"
            [styleConfig]="{
                        'color': widgetService?.widgetConf?.header_background_color,
                        'textDecorationColor': widgetService?.widgetConf?.header_background_color
                      }"
            [text]="'booking_components.select' | translate"
          ></app-csw-link>

          <app-csw-link
            *ngIf="bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) > -1"
            [isLink]="false"
            [styleConfig]="{
                        'color': widgetService?.widgetConf?.header_background_color,
                        'textDecorationColor': widgetService?.widgetConf?.header_background_color
                      }"
            [text]="'booking_components.deselect' | translate"
          ></app-csw-link>
        </div>
        <div
          class="next-arrow-container"
          *ngIf="bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) > -1"
        >
          <span
            (click)="next()"
          >
            <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px" />
          </span>
        </div>
      </div>
    </div>
  </app-info-card>
</ng-template>


