import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { fadeInFadeOut } from '../../animations/fade';
import { BOOKING_PAGE_VIEWS } from '../../constants/app.constants';
import { WINDOW_LISTENER_CONSTANTS } from '../../constants/window-listener.constants';
import { PartnerDbModel, TokenType } from '../../models/partner-db.model';
import { WidgetTemplateModel } from '../../models/widget-template.model';
import { BookingService } from '../../services/feature/booking.service';
import { WidgetService } from '../../services/feature/widget.service';
import { LoggerService } from '../../services/utils/logger.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  animations: [ fadeInFadeOut ],
})
export class BookingComponent implements OnInit {

  readonly bookingPageViews = BOOKING_PAGE_VIEWS;

  counter = 0;
  partnerData: PartnerDbModel;
  selectedView = BOOKING_PAGE_VIEWS.STORE_VIEW;
  widgetTemplates: WidgetTemplateModel[] = [];
  isFirstBookingQuestionActive = true;
  isLastBookingQuestionActive = false;

  constructor(
    public widgetService: WidgetService,
    public bookingService: BookingService
  ) {
    if (this.widgetService.widgetConf?.context?.show_questions_before_booking_process) {
      this.selectedView = BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW;
    } else if (this.widgetService.widgetConf?.context?.show_meeting_type_picker) {
      this.selectedView = BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW;
    }

    this.bookingService.initialSmartWidgetView = this.selectedView;
  }

  ngOnInit(): void {
    this.initializeBookingWidget(this.widgetService.partnerUUID);
  }

  initializeBookingWidget(partnerUUID: string): void {
    this.bookingService.initializeBookingWidget(partnerUUID, TokenType.publicSmartwidget).subscribe({
      next: partner => {
        if (partner) {
          this.partnerData = partner;

          if (this.partnerData?.adobe_launch_tracking_url && this.partnerData?.adobe_launch_tracking_url?.trim() !== '') {
            this.loadAdobeAnalytics(this.partnerData.adobe_launch_tracking_url);
          }

          this.bookingService.partnerData = this.partnerData;
          this.preinitializeWidgetContextValues();
          this.getWidgetTemplates();
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  loadAdobeAnalytics(adobe_launch_tracking_url: string): void {
    try {
      const script = document.createElement('script');
      script.src = adobe_launch_tracking_url;
      script.async = true;
      document.head.appendChild(script);
      LoggerService.log('Adobe Launch tracking script added.');
    } catch (error) {
      LoggerService.warn('Error appending dobe Launch tracking script');
      LoggerService.error(error);
    }
  }

  preinitializeWidgetContextValues(): void {
    this.bookingService.loadAppointmentStateFromLocalStorage();
    this.bookingService.preInitializeWidgetContextValues(
      this.widgetService.widgetConf?.context,
      this.widgetService.customerConfData,
    );
    this.bookingService.loadAppointmentSelectedUserFromLocalStorage();
    this.bookingService.preInitializeWidgetUserData(this.widgetService.customerConfData);
  }

  getWidgetTemplates(): void {
    this.bookingService.getWidgetTemplates().subscribe({
      next: templates => {
        this.widgetTemplates = templates;
        this.bookingService.widgetTemplates = this.widgetTemplates;
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  gotoView(view: string): void {
    this.selectedView = view;
    if (this.widgetService?.parentToChildOriginEvent) {
      this.widgetService.sendMessageToMainJs(this.widgetService.parentToChildOriginEvent, {
        eventName: WINDOW_LISTENER_CONSTANTS.PARENT_BOOKING_WEBSITE_EVENT,
        name: 'Child To Parent Communication Done',
        view
      });
    }
  }

  increment(): void {
    this.counter++;
  }

  decrement(): void {
    this.counter--;
  }
}
