import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AvailabilityRequestModel, FreeAppointmentDao } from '../../models/availability.model';
import { HttpClientService } from '../utils/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AvailabilitiesService {

  constructor(
    private httpClientService: HttpClientService
  ) {
  }

  // NOTE: Currently endpoint is being used in fill calendar type smartwidget
  // in case it is in use then need to update BE first to support multiple servie ids
  // and accordingly FE too
  getFreeAppointments(body: AvailabilityRequestModel): Observable<FreeAppointmentDao[]> {
    return this.httpClientService.post('availabilities/free_appointments', body, {});
  }
}
